import { G3Date } from "@g3r-developers/g3-common";
import React from "react";
import { QuestionAnswerModel } from "Types/Question/QuestionAnswerModel";

interface QuestionDatePickerProps {
    onChange(isoDate: string): void;
    question: QuestionAnswerModel;
}

export default function QuestionDatePicker({ onChange, question }: QuestionDatePickerProps) {
    return <G3Date onChange={onChange} selectedDate={question.answer} />;
}
