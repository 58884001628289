import { RootState } from "index";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { Navigate, Outlet, useLocation } from "react-router-dom";

function PrivateRoute() {
    const { pathname, search } = useLocation();
    const redirectUrl = useMemo(() => encodeURIComponent(`${pathname}${search}`), [pathname, search]);
    const authenticated = useSelector((state: RootState) => state.session.authenticated);

    return authenticated ? <Outlet /> : <Navigate to={`/?redirectUrl=${redirectUrl}`} replace />;
}

export default PrivateRoute;
