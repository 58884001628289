import { OfflineAssetType } from "./OfflineAssetType";

export enum TyreSectionName {
    NearsideFront = "NSF",
    NearsideRear = "NSR",
    OffsideFront = "OSF",
    OffsideRear = "OSR",
    Spare = "Spare",
    Front = "Front",
    Back = "Back",
}

export function TyreSectionNameToOfflineAssetType(
    assetType:
        | TyreSectionName.NearsideFront
        | TyreSectionName.NearsideRear
        | TyreSectionName.OffsideFront
        | TyreSectionName.OffsideRear
        | TyreSectionName.Front
        | TyreSectionName.Back
        | TyreSectionName.Spare
): OfflineAssetType {
    switch (assetType) {
        case TyreSectionName.NearsideFront: {
            return OfflineAssetType.NearsideFrontTyreImage;
        }
        case TyreSectionName.NearsideRear: {
            return OfflineAssetType.NearsideRearTyreImage;
        }
        case TyreSectionName.OffsideFront: {
            return OfflineAssetType.OffsideFrontTyreImage;
        }
        case TyreSectionName.OffsideRear: {
            return OfflineAssetType.OffsideRearTyreImage;
        }
        case TyreSectionName.Spare: {
            return OfflineAssetType.SpareTyreImage;
        }
        case TyreSectionName.Front: {
            return OfflineAssetType.FrontTyreImage;
        }
        case TyreSectionName.Back: {
            return OfflineAssetType.BackTyreImage;
        }
    }
}
