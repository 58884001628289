export enum VehicleType {
    FlatbedVen = "471D3B40-2A7E-4E4E-BE05-40A338AFD456",
    PanelVanStandard = "8AFFD566-35E9-4093-A5BE-5AB272F8F761",
    PeopleCarrier = "2C7257E1-9FCB-42CE-8B95-71028EC45DD0",
    Pickup = "B902158D-EE8B-422A-9A48-7421E2F3053A",
    Saloon4Door = "F503C7A9-8DB8-438A-98BA-8AFFCEA6336D",
    MiniBus = "F687C89D-2D03-46B6-8746-967BB7009242",
    Motorbike = "2B34AA2A-D857-4F2F-A06F-B75ACDBE5B23",
    Saloon2Door = "154E1067-20DC-4739-924D-C7E9007D87E3",
    Hatch3Door = "A6AD32A0-3829-4E89-BB36-D803BCDCF2A8",
    Estate5Door = "A9BE2268-2508-4FB0-96AE-D86F1874F559",
    Unknown = "97CDCC0A-ADF2-4D7B-AF30-E37640064DB1",
    Convertable2Door = "895B2A28-C5B3-4A55-B899-F841E754B1BC",
    Hatch5Door = "959C06BF-86DC-43F9-A710-F937F5451EA9",
}

export enum Transmission {
    Unknown = "2BE574A5-E95C-4C10-9BD0-5C122C1D1A01",
}
