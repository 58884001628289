import sections from "Constants/Sections";
import { inspectionAdditionalAssetCategory } from "Types/Enums/InspectionAdditionalAssetCategory";
import { MediaType } from "Types/Enums/MediaType";
import { OfflineAssetType } from "Types/Inspection/Enums/OfflineAssetType";
import { TyreSectionName, TyreSectionNameToOfflineAssetType } from "Types/Inspection/Enums/TyreSectionName";
import { DamageItemModel } from "Types/InspectionStaging/DamageItemModel";
import { InspectionStagingRequest } from "Types/InspectionStaging/InspectionStagingRequest";
import { LocationModel } from "Types/InspectionStaging/LocationModel";
import { MarketingItemModel } from "Types/InspectionStaging/MarketingItemModel";
import { StagingAssetReferenceItem } from "Types/InspectionStaging/StagingAssetReferenceItem";
import { TyreGroupItemModel } from "Types/InspectionStaging/TyreGroupItemModel";
import { TyreGroupModel } from "Types/InspectionStaging/TyreGroupModel";
import { DateTime } from "luxon";
import api from "./Api";
import inspectionAssetStore from "./DbStores/InspectionAssetStore";
import inspectionConfigStore from "./DbStores/InspectionConfigStore";
import inspectionDamageStore from "./DbStores/InspectionDamageStore";
import inspectionDetailStore from "./DbStores/InspectionDetailStore";
import inspectionSectionStore from "./DbStores/InspectionSectionStore";
import inspectionSplatComponentStore from "./DbStores/InspectionSplatComponentStore";

interface InspectionStagingResponse {
    stagingId: string;
    succesful: boolean;
}

const mediaTypeToExt = (mediaType: string) => {
    switch (mediaType) {
        case MediaType.Photo: {
            return "jpeg";
        }
        case MediaType.Audio: {
            return "ogg";
        }
        case MediaType.Video: {
            return "webm";
        }
    }
};

const clearDownInspection = async (inspectionId: string) => {
    const inspectionDetail = await inspectionDetailStore.load(inspectionId);

    await inspectionConfigStore.remove(inspectionId);

    const splats = await inspectionDamageStore.getDamages(inspectionId);
    const damageAssetIds = splats.flatMap(s => s.damages.map(d => d.assetId)).filter(id => id);
    await inspectionDamageStore.remove(inspectionId);

    await inspectionDetailStore.remove(inspectionId);

    const sections = await inspectionSectionStore.loadInspectionSections(inspectionId);
    const sectionAssetIds = sections.flatMap(s => s.questionItemModels.map(qi => qi.assetId)).filter(id => id);
    await inspectionSectionStore.remove(inspectionId);

    const assetIds = [...damageAssetIds, ...sectionAssetIds];
    await Promise.all(assetIds.map(async id => await inspectionAssetStore.remove(id)));

    if (Array.isArray(inspectionDetail?.splats)) {
        await Promise.all(inspectionDetail.splats.map(async s => await inspectionSplatComponentStore.remove(s.id)));
    }
};

export const completeInspection = async (inspectionId: string, setLoadingTxt): Promise<void> => {
    setLoadingTxt("Preparing Staging Request");
    const stagingRequest = await getInspectionStagingRequest(inspectionId);

    setLoadingTxt("Loading Media to Send");
    stagingRequest.marketingImageThirdPartyAssetReferenceItems = stagingRequest.marketingItemModels.map(d => {
        return {
            assetId: d.thirdPartyAssetId,
            mediaTypeId: MediaType.Photo,
        } as StagingAssetReferenceItem;
    });

    stagingRequest.damageItemThirdPartyAssetReferenceItems = stagingRequest.damageItemModels
        .map(d => d.damageAssets)
        .flat(1);

    stagingRequest.tyreItemThirdPartyAssetReferenceItems = stagingRequest.tyreGroupModels
        .filter(t => t.assetId !== undefined)
        .map(t => {
            return {
                assetId: t.assetId,
                mediaTypeId: MediaType.Photo,
            } as StagingAssetReferenceItem;
        });

    // Add additional assets
    const additionalMarketingImages = (
        await inspectionAssetStore.getOfflineAssets(inspectionId, OfflineAssetType.AdditionalMarketingImage)
    ).filter(i => i.inspectionAssetId !== undefined);

    stagingRequest.additionalAssets = stagingRequest.additionalAssets.concat(
        additionalMarketingImages.map(i => ({
            assetId: i.inspectionAssetId,
            inspectionAdditionalAssetCategoryId: inspectionAdditionalAssetCategory.AdditionalMarketingImage,
        }))
    );

    // Add mileage assets
    const interiorSection = stagingRequest.questionGroupModels.find(_ => _.questionSectionId === sections.interior.id);
    if (interiorSection) {
        const interiorMileageQuestions = interiorSection.questionItemModels.filter(
            _ => _.questionId === sections.interior.questions.mileage.id
        );

        const mileageAssets = interiorMileageQuestions
            .filter(i => i.assetId !== undefined)
            .map(t => {
                return {
                    assetId: t.assetId,
                    mediaTypeId: MediaType.Photo,
                } as StagingAssetReferenceItem;
            });

        stagingRequest.enforcedPhotoAssetReferenceItems = [
            ...stagingRequest.enforcedPhotoAssetReferenceItems,
            ...mileageAssets,
        ] as StagingAssetReferenceItem[];
    }

    // Add EV photo assets
    const evSection = stagingRequest.questionGroupModels.find(_ => _.questionSectionId === sections.ev.id);
    if (evSection) {
        const evPhotoQuestions = evSection.questionItemModels.filter(
            _ =>
                _.questionId === sections.ev.questions.chargePortCondition.id ||
                _.questionId === sections.ev.questions.domestic3PinCharger.id ||
                _.questionId === sections.ev.questions.type2Charger.id
        );

        const evAssets = evPhotoQuestions
            .filter(i => i.assetId !== undefined)
            .map(t => {
                return {
                    assetId: t.assetId,
                    mediaTypeId: MediaType.Photo,
                } as StagingAssetReferenceItem;
            });

        stagingRequest.enforcedPhotoAssetReferenceItems = [
            ...stagingRequest.enforcedPhotoAssetReferenceItems,
            ...evAssets,
        ] as StagingAssetReferenceItem[];
    }

    try {
        setLoadingTxt("Sending Staging Request");
        const stagingResponse: InspectionStagingResponse = await api.post(`inspection/staging`, stagingRequest);

        const itemsToSync: StagingAssetReferenceItem[] = [
            ...stagingRequest.marketingImageThirdPartyAssetReferenceItems,
            ...stagingRequest.damageItemThirdPartyAssetReferenceItems,
            ...stagingRequest.tyreItemThirdPartyAssetReferenceItems,
            ...additionalMarketingImages?.map(
                i => ({ assetId: i.inspectionAssetId, mediaTypeId: MediaType.Photo } as StagingAssetReferenceItem)
            ),
            ...stagingRequest.enforcedPhotoAssetReferenceItems,
        ];

        setLoadingTxt("Syncing Media");
        const itemSyncs = itemsToSync.map(async ma => {
            const asset = await inspectionAssetStore.loadInspectionAsset(ma.assetId);
            const file = getFileFromB64(asset.asset, MediaType.Photo);
            await api.postFile(`inspection/staging/${stagingResponse.stagingId}/item/${ma.assetId}`, file);
        });

        if (stagingRequest.audioAssetId) {
            const audioAsset = await inspectionAssetStore.loadInspectionAsset(stagingRequest.audioAssetId);
            const file = getFileFromB64(audioAsset.asset, MediaType.Audio);
            await api.postFile(
                `inspection/staging/${stagingResponse.stagingId}/item/${stagingRequest.audioAssetId}`,
                file
            );
        }

        if (stagingRequest.signatureAssetId) {
            const signatureAsset = await inspectionAssetStore.loadInspectionAsset(stagingRequest.signatureAssetId);
            const signatureFile = getFileFromB64(signatureAsset.asset, MediaType.Photo);
            await api.postFile(
                `inspection/staging/${stagingResponse.stagingId}/item/${stagingRequest.signatureAssetId}`,
                signatureFile
            );
        }

        const videoSync = stagingRequest.videoWalkArounds.map(async asset => {
            const videoAsset = await inspectionAssetStore.loadInspectionAsset(asset.assetId);
            const videoFile = getFileFromB64(videoAsset.asset, MediaType.Video);

            await api.postFile(`inspection/staging/${stagingResponse.stagingId}/item/${asset.assetId}`, videoFile);
        });

        const allPromises = [...itemSyncs, ...videoSync];

        await Promise.all(allPromises).then(async () => {
            setLoadingTxt("Finalising Inspection");
            await api.post(`inspection/staging/${stagingResponse.stagingId}/finalise?inspectionId=${inspectionId}`);
        });

        setLoadingTxt("Removing Inspection from device");
        await clearDownInspection(inspectionId);
    } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
        const inspectionDetail = await inspectionDetailStore.load(inspectionId);
        const vrm = inspectionDetail?.inspection?.vrm;

        throw new Error(
            `There was an error syncing the inspection for ${vrm ? "" : "this "}vehicle${vrm ? " " + vrm : ""}`
        );
    }
};

const base64ToBlob = (base64Data: string, contentType: string): Blob => {
    contentType = contentType || "";
    const sliceSize = 1024;
    const byteCharacters = atob(base64Data);
    const bytesLength = byteCharacters.length;
    const slicesCount = Math.ceil(bytesLength / sliceSize);
    const byteArrays = new Array(slicesCount);

    for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
        const begin = sliceIndex * sliceSize;
        const end = Math.min(begin + sliceSize, bytesLength);

        const bytes = new Array(end - begin);
        for (let offset = begin, i = 0; offset < end; ++i, ++offset) {
            bytes[i] = byteCharacters[offset].charCodeAt(0);
        }
        byteArrays[sliceIndex] = new Uint8Array(bytes);
    }
    return new Blob(byteArrays, { type: contentType });
};

const getFileFromB64 = (base64: string, type: string): File => {
    const base64Parts = base64.split("base64,");
    const fileFormat = base64Parts[0].split(";")[1];
    const fileContent = base64Parts[1];
    const file = new File([base64ToBlob(fileContent, fileFormat)], `AVID Asset.${mediaTypeToExt(type)}`, {
        type: "image/jpeg",
    });
    return file;
};

const getQuestionSection = async (inspectionId: string, sectionId: string) => {
    const sections = await inspectionSectionStore.loadInspectionSections(inspectionId);
    const savedSection = sections.find(s => s.questionSectionId === sectionId);
    return savedSection;
};

const getTyreSection = async (inspectionId: string, sectionId: string): Promise<TyreGroupModel> => {
    const sections = await inspectionSectionStore.loadInspectionSections(inspectionId);
    const savedSection = sections.find(s => s.questionSectionId === sectionId);

    const tgm = {} as TyreGroupModel;

    tgm.sectionId = sectionId;
    tgm.vehicleTyreSectionId = sectionId;

    // should only be 1 so grab first
    const imageAsset = (
        await inspectionAssetStore.getOfflineAssets(
            inspectionId,
            TyreSectionNameToOfflineAssetType(savedSection.questionGroupText as TyreSectionName)
        )
    )[0];

    tgm.assetId = imageAsset?.inspectionAssetId;

    tgm.tyreGroupModels = savedSection.questionItemModels.map(ss => {
        return {
            questionId: ss.questionId,
            questionValue: ss.actualValue,
            questionText: ss.textValue,
        } as TyreGroupItemModel;
    });

    return tgm;
};

const getMarketingSection = async (inspectionId: string, sectionId: string): Promise<MarketingItemModel[]> => {
    const sections = await inspectionSectionStore.loadInspectionSections(inspectionId);
    const savedSection = sections.find(s => s.questionSectionId === sectionId);

    if (!savedSection) {
        return [];
    }

    return savedSection.questionItemModels.map(qim => {
        return {
            marketingItemId: qim.questionId,
            thirdPartyAssetId: qim.assetId,
        } as MarketingItemModel;
    });
};

const getDamageSection = async (inspectionId: string): Promise<DamageItemModel[]> => {
    const damages = await inspectionDamageStore.getDamages(inspectionId);

    const allDamages = damages.map(d => d.damages).flat(1);

    return allDamages.map(d => {
        let assets = [] as StagingAssetReferenceItem[];

        if (d.assetIds?.length) {
            const damageAssets = d.assetIds.map(
                id =>
                    ({
                        assetId: id,
                        mediaTypeId: MediaType.Photo,
                    } as StagingAssetReferenceItem)
            );

            assets = assets.concat(damageAssets);
        }

        return {
            componentId: d.componentId,
            damageId: d.damageId,
            damageAssets: assets,
            damageLocation: {
                xLocation: Math.trunc(d.xCoordinate),
                yLocation: Math.trunc(d.yCoordinate),
            } as LocationModel,
            inspectionId: inspectionId,
            repairMethodId: d.repairMethodId,
            severityId: d.severityId,
            splatId: d.splatId,
        } as DamageItemModel;
    });
};
async function asyncForEach(array, callback) {
    for (let index = 0; index < array.length; index++) {
        // eslint-disable-next-line no-await-in-loop
        await callback(array[index], index, array);
    }
}

export const getInspectionStagingRequest = async (inspectionId: string): Promise<InspectionStagingRequest> => {
    const inspection = (await inspectionDetailStore.load(inspectionId))?.inspection;
    const inspectionConfig = await inspectionConfigStore.loadInspectionConfig(inspectionId);
    const storedInspectionDetail = await inspectionDetailStore.load(inspectionId);

    const questionSections = storedInspectionDetail.sections
        .filter(s => s.sectionType.reference === "Questions")
        .map(_ => _.id);

    const tyreTypeSections = storedInspectionDetail.sections.filter(s => s.sectionType.reference === "Tyre");

    const tyreSectionIds = tyreTypeSections.flatMap(tts => tts.sections.map(s => s.id));

    const marketingSections = storedInspectionDetail.sections
        .filter(s => s.sectionType.reference === "MarketingImages")
        .map(_ => _.id);

    // get Questions
    const questionGroupModels = [];

    await asyncForEach(inspectionConfig, async (sectionId: string) => {
        if (questionSections.includes(sectionId)) {
            questionGroupModels.push(await getQuestionSection(inspectionId, sectionId));
        }
    });

    // get Tyres
    const tyreGroupModels = [];

    await asyncForEach(inspectionConfig, async (sectionId: string) => {
        if (tyreSectionIds.includes(sectionId)) {
            const section = await getTyreSection(inspectionId, sectionId);
            tyreGroupModels.push(section);
        }
    });

    const model = {
        inspectionId: inspectionId,
        colourId: inspection?.colour?.id,
        manufacturerId: inspection?.manufacturerId,
        modelId: inspection?.modelId,
        inspectionTypeId: inspection?.inspectionTypeId,
        profileId: inspection?.inspectionProfileId,
        transmissionId: inspection?.transmissionId,
        damageItemModels: await getDamageSection(inspectionId),
        tyreGroupModels: tyreGroupModels,
        questionGroupModels: questionGroupModels,
        vehicleTypeId: inspection?.vehicleTypeId,
        marketingItemModels: await getMarketingSection(inspectionId, marketingSections[0]),
        signatureAssetId: inspection?.signatureAssetId,
        audioAssetId: inspection?.audioAssetId,
        additionalAssets: [],
        timeStampOfWhenInspectionWasCompleted: DateTime.now().toISO(),
        timeStampOfwhenInspectionWasStarted: inspection?.inspectionStartedTimeStamp,
        notes: inspection?.notes,
        enforcedPhotoAssetReferenceItems: [],
        videoWalkArounds: [],
    } as InspectionStagingRequest;

    if (inspection.videoAssetId) {
        model.videoWalkArounds = [
            {
                assetId: inspection.videoAssetId,
                mediaTypeId: MediaType.Video,
            } as StagingAssetReferenceItem,
        ];
    }

    return model;
};

const inspectionStagingService = {
    clearDownInspection,
    completeInspection,
    getInspectionStagingRequest,
};

export default inspectionStagingService;
