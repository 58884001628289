import sections from "Constants/Sections";
import { AccordionBody, AccordionHeader, AccordionItem, Button, CardImg, Row, UncontrolledAccordion } from "reactstrap";
import { OfflineAssetModel } from "Types/Inspection/OfflineAssetModel";
import { QuestionAnswerModel } from "Types/Question/QuestionAnswerModel";
import { QuestionComponent } from "../Question/QuestionComponent";

interface SignalTyreQuestionsProps {
    handleChange(sectionId: string, questionId: string, newValue: string, textValue: string): void;
    questions: QuestionAnswerModel[];
    toggleShowCamera(): void;
    tyreImageAsset: OfflineAssetModel;
}

export default function SignalTyreQuestions({
    handleChange,
    questions,
    toggleShowCamera,
    tyreImageAsset,
}: SignalTyreQuestionsProps) {
    return (
        <>
            {questions
                .filter(_ => _.id === sections.signalTyre.questions.tyreDepth.id) //Tyre depth
                .map(q => (
                    <Row className={"align-items-center"} key={q.id}>
                        <QuestionComponent
                            handleChange={(questionId: string, newValue: string, textValue: string) =>
                                handleChange(q.sectionId, questionId, newValue, textValue)
                            }
                            md={12}
                            question={q}
                        />
                    </Row>
                ))}
            <Row className={"align-items-center"}>
                <Button onClick={toggleShowCamera}>Photo</Button>
            </Row>
            {tyreImageAsset?.asset && (
                <Row className={"mt-2"}>
                    <CardImg alt={"Tyre Image"} src={tyreImageAsset.asset} width="100%" top />
                </Row>
            )}

            <UncontrolledAccordion flush defaultOpen="0" open="0">
                <AccordionItem>
                    <AccordionHeader targetId="1">
                        <strong>Additional Tyre Detail</strong>
                    </AccordionHeader>
                    <AccordionBody accordionId="1">
                        {questions
                            .filter(_ => _.id !== sections.signalTyre.questions.tyreDepth.id) // Exclude Tyre Depth
                            .map(q => (
                                <Row className={"align-items-center"} key={q.id}>
                                    <QuestionComponent
                                        handleChange={(questionId: string, newValue: string, textValue: string) =>
                                            handleChange(q.sectionId, questionId, newValue, textValue)
                                        }
                                        md={12}
                                        question={q}
                                    />
                                </Row>
                            ))}
                    </AccordionBody>
                </AccordionItem>
            </UncontrolledAccordion>
        </>
    );
}
