import { useMemo } from "react";
import { Button, Col, Row } from "reactstrap";
import { QuestionAnswerModel } from "Types/Question/QuestionAnswerModel";

export function QuestionCheckbox({
    onChange,
    question,
}: {
    onChange(newValue: string): void;
    question: QuestionAnswerModel;
}) {
    const color = useMemo(() => {
        if (question.answer === "true") {
            return "success";
        }
        return "secondary";
    }, [question.answer]);

    const outline = useMemo(() => {
        if (question.answer === "true") {
            return false;
        }
        return true;
    }, [question.answer]);

    return (
        <Row>
            <Col>
                <Button
                    outline={outline}
                    className="p-3 w-100"
                    block
                    color={color}
                    onClick={() => onChange(question.answer === "true" ? "false" : "true")}
                >
                    {question.questionText}
                </Button>
            </Col>
        </Row>
    );
}
