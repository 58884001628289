import { DropdownModel } from "Pages/Inspection";
import * as api from "./Api";

export const inspectionProfile = (): Promise<DropdownModel[]> => {
    return api.get(`dropdown/inspection-profile`);
};

export const inspectionType = (): Promise<DropdownModel[]> => {
    return api.get(`dropdown/inspection-type`);
};

export const transmission = (): Promise<DropdownModel[]> => {
    return api.get(`dropdown/transmission`);
};

export const vehicleType = (): Promise<DropdownModel[]> => {
    return api.get(`dropdown/vehicle-type`);
};

export const pendingInspectionVendors = (): Promise<DropdownModel[]> => {
    return api.get(`dropdown/pending-inspection-vendors`);
};

const dropdownService = {
    inspectionProfile,
    inspectionType,
    pendingInspectionVendors,
    transmission,
    vehicleType,
};

export default dropdownService;
