import { sessionService } from "redux-react-session";
import { AuthenticationResponse, UserModel } from "../Types/Authentication/UserModel";
import * as api from "./Api";

export const login = (model: UserModel): Promise<AuthenticationResponse> => {
    return api.post<AuthenticationResponse, UserModel>(`authentication`, model).then((res: AuthenticationResponse) => {
        if (res.success) {
            return sessionService.saveSession(res);
        }
    });
};

export const getSession = (): Promise<AuthenticationResponse> => {
    return sessionService.loadSession();
};

const authService = {
    getSession,
    login,
};

export default authService;
