export enum OfflineAssetType {
    AdditionalMarketingImage,
    NearsideFrontTyreImage,
    NearsideRearTyreImage,
    OffsideFrontTyreImage,
    OffsideRearTyreImage,
    SpareTyreImage,
    FrontTyreImage,
    BackTyreImage,
    MileageImage,
}
