import { G3Input, IconButton } from "@g3r-developers/g3-common";
import { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import authService from "../Services/AuthenticationService";
import { UserModel } from "../Types/Authentication/UserModel";

export default function LoginForm(): JSX.Element {
    const [loginData, setLoginData] = useState<UserModel>({} as UserModel);

    const navigate = useNavigate();

    const loggedIn = useCallback(() => {
        navigate("/dashboard");
    }, [navigate]);

    const handleSignIn = useCallback(() => {
        authService
            .login(loginData)
            .then(loggedIn)
            .catch(e => alert(e.message));
    }, [loginData, loggedIn]);

    const handleUsernameChanged = (value: string) => {
        setLoginData(p => {
            return { ...p, username: value };
        });
    };

    const handlePasswordChanged = (value: string) => {
        setLoginData(p => {
            return { ...p, password: value };
        });
    };

    return (
        <div className="align-items-center h-100 mt-5">
            <div className="align-self-center mt-50">
                <h1 className="text-bold">AVID</h1>
                <G3Input label="Username" onChange={handleUsernameChanged} value={loginData.username} />
                <G3Input label="Password" type="password" onChange={handlePasswordChanged} value={loginData.password} />
                <IconButton variant="primary" className="mt-3" text="Sign In" onClick={handleSignIn} />
            </div>
        </div>
    );
}
