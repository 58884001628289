export enum InspectionType {
    Signal = "0861A9E6-9EB7-4D19-B072-0B6C8DA03D6A",
    SignalLite = "967173A7-C2B8-4537-A9A1-BC15CE453C5A",
    UngradedInspection = "F3FBF126-55FB-4F7A-B3D1-8932A7866A40",
    UngradedInspectionWithSpec = "CE66FAE5-9794-4C2D-9074-8EB7217353DE",
    SafetyInspection = "FE0A12D5-56D9-4965-AA37-A1454B8A5CC0",
    OffsiteStandardInspection = "4149831D-7E60-4037-9ED8-A3D13AAF6E0F",
    AssuredInspection = "809895A8-DEAC-4D18-A259-A4642EDC5CF0",
    StandardInspection = "EF7C1AA3-3017-4640-AF5E-C6690ED1FF90",
}
