// NOTE: We can't use IDs for sections, we need to look these up by name

const sections = {
    damage: {
        name: "Damage",
    },
    mechanicalCondition: {
        name: "Mechanical Condition",
        questions: {
            absWarningLight: {
                id: "0c01d036-205d-4fb0-bbb6-81171aa244d8",
            },
            adBlueWarningLight: {
                id: "c17014ca-f831-436e-afb6-03129d342ad6",
            },
            airBagWarningLight: {
                id: "0c91c30d-d7c8-4924-9d95-306ce70c1148",
            },
            breakWearIndicatorLight: {
                id: "f1a3cf3b-05e1-4699-a916-cb94494aa844",
            },
            doesTheVehicleDisplayAnyWarningLights: {
                id: "fab5dbae-a582-43e1-a5ff-fddf6dd0190b",
            },
            engineManagementLight: {
                id: "102bce7f-d0d7-4a3d-8631-94f74d0230c3",
            },
            haveYouTestDrivenThisVehicle: {
                id: "447baef4-09c8-4ad6-a21b-a1a45e556ed7",
            },
            oilWarningLight: {
                id: "8b6c2b53-f0c8-4708-841e-6d3879274f8c",
            },
            serviceIndicatorLight: {
                id: "a33ca1f8-fcb8-4781-81b1-e8e68cdeb1ba",
            },
        },
    },
    offsiteMarketing: {
        name: "Offsite Marketing",
    },
    signalTyre: {
        name: "Signal Tyre",
        questions: {
            tyreDepth: {
                id: "c5e94804-a49e-45dd-9a05-1c669926f17e",
            },
        },
        sections: {
            nearSideFront: {
                name: "NSF",
            },
            nearSideRear: {
                name: "NSR",
            },
            offSideFront: {
                name: "OSF",
            },
            offSideRear: {
                name: "OSR",
            },
            spare: {
                name: "Spare",
                questions: {
                    repairKitExpiry: {
                        id: "3f7f05e5-d96b-4a27-9e94-ada76eb3a64c",
                    },
                    spareType: {
                        id: "1b3e7c8b-60a0-4e05-9602-c94f46bf0a8c",
                        choiceGroup: {
                            id: "56bd1a8f-007a-475b-9145-326ed7751e67",
                            choiceItems: {
                                none: {
                                    id: "65ff09d2-52ab-4d99-8602-4869d9f3a949",
                                },
                                repairKit: {
                                    id: "a36b3f9e-3332-4eb8-b9f2-c61c66984912",
                                },
                                runFlat: {
                                    id: "0f6a7a24-312e-4eb4-8f65-8f27b0824980",
                                },
                                spaceSaver: {
                                    id: "99d60fb3-3254-42a4-979a-3742bd9f17c7",
                                },
                                tyre: {
                                    id: "b8b76b5e-b92b-4288-9339-4c10dd64314e",
                                },
                            },
                        },
                    },
                    tyreManufacturer: {
                        id: "f66d06de-9715-4ecd-be1d-ba446e7c65ce",
                    },
                },
            },
        },
    },
    vehicleDetails: {
        name: "Vehicle Details",
        questions: {
            mileage: {
                id: "0b175b93-daec-41fe-b10e-3733485a7c60",
            },
            numberOfServiceStamps: {
                id: "e2d26dd0-36b2-438b-bcb8-0f7d6da9ffbf",
            },
            vehicleServiceHistory: {
                id: "0f2b328e-af16-4ff0-bf76-d2494f34c907",
                choiceGroup: {
                    id: "3525eb14-d6ef-453f-abf3-1ea301aadb04",
                    choiceItems: {
                        none: {
                            id: "800cfcda-9ec5-4665-92b9-977c554c5b39",
                        },
                    },
                },
            },
        },
    },
    vehicleEquipment: {
        name: "Vehicle Equipment",
    },
    exterior: {
        name: "Exterior",
    },
    interior: {
        name: "Interior",
        id: "86353C0A-89F4-4A0E-83C2-89836E8429AE",
        questions: {
            mileage: {
                id: "0B175B93-DAEC-41FE-B10E-3733485A7C60",
            },
        },
    },
    tyre: {
        name: "Tyre",
        nsf: {
            name: "NSF",
        },
        nsr: {
            name: "NSR",
        },
        osf: {
            name: "OSF",
        },
        osr: {
            name: "OSR",
        },
        spare: {
            name: "Spare",
        },
        front: {
            name: "Front",
        },
        back: {
            name: "Back",
        },
    },
    audio: {
        name: "Audio",
    },
    inspection: {
        name: "Inspection",
    },
    assuredUnderBonnet: {
        name: "Assured Under Bonnet",
    },
    assuredChecks: {
        name: "Assured Checks",
    },
    assuredTyre: {
        name: "Assured Tyre",
        nsf: {
            name: "NSF",
        },
        nsr: {
            name: "NSR",
        },
        osf: {
            name: "OSF",
        },
        osr: {
            name: "OSR",
        },
        spare: {
            name: "Spare",
        },
        front: {
            name: "Front",
        },
        back: {
            name: "Back",
        },
    },
    ev: {
        name: "EV",
        id: "4E2CDBC4-0E10-43C4-A7EA-C4E0A794DB86",
        questions: {
            type2Charger: {
                id: "BBCFBD75-0400-4106-A9F8-764E264564FA",
            },
            chargePortCondition: {
                id: "E0DD4F32-D43D-4D91-8ED9-C4CDF742572A",
            },
            domestic3PinCharger: {
                id: "C99A8AF9-A289-441E-AE0C-40D7F6495FB7",
            },
        },
    },
};

export default sections;
