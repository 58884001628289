/* eslint-disable no-console */
import { MediaType } from "Types/Enums/MediaType";
import { OfflineAssetType } from "Types/Inspection/Enums/OfflineAssetType";
import { TyreSectionName, TyreSectionNameToOfflineAssetType } from "Types/Inspection/Enums/TyreSectionName";
import { InspectionDetailModel } from "Types/Inspection/InspectionDetailModel";
import { InspectionModel } from "Types/Inspection/InspectionModel";
import { InspectionSearchModel } from "Types/Inspection/InspectionSearchModel";
import { CopyInspectionResponse } from "Types/Inspection/Responses/CopyInspectionResponse";
import { InspectionDetailsModel } from "Types/Inspection/Responses/InspectionDetailsModel";
import { SectionModel } from "Types/Inspection/SectionModel";
import { QuestionGroupModel } from "Types/InspectionStaging/QuestionGroupModel";
import { Guid } from "guid-typescript";
import * as Api from "./Api";
import componentService from "./ComponentService";
import inspectionAssetStore from "./DbStores/InspectionAssetStore";
import inspectionConfigStore from "./DbStores/InspectionConfigStore";
import inspectionDamageStore from "./DbStores/InspectionDamageStore";
import inspectionDetailStore from "./DbStores/InspectionDetailStore";
import inspectionSectionStore from "./DbStores/InspectionSectionStore";
import inspectionSplatComponentStore from "./DbStores/InspectionSplatComponentStore";
import utils from "./Utils";

const copy = async (inspectionId: string): Promise<CopyInspectionResponse> =>
    Api.post(`inspection/${inspectionId}/copy`);

const details = async (inspectionId: string): Promise<InspectionDetailsModel> =>
    Api.get(`inspection/details/${inspectionId}`);

const getById = async (inspectionId: string): Promise<InspectionModel> => {
    return Api.get(`inspection/${inspectionId}`);
};

export const getByStatus = (status: string, data?: unknown): Promise<InspectionModel[]> => {
    return Api.get(`status/${status}`, data);
};

const getByVrm = (vrm: string): Promise<InspectionModel> => {
    return Api.get(`inspection/${vrm}`);
};

const search = (model: InspectionSearchModel): Promise<InspectionModel[]> => {
    return Api.get<InspectionSearchModel, InspectionModel[]>("inspection/simple", model);
};

export const start = (inspectionId: string): Promise<InspectionDetailModel> => {
    return Api.post(`inspection/${inspectionId}/start`);
};

const storeDetail = async (inspectionDetail: InspectionDetailModel): Promise<InspectionDetailModel> => {
    const flattenedSections: SectionModel[] = [];

    inspectionDetail.sections.forEach(s => {
        flattenedSections.push(s);

        if (s.sections) {
            s.sections.forEach(s2 => {
                flattenedSections.push(s2);
            });
        }
    });

    const flattenedSectionIds = flattenedSections.map(s => s.id);

    await inspectionConfigStore.saveInspectionConfig({
        inspectionId: inspectionDetail.inspection?.id,
        sectionIds: flattenedSectionIds,
    });

    const sections = await inspectionSectionStore.loadInspectionSections(inspectionDetail.inspection?.id);
    const existingSectionIdsForInspection = sections.map(s => s.questionSectionId);

    // This is to handle when the user partially completes an inspection, then changes the
    // inspection type to one with different sections - this was causing validation issues
    // due to the "left behind" sections from the old inspection type which weren't on
    // the new inspection type
    const sectionsToAdd = flattenedSections.filter(
        newSection => !existingSectionIdsForInspection.includes(newSection.id)
    );
    const sectionsToRemove = sections.filter(s => !flattenedSectionIds.includes(s.questionSectionId));

    for (const s of sectionsToRemove) {
        const index = sections.indexOf(s);
        sections.splice(index, 1);
    }

    for (const s of sectionsToAdd) {
        sections.push({
            questionItemModels: [],
            questionSectionId: s.id,
            questionGroupText: s.name,
        } as QuestionGroupModel);
    }

    await inspectionSectionStore.saveInspectionSections(inspectionDetail.inspection?.id, sections);

    await Promise.all(
        inspectionDetail.splats.map(async splat => {
            const components = await componentService.getComponentsForSplat(splat.id);
            await inspectionSplatComponentStore.save(splat.id, components);
        })
    );

    await inspectionDetailStore.save(inspectionDetail);

    return inspectionDetail;
};

export const update = (inspectionId: string, data: InspectionModel): Promise<InspectionModel> => {
    return Api.put(`inspection/${inspectionId}`, data);
};

const updateDetailWithCompletedInspection = async (
    inspectionId: string,
    completedInspection: InspectionDetailsModel
): Promise<InspectionDetailModel> => {
    for (const ami of completedInspection.additionalMarketingImages) {
        let image: string = undefined;

        if (ami.url) {
            try {
                // eslint-disable-next-line no-await-in-loop
                image = await utils.getOfflineImageUrl(ami.url);
            } catch (e) {
                // Do nothing
            }
        }

        if (image) {
            // eslint-disable-next-line no-await-in-loop
            await inspectionAssetStore.storeOfflineAsset({
                inspectionAssetId: undefined,
                asset: image,
                inspectionId: inspectionId,
                mediaType: MediaType.Photo,
                type: OfflineAssetType.AdditionalMarketingImage,
            });
        }
    }

    if (completedInspection.audioAsset) {
        try {
            const audio = await fetch(completedInspection.audioAsset)
                .then(res => res.blob())
                .then(blob => utils.convertBlobToBase64(blob));

            const audioAssetId = await inspectionAssetStore.storeAsset({
                asset: audio,
                mediaType: MediaType.Audio,
            });

            const inspectionDetail = await inspectionDetailStore.load(inspectionId);

            await inspectionDetailStore.save({
                ...inspectionDetail,
                inspection: {
                    ...inspectionDetail.inspection,
                    audioAssetId: audioAssetId,
                },
            });
        } catch (e) {
            // Do nothing
        }
    }

    if (completedInspection.videoUrl) {
        try {
            const video = await fetch(completedInspection.videoUrl);

            const blob = await video.blob();
            console.log(blob);
            const txt = await utils.convertBlobToBase64(blob);

            const videoAssetId = await inspectionAssetStore.storeAsset({
                asset: txt,
                mediaType: MediaType.Video,
            });

            const inspectionDetail = await inspectionDetailStore.load(inspectionId);

            await inspectionDetailStore.save({
                ...inspectionDetail,
                inspection: {
                    ...inspectionDetail.inspection,
                    videoAssetId: videoAssetId,
                },
            });
        } catch (e) {
            // eslint-disable-next-line no-console
            console.error(e);
            // do nothing
        }
    }

    for (const ds of completedInspection.damageSections) {
        for (const di of ds.damageItems) {
            const images: string[] = [];

            if (di.imageUrls?.length) {
                for (const imageUrl of di.imageUrls) {
                    try {
                        // eslint-disable-next-line no-await-in-loop
                        const image = await utils.getOfflineImageUrl(imageUrl);
                        images.push(image);
                    } catch (e) {
                        // Do nothing

                        // eslint-disable-next-line no-console
                        console.info(`Failed to download image ${imageUrl} for damage ${di.questionId}`);
                    }
                }
            }

            // eslint-disable-next-line no-await-in-loop
            await inspectionDamageStore.addDamage(inspectionId, {
                splatId: ds.splatId,
                componentId: di.componentId,
                damageId: di.questionId,
                severityId: di.severityId,
                repairMethodId: di.repairMethodId,
                xCoordinate: di.splatLocation.x,
                yCoordinate: di.splatLocation.y,
                inspectionDamageItemId: undefined,
                images: images,
                assetIds: [],
            });
        }
    }

    for (const qs of completedInspection.questionSections) {
        for (const qi of qs.questionItems) {
            let image: string = undefined;

            if (qi.asset?.url) {
                try {
                    // eslint-disable-next-line no-await-in-loop
                    image = await utils.getOfflineImageUrl(qi.asset.url);
                } catch (e) {
                    // Do nothing
                }
            }

            // eslint-disable-next-line no-await-in-loop
            await inspectionSectionStore.updateInspectionSectionAnswer({
                inspectionId: inspectionId,
                sectionId: qs.questionSectionId,
                questionId: qi.questionId,
                answer: qi.textValue,
                textValue: qi.textValue,
                asset: image,
            });
        }
    }

    for (const ts of completedInspection.tyreSections) {
        let image: string = undefined;

        if (ts.imageUrl) {
            try {
                // eslint-disable-next-line no-await-in-loop
                image = await utils.getOfflineImageUrl(ts.imageUrl);
            } catch (e) {
                // Do nothing
            }
        }

        if (image) {
            const tyreSectionName = Object.values(TyreSectionName).find(n => n === ts.tyreName);
            // eslint-disable-next-line no-await-in-loop
            await inspectionAssetStore.storeOfflineAsset({
                inspectionAssetId: undefined,
                asset: image,
                inspectionId: inspectionId,
                mediaType: MediaType.Photo,
                type: TyreSectionNameToOfflineAssetType(tyreSectionName),
            });
        }

        for (const ti of ts.tyreItems) {
            // This is the way around it should be mapped
            let answer = ti.tyreTextValue;
            let textValue = ti.tyreQuestionText;

            // TODO: Remove this after a while? This is just to fix issues with erroneous inspections
            // This will allow users to copy inspections that are generating PDFs with GUIDs on display and sync the copy as a fix
            if (!Guid.isGuid(answer) && Guid.isGuid(textValue)) {
                answer = ti.tyreQuestionText;
                textValue = ti.tyreTextValue;
            }

            // eslint-disable-next-line no-await-in-loop
            await inspectionSectionStore.updateInspectionSectionAnswer({
                inspectionId: inspectionId,
                sectionId: ts.sectionId,
                questionId: ti.questionId,
                answer: answer,
                textValue: textValue,
            });
        }
    }

    return await inspectionDetailStore.load(inspectionId);
};

const inspectionService = {
    copy,
    details,
    getById,
    getByStatus,
    getByVrm,
    search,
    start,
    storeDetail,
    update,
    updateDetailWithCompletedInspection,
};

export default inspectionService;
