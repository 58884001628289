import { IconButton } from "@g3r-developers/g3-common";
import { InspectionModel } from "Types/Inspection/InspectionModel";
import { SectionModel } from "Types/Inspection/SectionModel";
import { useCallback, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { Col, Nav, NavItem, NavLink, Row } from "reactstrap";
import "../Sidebar.css";

export interface InspectionSidebarProps {
    activeSection: number;
    inspection: InspectionModel;
    isInspectionComplete: boolean;
    isSectionComplete: boolean[];
    sections: SectionModel[];
    setActiveSection(sectionName: string): void;
}

export default function InspectionSidebar({
    activeSection,
    inspection,
    isInspectionComplete,
    isSectionComplete,
    sections,
    setActiveSection,
}: InspectionSidebarProps) {
    const navigate = useNavigate();
    const handleBack = useCallback(() => {
        navigate(-1);
    }, [navigate]);

    return (
        <>
            <aside className={"left-sidebar pt-4"}>
                <div className="scroll-sidebar">
                    <div className="ps-3 pe-3">
                        <Row>
                            <Col md="6">
                                <IconButton
                                    className="mb-3"
                                    block
                                    text="Back"
                                    faIconString="fa fa-caret-left"
                                    onClick={handleBack}
                                />
                            </Col>
                            <Col md="6">
                                <IconButton
                                    className="mb-3"
                                    block
                                    text="Menu"
                                    faIconString="fa fa-bars"
                                    url="/inspections"
                                    variant="secondary"
                                />
                            </Col>
                        </Row>

                        <div className="text-center">
                            <Col xs="12" className="mb-2 text-center">
                                <span className="form-control bg-warning">
                                    <h1 className="text-bold mb-0">{inspection.vrm}</h1>
                                </span>
                            </Col>
                            <Col xs="12">
                                <span className="text-bold">
                                    {inspection.vehicleManufacturer.name} {inspection.vehicleModel.model}{" "}
                                    {inspection.vehicleType.display}
                                </span>
                            </Col>
                            <Col xs="12">
                                <span>
                                    VIN: <strong>{inspection.vin}</strong>
                                </span>
                            </Col>
                        </div>
                    </div>
                    <Nav className="mt-5" tabs vertical>
                        {sections.map((s, idx) => (
                            <InspectionSidebarItem
                                activeSection={activeSection}
                                idx={idx}
                                isComplete={isSectionComplete[idx]}
                                key={s.id}
                                s={s}
                                setActiveSection={setActiveSection}
                            />
                        ))}
                    </Nav>
                </div>
            </aside>
        </>
    );
}

export function InspectionSidebarItem({
    s,
    setActiveSection,
    activeSection,
    idx,
    isComplete,
}: {
    s: SectionModel;
    setActiveSection(sectionName: string): void;
    activeSection: number;
    idx: number;
    isComplete: boolean;
}) {
    const navClass = useMemo(() => {
        return (
            "inspection-nav" +
            (idx === activeSection && isComplete
                ? " active complete"
                : idx === activeSection && !isComplete
                ? " active incomplete"
                : "")
        );
    }, [idx, activeSection, isComplete]);

    const completeClass = useMemo(() => {
        if (isComplete) {
            return "text-success fas fa-check-circle";
        }

        if (s.name === "Summary") {
            return "fas fa-question-circle text-info";
        }

        return "text-danger fa fa-1x fa-exclamation-circle";
    }, [isComplete, s.name]);

    return (
        <NavItem className={navClass} key={`nav__${s.id}`} onClick={() => setActiveSection(s.name)}>
            <NavLink>
                <i className={`me-3  ${completeClass}`}></i>
                <span className="">{s.name}</span>
            </NavLink>
        </NavItem>
    );
}
