import { IconButton } from "@g3r-developers/g3-common";
import { DateTime } from "luxon";
import { useMemo, useState } from "react";
import { Alert, Col, Container, Row, Table } from "reactstrap";
import { InspectionStatus } from "Types/Enums/InspectionStatus";
import { InspectionModel } from "Types/Inspection/InspectionModel";
import CopyInspectionModal from "./CopyInspectionModal";

interface VehicleSearchConfirmationProps {
    inspection: InspectionModel;
}

export function VehicleSearchConfirmation({ inspection }: VehicleSearchConfirmationProps): JSX.Element {
    const [showCopyModal, setShowCopyModal] = useState<boolean>(false);

    const fullDescription = useMemo(() => {
        return `${inspection.vehicleManufacturer.name} ${inspection.vehicleModel.model} ${inspection.vehicleType.display}`;
    }, [inspection]);

    return (
        <>
            {inspection && (
                <Container className="p-5">
                    <Row>
                        <Col>
                            <h3>Is this the car you're looking for?</h3>
                        </Col>
                    </Row>
                    <Row className="mb-3">
                        <Col>Please check the details below</Col>
                    </Row>
                    <Row>
                        <Col md="3">
                            <Row>
                                <Col className="text-center">
                                    <img alt="Holding" src="https://media.g3r.co.uk/legacy/no-image.jpg" height="150" />
                                </Col>
                            </Row>
                            <Row className="mt-3">
                                <Col className="text-center">
                                    <span className="form-control bg-warning">
                                        <h3 className="text-bold mb-0">{inspection.vrm}</h3>
                                    </span>
                                </Col>
                            </Row>
                        </Col>
                        <Col md="9">
                            <Table>
                                <tbody>
                                    <tr>
                                        <td colSpan={2}>
                                            <h3 className="text-primary">{fullDescription}</h3>
                                        </td>
                                    </tr>
                                    <tr className="my-2">
                                        <td>Make: {inspection.vehicleManufacturer.name}</td>
                                        <td>Colour: {inspection.colour.display}</td>
                                    </tr>
                                    <tr>
                                        <td>Model: {inspection.vehicleModel.model}</td>
                                        <td>VIN: {inspection.vin}</td>
                                    </tr>
                                    <tr>
                                        <td>Fuel Type: {inspection.fuelType.display}</td>
                                        <td>Transmission: {inspection.transmission.display}</td>
                                    </tr>
                                </tbody>
                            </Table>
                        </Col>
                    </Row>

                    {inspection.status?.id === InspectionStatus.Completed && (
                        <Row className={"mt-4"}>
                            <Col>
                                <Alert color={"info"} fade={false}>
                                    <Row className={"align-items-center"}>
                                        <Col xs={"auto"}>
                                            <i className={"fas fa-info-circle"} />
                                        </Col>

                                        <Col>
                                            This inspection was completed{" "}
                                            {!!inspection?.inspectionCompletedTimeStamp &&
                                                DateTime.fromISO(inspection.inspectionCompletedTimeStamp).toFormat(
                                                    "dd/MM/yyyy HH:mm"
                                                )}
                                        </Col>
                                    </Row>
                                </Alert>
                            </Col>
                        </Row>
                    )}

                    <Row className="mt-5">
                        <Col xs={6}>
                            <IconButton block outline={true} text="Search Again" url="/" variant={"secondary"} />
                        </Col>
                        <Col xs={6}>
                            {inspection.status?.id === InspectionStatus.Completed ? (
                                <IconButton
                                    block
                                    onClick={() => setShowCopyModal(true)}
                                    text={"Copy Inspection"}
                                    variant={"primary"}
                                />
                            ) : (
                                <IconButton
                                    block
                                    text="Confirm"
                                    url={`/inspection/confirm/${inspection.id}`}
                                    variant={"primary"}
                                />
                            )}
                        </Col>
                    </Row>
                </Container>
            )}

            {showCopyModal && <CopyInspectionModal close={() => setShowCopyModal(false)} inspection={inspection} />}
        </>
    );
}
