import { Button, CardImg, Row } from "reactstrap";
import { OfflineAssetModel } from "Types/Inspection/OfflineAssetModel";
import { QuestionAnswerModel } from "Types/Question/QuestionAnswerModel";
import { QuestionComponent } from "../Question/QuestionComponent";

interface TyreQuestionsProps {
    handleChange(sectionId: string, questionId: string, newValue: string, textValue: string): void;
    questions: QuestionAnswerModel[];
    toggleShowCamera(): void;
    tyreImageAsset: OfflineAssetModel;
}

export default function TyreQuestions({
    handleChange,
    questions,
    toggleShowCamera,
    tyreImageAsset,
}: TyreQuestionsProps) {
    return (
        <>
            {questions.map(q => (
                <Row className={"align-items-center"} key={q.id}>
                    <QuestionComponent
                        handleChange={(questionId: string, newValue: string, textValue: string) =>
                            handleChange(q.sectionId, questionId, newValue, textValue)
                        }
                        md={12}
                        question={q}
                    />
                </Row>
            ))}
            <Row className={"align-items-center"}>
                <Button onClick={toggleShowCamera}>Photo</Button>
            </Row>
            {tyreImageAsset?.asset && (
                <Row className={"mt-2"}>
                    <CardImg alt={"Tyre Image"} src={tyreImageAsset.asset} width="100%" top />
                </Row>
            )}
        </>
    );
}
