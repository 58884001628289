import { QuestionAnswerModel } from "Types/Question/QuestionAnswerModel";

export const VehicleDetailsDisplay = (question: QuestionAnswerModel, questions: QuestionAnswerModel[]): boolean => {
    switch (question.questionText) {
        case "Number of Service Stamps": {
            const serviceHistoryQuestion = questions.find(q => q.questionText === "Vehicle Service History");

            if (!serviceHistoryQuestion.answer) {
                return false;
            }

            const allowed = serviceHistoryQuestion.choiceGroup.choiceItems
                .filter(ci => ci.reference !== "None")
                .map(ci => ci.id);

            return allowed.includes(serviceHistoryQuestion.answer);
        }
        default: {
            return true;
        }
    }
};

export const MechanicalConditionDisplay = (
    question: QuestionAnswerModel,
    questions: QuestionAnswerModel[]
): boolean => {
    if (question.questionText === "Have you test driven this vehicle?") {
        return true;
    }

    const testDrivenQuestion = questions.find(q => q.questionText === "Have you test driven this vehicle?");
    const shouldShowQuestion = testDrivenQuestion.answer && testDrivenQuestion.answer === "true";

    const warningLightsQuestion = questions.find(
        q => q.questionText === "Does the vehicle display any warning lights?"
    );

    // If the question is a "warning light" only show if there are warning lights
    if (question.questionText.endsWith("Light")) {
        return shouldShowQuestion && warningLightsQuestion.answer && warningLightsQuestion.answer === "true";
    } else {
        return shouldShowQuestion;
    }
};

const questionSectionDisplayService = {
    MechanicalConditionDisplay,
    VehicleDetailsDisplay,
};

export default questionSectionDisplayService;
