/* eslint-disable no-console */
import { IconButton } from "@g3r-developers/g3-common";
import inspectionAssetStore from "Services/DbStores/InspectionAssetStore";
import inspectionDetailStore from "Services/DbStores/InspectionDetailStore";
import utils from "Services/Utils";
import { MediaType } from "Types/Enums/MediaType";
import { InspectionAsset } from "Types/Inspection/InspectionConfig";
import { OfflineInspectionDetailModel } from "Types/Inspection/OfflineInspectionDetailModel";
import { Guid } from "guid-typescript";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Col, Modal, ModalBody, ModalFooter, Row } from "reactstrap";

const mimeType = 'video/webm; codecs="opus,vp8"';
interface VideoRecorderProps {
    inspection: OfflineInspectionDetailModel;
    updateInspectionDetail(inspection: OfflineInspectionDetailModel): void;
}

const VideoRecorder = ({ inspection, updateInspectionDetail }: VideoRecorderProps) => {
    const [permission, setPermission] = useState(false);

    const [deviceId, setDeviceId] = useState<string>(undefined);

    const mediaRecorder = useRef(null);
    const liveVideoFeed = useRef(null);

    const [recordingStatus, setRecordingStatus] = useState("inactive");

    const [stream, setStream] = useState<MediaStream>(null);

    const [recordedVideo, setRecordedVideo] = useState(null);

    const [videoChunks, setVideoChunks] = useState([]);

    const [showVideo, setShowVideo] = useState(false);

    const toggleShowVideo = useCallback(() => setShowVideo(p => !p), []);

    const loadVideoAsset = useCallback(async () => {
        console.log(inspection.inspection.videoAssetId);
        if (!inspection.inspection.videoAssetId) {
            return;
        }

        try {
            const asset = await inspectionAssetStore.loadInspectionAsset(inspection.inspection.videoAssetId);
            const blob = await utils.convertBase64ToBlob(asset.asset, "video/webm");
            const videoUrl = URL.createObjectURL(blob);

            setRecordedVideo(videoUrl);
        } catch (e) {
            // eslint-disable-next-line no-console
            console.error(e);
            const newInspectionDetails = {
                ...inspection,
                inspection: {
                    ...inspection.inspection,
                    videoAssetId: undefined,
                },
            };

            inspectionDetailStore.save(newInspectionDetails);
            updateInspectionDetail(newInspectionDetails);
        }
    }, [inspection, updateInspectionDetail]);

    useEffect(() => {
        loadVideoAsset();
    }, [loadVideoAsset]);
    useEffect(() => {
        const storedDeviceId = localStorage.getItem("CameraDeviceId");
        if (storedDeviceId) {
            setDeviceId(storedDeviceId);
        }
    }, []);

    const getCameraPermission = useCallback(async () => {
        setRecordedVideo(null);
        //get video and audio permissions and then stream the result media stream to the videoSrc variable
        if ("MediaRecorder" in window) {
            try {
                const videoConstraints = {
                    audio: false,
                    video: {
                        facingMode: "environment",
                        deviceId: deviceId,
                    } as MediaTrackConstraints,
                } as MediaStreamConstraints;
                const audioConstraints = { audio: true };

                // create audio and video streams separately
                const audioStream = await navigator.mediaDevices.getUserMedia(audioConstraints);
                const videoStream = await navigator.mediaDevices.getUserMedia(videoConstraints);

                setPermission(true);

                //combine both audio and video streams

                const combinedStream = new MediaStream([
                    ...videoStream.getVideoTracks(),
                    ...audioStream.getAudioTracks(),
                ]);

                setStream(combinedStream);

                //set videostream to live feed player
                liveVideoFeed.current.srcObject = videoStream;
            } catch (err) {
                alert(err.message);
            }
        } else {
            alert("The MediaRecorder API is not supported in your browser.");
        }
    }, [deviceId]);

    const startRecording = async () => {
        setRecordingStatus("recording");

        const media = new MediaRecorder(stream, { mimeType });

        mediaRecorder.current = media;

        mediaRecorder.current.start();

        const localVideoChunks = [];

        mediaRecorder.current.ondataavailable = event => {
            if (typeof event.data === "undefined") return;
            if (event.data.size === 0) return;
            localVideoChunks.push(event.data);
        };

        setVideoChunks(localVideoChunks);
    };

    const storeVideoAsset = useCallback(
        async (newVideo: Blob) => {
            if (!newVideo) {
                return;
            }

            const blobString = await utils.convertBlobToBase64(newVideo);

            if (inspection?.inspection?.videoAssetId) {
                const existingAsset = await inspectionAssetStore.loadInspectionAsset(
                    inspection.inspection.videoAssetId
                );

                // don't save the same video file
                if (existingAsset?.asset === blobString) {
                    return;
                }
            }

            const assetId = await inspectionAssetStore.storeAsset({
                asset: blobString,
                mediaType: MediaType.Video,
                inspectionAssetId: inspection?.inspection?.videoAssetId ?? Guid.create().toString(),
            } as InspectionAsset);

            const newInspectionDetails = {
                ...inspection,
                inspection: {
                    ...inspection.inspection,
                    videoAssetId: assetId,
                },
            };

            inspectionDetailStore.save(newInspectionDetails);
            updateInspectionDetail(newInspectionDetails);
        },
        [inspection, updateInspectionDetail]
    );

    const closeVideo = useCallback(() => {
        setPermission(false);
        const tracks = stream.getTracks();
        tracks.forEach(track => {
            track.stop();
        });
        setStream(undefined);
    }, [stream]);

    const stopRecording = () => {
        setPermission(false);
        setRecordingStatus("inactive");
        mediaRecorder.current.stop();

        mediaRecorder.current.onstop = () => {
            const videoBlob = new Blob(videoChunks, { type: mimeType });
            const videoUrl = URL.createObjectURL(videoBlob);

            setRecordedVideo(videoUrl);
            storeVideoAsset(videoBlob);

            setVideoChunks([]);
        };

        closeVideo();
    };

    const showTakeVideo = useMemo(() => {
        return !recordedVideo;
    }, [recordedVideo]);

    const handleDeleteVideo = useCallback(async () => {
        setRecordedVideo(undefined);
        setShowVideo(false);
        await inspectionAssetStore.remove(inspection.inspection.videoAssetId);
        const newInspectionDetails = {
            ...inspection,
            inspection: {
                ...inspection.inspection,
                videoAssetId: undefined,
            },
        };

        inspectionDetailStore.save(newInspectionDetails);
        updateInspectionDetail(newInspectionDetails);
    }, [inspection, updateInspectionDetail]);

    return (
        <div>
            <div className="video-controls">
                {showTakeVideo ? <IconButton onClick={getCameraPermission} text="Take Video" /> : null}
            </div>

            <div className="video-player">
                {!recordedVideo && permission && (
                    <>
                        <Modal fullscreen isOpen>
                            <video height="100%" width="100%" ref={liveVideoFeed} autoPlay className="w-100"></video>

                            <div style={{ position: "fixed", top: "50%", right: "2%", transform: "translateY(-50%)" }}>
                                <Row className="w-100">
                                    <Col xs={12}>
                                        <IconButton
                                            block
                                            onClick={stopRecording}
                                            variant="danger"
                                            faIconString="fa fa-3x fa-times"
                                        />
                                    </Col>
                                    <Col xs={12}>
                                        {recordingStatus === "inactive" && (
                                            <IconButton
                                                className="mt-3"
                                                block
                                                onClick={startRecording}
                                                variant="success"
                                                faIconString="fa fa-3x fa-video"
                                            />
                                        )}
                                        {recordingStatus === "recording" && (
                                            <IconButton
                                                className="mt-3"
                                                block
                                                onClick={stopRecording}
                                                variant="danger"
                                                faIconString="fa fa-3x fa-stop"
                                            />
                                        )}
                                    </Col>
                                </Row>
                            </div>
                        </Modal>
                    </>
                )}
                {recordedVideo && <IconButton onClick={toggleShowVideo} text="View Video" variant="success" />}

                {showVideo && (
                    <Modal size="xl" isOpen toggle={toggleShowVideo}>
                        <ModalBody>
                            <video src={recordedVideo} className="w-100" controls></video>
                        </ModalBody>
                        <ModalFooter>
                            <Row className="w-100">
                                <Col xs={12}>
                                    <IconButton
                                        block
                                        onClick={handleDeleteVideo}
                                        variant="danger"
                                        text="Delete Video"
                                    />
                                </Col>
                            </Row>
                        </ModalFooter>
                    </Modal>
                )}
            </div>
        </div>
    );
};

export default VideoRecorder;
