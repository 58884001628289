import { G3Input } from "@g3r-developers/g3-common";
import React from "react";
import { QuestionAnswerModel } from "Types/Question/QuestionAnswerModel";

interface QuestionNumericTextBoxProps {
    className?: string;
    error?: string;
    onChange(value: number): void;
    question: QuestionAnswerModel;
    step?: number;
}

export default function QuestionNumericTextBox({
    className,
    error,
    onChange,
    question,
    step,
}: QuestionNumericTextBoxProps) {
    return (
        <G3Input
            className={className + " h-100"}
            error={error}
            inputMode={"decimal"}
            onChange={onChange}
            step={step}
            type={"number"}
            value={question.answer}
        />
    );
}
