import { G3Modal } from "@g3r-developers/g3-common";
import React, { useCallback, useState } from "react";
import { Form, FormGroup, Input } from "reactstrap";
import inspectionDetailStore from "Services/DbStores/InspectionDetailStore";
import { OfflineInspectionDetailModel } from "Types/Inspection/OfflineInspectionDetailModel";

interface InspectionNotesModalProps {
    inspection: OfflineInspectionDetailModel;
    toggle(): void;
    updateInspectionDetail(inspection: OfflineInspectionDetailModel): void;
}

export default function InspectionNotesModal({
    inspection,
    toggle,
    updateInspectionDetail,
}: InspectionNotesModalProps) {
    const [notes, setNotes] = useState<string>(inspection.inspection.notes);

    const handleNotesChange = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            setNotes(e.target.value);
        },
        [setNotes]
    );

    const handleSave = useCallback(() => {
        const newInspectionDetails = {
            ...inspection,
            inspection: {
                ...inspection.inspection,
                notes: notes,
            },
        };

        inspectionDetailStore.save(newInspectionDetails);
        updateInspectionDetail(newInspectionDetails);
        toggle();
    }, [inspection, notes, toggle, updateInspectionDetail]);

    return (
        <G3Modal title="Inspection Notes" toggle={toggle} save={handleSave} saveText="Update">
            <Form>
                <FormGroup>
                    <Input type="textarea" rows={8} onChange={handleNotesChange} value={notes} />
                </FormGroup>
            </Form>
        </G3Modal>
    );
}
