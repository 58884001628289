// Adapted from https://usehooks.com/useWindowSize/
import { useEffect, useState } from "react";

export interface WindowSize {
    height: number;
    width: number;
}

export default function useWindowSize(): WindowSize {
    const [windowSize, setWindowSize] = useState<WindowSize>({
        height: window.document.documentElement.clientHeight,
        width: window.document.documentElement.clientWidth,
    });

    useEffect(() => {
        const handleResize = () => {
            setWindowSize({
                height: window.document.documentElement.clientHeight,
                width: window.document.documentElement.clientWidth,
            });
        };

        window.addEventListener("resize", handleResize);

        handleResize();

        return () => window.removeEventListener("resize", handleResize);
    }, []);

    return windowSize;
}
