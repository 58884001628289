import { G3Dropdown, IconButton } from "@g3r-developers/g3-common";
import Camera from "Components/Camera/Camera";
import { useCallback, useEffect, useState } from "react";
import { CardImg, Col, Modal, ModalBody, Row } from "reactstrap";

export default function CameraSelector(): JSX.Element {
    const [deviceId, setDeviceId] = useState<string>(undefined);
    const [devices, setDevices] = useState([]);
    const [showCamera, setShowCamera] = useState<boolean>(false);
    const [testImgSrc, setTestImgSrc] = useState<string>(undefined);

    const handleDevices = useCallback(
        (mediaDevices: MediaDeviceInfo[]) =>
            setDevices(
                mediaDevices
                    .filter(({ kind }) => kind === "videoinput")
                    .map(dev => {
                        return {
                            label: dev.label,
                            value: dev.deviceId,
                        };
                    })
            ),
        [setDevices]
    );

    useEffect(() => {
        const storedDeviceId = localStorage.getItem("CameraDeviceId");
        if (storedDeviceId) {
            setDeviceId(storedDeviceId);
        }
    }, []);

    useEffect(() => {
        navigator.mediaDevices.enumerateDevices().then(handleDevices);
    }, [handleDevices]);

    const loadDevices = useCallback(() => {
        return Promise.resolve(devices);
    }, [devices]);

    const handleDeviceChange = (e: string) => {
        setDeviceId(e);
        localStorage.setItem("CameraDeviceId", e);
    };

    const photoCaptured = useCallback(
        (img: string) => {
            setTestImgSrc(img);
            setShowCamera(false);
        },
        [setShowCamera]
    );

    const toggleCamera = useCallback(() => {
        setShowCamera(!showCamera);
    }, [showCamera]);

    return (
        <>
            Which camera should AVID use?
            {devices.length > 0 ? (
                <>
                    <G3Dropdown
                        defaultOption={deviceId}
                        loadOptions={loadDevices}
                        onChange={handleDeviceChange}
                        isSearchable={false}
                    />
                    <IconButton
                        block
                        className="my-2"
                        faIconString={"fas fa-camera"}
                        text="Take Test Photo"
                        onClick={() => setShowCamera(true)}
                        variant={"primary"}
                    />
                    {testImgSrc && (
                        <Row>
                            <Col xs="12" lg={{ offset: 3, size: 6 }} xl={{ offset: 4, size: 4 }}>
                                <CardImg alt={"Test Image"} src={testImgSrc} top />
                            </Col>
                        </Row>
                    )}
                </>
            ) : (
                <div>
                    <i className="fas fa-exclamation-triangle text-warning me-2" /> No devices detected
                </div>
            )}
            {showCamera && (
                <Modal className="p-0 m-0" isOpen fullscreen={true}>
                    <ModalBody className="p-0">
                        <Camera onPhoto={photoCaptured} toggleCamera={toggleCamera} />
                    </ModalBody>
                </Modal>
            )}
        </>
    );
}
