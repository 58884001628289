import Camera from "Components/Camera/Camera";
import { useMemo } from "react";
import { MarketingOverlayMappings } from "Services/OverlayMapping";

interface MarketingImageCameraProps {
    close(): void;
    extraText: string;
    onNewPhoto(image: string): void;
    questionText: string;
    imageNumber?: number;
    imageTotal?: number;
}

export default function MarketingImageCamera({
    close,
    extraText,
    onNewPhoto,
    questionText,
    imageNumber,
    imageTotal,
}: MarketingImageCameraProps) {
    const overlayUrl = useMemo(() => {
        const mapping = MarketingOverlayMappings.find(_ => _.key.trim() === questionText.trim());

        if (mapping) {
            return mapping.overlayUrl;
        }

        return undefined;
    }, [questionText]);

    return (
        <Camera
            label={questionText}
            description={extraText}
            onPhoto={onNewPhoto}
            toggleCamera={close}
            overlayUrl={overlayUrl}
            imageNumber={imageNumber}
            imageTotal={imageTotal}
        />
    );
}
