import { useMemo } from "react";
import { Button } from "reactstrap";
import { Identifiable } from "Types/Identifiable";

export interface DamageListItemProps<T> {
    item: Identifiable;
    changed(item: Identifiable): void;
    selected?: Identifiable;
}

export function DamageListItem<T>({ item, changed, selected }: DamageListItemProps<T>) {
    const listGroupColor = useMemo(() => {
        if (selected && selected.id === item.id) {
            return "success";
        }
        return "secondary";
    }, [selected, item]);

    const outline = useMemo(() => {
        if (selected && selected.id === item.id) {
            return false;
        }
        return true;
    }, [selected, item]);

    return (
        <Button className="mb-2" outline={outline} color={listGroupColor} onClick={() => changed(item)}>
            <span>{item.componentName || item.displayName || item.questionText || item.repairMethod}</span>
        </Button>
    );
}
