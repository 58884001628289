import { OverlayMapping } from "Types/OverlayMapping";

export const MarketingOverlayMappings: OverlayMapping[] = [
    {
        key: "Front Number Plate",
        overlayUrl: "https://g3-web.s3.eu-west-2.amazonaws.com/overlays/front.svg",
    },
    {
        key: "Offside Front Corner",
        overlayUrl: "https://g3-web.s3.eu-west-2.amazonaws.com/overlays/nsf.svg",
    },
    {
        key: "Offside Side Exterior",
        overlayUrl: "https://g3-web.s3.eu-west-2.amazonaws.com/overlays/ns.svg",
    },
    {
        key: "Offside Front Interior",
        overlayUrl: "https://g3-web.s3.eu-west-2.amazonaws.com/overlays/driver_door.svg",
    },
    {
        key: "Offside Rear Interior",
        overlayUrl: "https://g3-web.s3.eu-west-2.amazonaws.com/overlays/driver_door.svg",
    },
    {
        key: "Offside Rear Corner",
        overlayUrl: "https://g3-web.s3.eu-west-2.amazonaws.com/overlays/nsr.svg",
    },
    {
        key: "Rear Number Plate",
        overlayUrl: "https://g3-web.s3.eu-west-2.amazonaws.com/overlays/rear.svg",
    },
    {
        key: "Nearside Rear Corner",
        overlayUrl: "https://g3-web.s3.eu-west-2.amazonaws.com/overlays/osr.svg",
    },
    {
        key: "Nearside Side Exterior",
        overlayUrl: "https://g3-web.s3.eu-west-2.amazonaws.com/overlays/os.svg",
    },
    {
        key: "Nearside Front Corner",
        overlayUrl: "https://g3-web.s3.eu-west-2.amazonaws.com/overlays/osf.svg",
    },
    {
        key: "Odometer",
        overlayUrl: "https://g3-web.s3.eu-west-2.amazonaws.com/overlays/odometer.svg",
    },
];
