import { G3Input } from "@g3r-developers/g3-common";
import inspectionAssetStore from "Services/DbStores/InspectionAssetStore";
import inspectionDetailStore from "Services/DbStores/InspectionDetailStore";
import inspectionSectionStore from "Services/DbStores/InspectionSectionStore";
import { InspectionType } from "Types/Enums/InspectionType";
import { MediaType } from "Types/Enums/MediaType";
import { OfflineAssetType } from "Types/Inspection/Enums/OfflineAssetType";
import { SectionNames } from "Types/Inspection/Enums/SectionNames";
import { InspectionDetailModel } from "Types/Inspection/InspectionDetailModel";
import { OfflineAssetModel } from "Types/Inspection/OfflineAssetModel";
import { OfflineMarketingImageModel } from "Types/Inspection/OfflineSplatModel";
import { SectionModel } from "Types/Inspection/SectionModel";
import { QuestionAnswerModel } from "Types/Question/QuestionAnswerModel";
import { DateTime } from "luxon";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Button, Col, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";
import AdditionalMarketingImage from "./AdditionalMarketingImage";
import MarketingImageCamera from "./MarketingImageCamera";
import OffsiteMarketingImage from "./OffsiteMarketingImage";

export interface MarketingSectionProps {
    inspectionId: string;
    section: SectionModel;
    sectionIndex: number;
    placeholders: OfflineMarketingImageModel[];
    handleComplete: () => void;
    handleNextStep: () => void;
    handleSectionComplete(index: number, state: boolean): void;
    nextAvailable: boolean;
    reloadInspectionDetail(inspectionId: string): void;
    inspection: InspectionDetailModel;
}

export default function MarketingSection({
    inspectionId,
    section,
    sectionIndex,
    placeholders,
    handleComplete,
    handleNextStep,
    handleSectionComplete,
    nextAvailable,
    reloadInspectionDetail,
    inspection,
}: MarketingSectionProps) {
    const [additionalImages, setAdditionalImages] = useState<OfflineAssetModel[]>([]);
    const [currentPhoto, setCurrentPhoto] = useState<QuestionAnswerModel>(undefined);
    const [images, setImages] = useState<QuestionAnswerModel[]>([]);
    const [selectedImageNo, setSelectedImageNo] = useState<number>(undefined);
    const [refreshAdditionalImages, setRefreshAdditionalImages] = useState<boolean>(false);
    const [showAdditionalPhotoCamera, setShowAdditionalPhotoCamera] = useState<boolean>(false);
    const [mileage, setMileage] = useState("");
    const [mileageSection, setMileageSection] = useState<string>(undefined);

    const [showMileageModal, setShowMileageModal] = useState<boolean>(false);

    const handleChange = useCallback(
        async (newValue: string, textValue: string) => {
            setMileage(newValue);
            await inspectionSectionStore.updateInspectionSectionAnswer({
                inspectionId: inspectionId,
                sectionId: mileageSection,
                questionId: "0b175b93-daec-41fe-b10e-3733485a7c60",
                answer: newValue,
                textValue: textValue,
            });
        },
        [inspectionId, mileageSection]
    );

    const toggleModal = useCallback(() => setShowMileageModal(p => !p), []);

    const confirmMileage = useCallback(() => {
        reloadInspectionDetail(inspectionId);
        toggleModal();
    }, [inspectionId, reloadInspectionDetail, toggleModal]);

    const refreshArray = useCallback(async () => {
        const allImages = await inspectionSectionStore.getSectionFromSection(inspectionId, section);
        setImages(allImages);

        const untakenImages = allImages.filter(i => !i.answer);
        return untakenImages;
    }, [inspectionId, section]);

    const getAdditionalMarketingImages = useCallback(async (inspectionId: string) => {
        const images = await inspectionAssetStore.getOfflineAssets(
            inspectionId,
            OfflineAssetType.AdditionalMarketingImage
        );

        const sortedImages = images.sort(
            (a, b) => DateTime.fromISO(a.added).toMillis() - DateTime.fromISO(b.added).toMillis()
        );

        setAdditionalImages(sortedImages);
    }, []);

    const selectMarketingImage = useCallback((question?: QuestionAnswerModel, imageIndex?: number) => {
        setCurrentPhoto(question);

        if (!isNaN(imageIndex)) {
            setSelectedImageNo(imageIndex + 1);
        }
    }, []);

    const handleRefreshAdditionalImages = useCallback(() => setRefreshAdditionalImages(p => !p), []);

    const handleNewAdditionalPhoto = useCallback(
        async (img: string) => {
            await inspectionAssetStore.storeOfflineAsset({
                asset: img,
                inspectionId: inspectionId,
                mediaType: MediaType.Photo,
                type: OfflineAssetType.AdditionalMarketingImage,
            });

            handleRefreshAdditionalImages();
        },
        [handleRefreshAdditionalImages, inspectionId]
    );

    const handleNewOffsiteMarketingPhoto = useCallback(
        async (questionId: string, img: string) => {
            // Add new photo
            await inspectionSectionStore.updateInspectionSectionAnswer({
                inspectionId: inspectionId,
                sectionId: section.id,
                questionId: questionId,
                answer: img,
                textValue: "Photo",
                asset: img,
            });

            const inspection = await inspectionDetailStore.load(inspectionId);
            const detailsSection = inspection.sections.find(
                _ => _.name === SectionNames.Interior || _.name === SectionNames.VehicleDetails
            );

            // If we've taken the Odometer shot and there is an "Interior" or "Details" section, pop up the Mileage modal
            if (questionId === "709b5d45-89de-40e9-a3a8-f2ae9dbdb145" && detailsSection) {
                setMileageSection(detailsSection.id);
                setShowMileageModal(true);
            }

            // are there any untaken images?
            const untakenImages = await refreshArray();

            // If there are, show the marketing camera for the next image
            if (untakenImages.length) {
                const question = untakenImages[0];
                const imageNumber = images.findIndex(i => i.id === question.id);
                selectMarketingImage(question, imageNumber);
            } else {
                // If there aren't, just hide the camera
                selectMarketingImage(undefined, undefined);
            }
        },
        [images, inspectionId, refreshArray, section.id, selectMarketingImage]
    );

    const handleRemoveAdditionalImage = useCallback(
        async (image: OfflineAssetModel) => {
            await inspectionAssetStore.remove(image.inspectionAssetId);

            handleRefreshAdditionalImages();
        },
        [handleRefreshAdditionalImages]
    );

    const closeAdditionalImages = useCallback(() => {
        setShowAdditionalPhotoCamera(false);

        if (images.every(i => !!i.answer)) {
            if (!nextAvailable) {
                handleComplete();
            } else {
                handleNextStep();
            }
        }
    }, [handleComplete, handleNextStep, images, nextAvailable]);

    useEffect(() => {
        getAdditionalMarketingImages(inspectionId);
    }, [getAdditionalMarketingImages, inspectionId, refreshAdditionalImages]);

    useEffect(() => {
        refreshArray();
    }, [refreshArray]);

    useEffect(() => {
        if (section.questions.length === images.filter(q => q.answer !== undefined).length) {
            handleSectionComplete(sectionIndex, true);
        }
    }, [images, section, handleSectionComplete, sectionIndex]);

    const scrollInto = useRef(null);

    useEffect(() => {
        if (!images?.length) {
            return;
        }

        if (images[0].answer) {
            return;
        } else {
            window.scrollTo(0, document.body.scrollHeight);
            selectMarketingImage(images[0], 0);
        }
    }, [images, selectMarketingImage, scrollInto]);

    const isLite = useMemo(() => {
        return inspection.inspection.inspectionTypeId === InspectionType.SignalLite;
    }, [inspection.inspection.inspectionTypeId]);

    return (
        <>
            <Row className={"mb-4"}>
                <span className="mb-2 text-bold">
                    Please select an image below to open the camera, then follow the instructions to photograph the
                    vehicle
                </span>
                {images.map((q: QuestionAnswerModel, index) => (
                    <Col sm={4} md={3} className="mb-3" key={`img__${q.id}`}>
                        <OffsiteMarketingImage
                            onClick={() => selectMarketingImage(q, index)}
                            question={q}
                            placeholder={placeholders.find(p => p.id === q.id)}
                        />
                    </Col>
                ))}
            </Row>

            <div ref={scrollInto} style={{ color: "white" }}></div>

            {!isLite && (
                <Row className={"flex-column"}>
                    <Col className={"mb-3"}>
                        <h4 className={"fw-bold"}>Additional Marketing Images</h4>
                        <div className={"form-text"}>You may add any number of additional images</div>
                    </Col>

                    <Col>
                        <Row>
                            {additionalImages.map((img, i) => (
                                <Col className={"mb-3"} key={`add_img__${img.inspectionAssetId}`} md={3}>
                                    <AdditionalMarketingImage
                                        imageTaken={true}
                                        onRemove={() => handleRemoveAdditionalImage(img)}
                                        name={`Additional Image #${i + 1}`}
                                        src={img.asset}
                                    />
                                </Col>
                            ))}

                            <Col className={"mb-3"} sm={4} md={3}>
                                <AdditionalMarketingImage
                                    imageTaken={false}
                                    onClick={() => setShowAdditionalPhotoCamera(true)}
                                    name={`Add another image`}
                                    src={"/add_placeholder.jpg"}
                                />
                            </Col>
                        </Row>
                    </Col>
                </Row>
            )}

            {showAdditionalPhotoCamera && (
                <Modal className="p-0 m-0" isOpen fullscreen={true}>
                    <ModalBody className="p-0">
                        <MarketingImageCamera
                            close={closeAdditionalImages}
                            extraText={"Take any additional images, or press the red arrow to close the camera"}
                            onNewPhoto={image => handleNewAdditionalPhoto(image)}
                            questionText={`Additional Image #${additionalImages.length + 1}`}
                        />
                    </ModalBody>
                </Modal>
            )}

            {!!currentPhoto && (
                <Modal className="p-0 m-0" isOpen fullscreen={true}>
                    <ModalBody className="p-0">
                        <MarketingImageCamera
                            close={() => setCurrentPhoto(undefined)}
                            extraText={currentPhoto.extraText}
                            onNewPhoto={image => handleNewOffsiteMarketingPhoto(currentPhoto.id, image)}
                            questionText={currentPhoto.questionText}
                            imageNumber={selectedImageNo}
                            imageTotal={images?.length}
                        />
                    </ModalBody>
                </Modal>
            )}

            {!!showMileageModal && (
                <Modal isOpen centered={true} toggle={toggleModal}>
                    <ModalHeader toggle={toggleModal}>Add Mileage</ModalHeader>
                    <ModalBody>
                        <Row className="d-flex align-items-center">
                            <Col md={6}>
                                <Label className="mb-0">Please Confirm Mileage</Label>
                            </Col>
                            <Col md={6}>
                                <G3Input
                                    className={"p-3 h-100"}
                                    inputMode={"decimal"}
                                    onChange={val => handleChange(val.toString(), val.toString())}
                                    type={"number"}
                                    value={mileage}
                                />
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <Button block onClick={confirmMileage}>
                            Confirm
                        </Button>
                    </ModalFooter>
                </Modal>
            )}
        </>
    );
}
