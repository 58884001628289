import { ComponentRepairMethodModel, NamaSplatOverlayItemModel } from "Types/Inspection/NamaSplatOverlayItemModel";
import { DB_NAMES, initDb } from "../QuestionStore";

interface SplatComponents {
    components: NamaSplatOverlayItemModel[];
    splatId: string;
}

const orderedRepairMethods = [
    "ungraded",
    "advisory",
    "polish",
    "touch in",
    "smart glass",
    "paintless dent repair",
    "refinish",
    "repair and refinish",
    "replace",
];

const orderRepairMethods = (repairMethods: ComponentRepairMethodModel[]) => {
    const unsortableRepairMethods = repairMethods.filter(
        rm => orderedRepairMethods.indexOf(rm.repairMethod?.toLowerCase()) < 0
    );
    const sortableRepairMethods = repairMethods.filter(
        rm => orderedRepairMethods.indexOf(rm.repairMethod?.toLowerCase()) >= 0
    );

    const sortedRepairMethods = sortableRepairMethods.sort(
        (a, b) =>
            orderedRepairMethods.indexOf(a.repairMethod?.toLowerCase()) -
            orderedRepairMethods.indexOf(b.repairMethod?.toLowerCase())
    );

    return sortedRepairMethods.concat(unsortableRepairMethods);
};

const load = async (splatId: string): Promise<NamaSplatOverlayItemModel[]> => {
    const db = await initDb();
    const value = await db.getValue<SplatComponents>(DB_NAMES.INSPECTION_SPLAT_COMPONENT, splatId);
    // return value?.components ?? [];

    return value?.components?.length
        ? value.components.map(c => ({
              ...c,
              component: {
                  ...c.component,
                  repairMethods: orderRepairMethods(c.component.repairMethods),
              },
          }))
        : [];
};

const remove = async (splatId: string): Promise<void> => {
    // const db = await initDb();
    // await db.deleteValue(DB_NAMES.INSPECTION_SPLAT_COMPONENT, splatId);
};

const save = async (splatId: string, components: NamaSplatOverlayItemModel[]): Promise<void> => {
    const splatComponents: SplatComponents = {
        components,
        splatId,
    };

    const db = await initDb();
    await db.putValue(DB_NAMES.INSPECTION_SPLAT_COMPONENT, splatComponents);
};

const inspectionSplatComponentStore = {
    load,
    remove,
    save,
};

export default inspectionSplatComponentStore;
