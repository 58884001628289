import LoginForm from "Components/LoginForm";
import { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import authService from "Services/AuthenticationService";

export default function Login(): JSX.Element {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();

    useEffect(() => {
        authService
            .getSession()
            .then(() => {
                const redirectUrl = searchParams?.get("redirectUrl");
                navigate(redirectUrl ?? "/dashboard", { replace: true });
            })
            .catch();
    }, [searchParams, navigate]);

    return (
        <div className="text-center m-0a">
            <div className="w-50 align-self-center m-0a">
                <LoginForm />
            </div>
        </div>
    );
}
