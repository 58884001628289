import { QuestionAnswerModel } from "Types/Question/QuestionAnswerModel";
import { useCallback } from "react";
import { QuestionCheckbox } from "./QuestionCheckbox";

export function LightQuestion({ question, handleChange }) {
    const handleLightChange = useCallback(
        newValue => {
            const newAnswer = newValue === "true" ? "illuminated" : "not illuminated";
            handleChange(newAnswer);
        },
        [handleChange]
    );

    const localQuestion = {
        ...question,
        answer: question.answer === "illuminated" ? "true" : "false",
    } as QuestionAnswerModel;

    return <QuestionCheckbox onChange={handleLightChange} question={localQuestion} />;
}
