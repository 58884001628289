import { MarketingImage } from "./MarketingImage";

interface AdditionalMarketingImageProps {
    imageTaken: boolean;
    name: string;
    onClick?: () => void;
    onRemove?: () => void;
    src?: string;
}

export default function AdditionalMarketingImage({
    imageTaken,
    name,
    onClick,
    onRemove,
    src,
}: AdditionalMarketingImageProps) {
    return <MarketingImage imageTaken={imageTaken} onClick={onClick} name={name} onRemove={onRemove} src={src ?? ""} />;
}
