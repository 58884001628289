import sections from "Constants/Sections";
import { InspectionType } from "Types/Enums/InspectionType";
import { SectionNames } from "Types/Inspection/Enums/SectionNames";
import { VehicleType } from "Types/Inspection/Enums/VehicleType";
import { OfflineInspectionDetailModel } from "Types/Inspection/OfflineInspectionDetailModel";
import { SectionModel } from "Types/Inspection/SectionModel";
import { QuestionGroupModel } from "Types/InspectionStaging/QuestionGroupModel";
import inspectionDetailStore from "./DbStores/InspectionDetailStore";
import inspectionSectionStore from "./DbStores/InspectionSectionStore";

interface InspectionValidation {
    assuredChecks?: boolean;
    assuredUnderBonnet?: boolean;
    audio?: boolean;
    damage?: boolean;
    exterior?: boolean;
    inspection?: boolean;
    interior?: boolean;
    marketing?: boolean;
    mechanicalCondition?: boolean;
    signalTyre?: boolean;
    vehicleDetails?: boolean;
    vehicleEquipment?: boolean;
    nsf?: boolean;
    nsr?: boolean;
    osf?: boolean;
    osr?: boolean;
    spare?: boolean;
    front?: boolean;
    back?: boolean;
}

type InspectionValidationKey = keyof InspectionValidation;

const shouldValidate = (keys: InspectionValidationKey[], key: InspectionValidationKey) =>
    !keys?.length || keys.includes(key);

const validateMehanicalCondition = (questionGroup: QuestionGroupModel, section: SectionModel): boolean => {
    const haveYouTestDrivenThisVehicleAnswer = questionGroup?.questionItemModels?.find(
        a => a.questionId === sections.mechanicalCondition.questions.haveYouTestDrivenThisVehicle.id
    );

    const hasNotTestDrivenVehicle = haveYouTestDrivenThisVehicleAnswer?.actualValue === "false";

    if (hasNotTestDrivenVehicle) {
        return true;
    }

    const warningLightQuestions = [
        sections.mechanicalCondition.questions.absWarningLight.id,
        sections.mechanicalCondition.questions.adBlueWarningLight.id,
        sections.mechanicalCondition.questions.airBagWarningLight.id,
        sections.mechanicalCondition.questions.breakWearIndicatorLight.id,
        sections.mechanicalCondition.questions.engineManagementLight.id,
        sections.mechanicalCondition.questions.oilWarningLight.id,
        sections.mechanicalCondition.questions.serviceIndicatorLight.id,
    ];

    let warningLightCheck = true;

    const doesTheVehicleDisplayAnyWarningLightsAnswer = questionGroup?.questionItemModels?.find(
        a => a.questionId === sections.mechanicalCondition.questions.doesTheVehicleDisplayAnyWarningLights.id
    );

    const isWarningLightAnswerRequired = doesTheVehicleDisplayAnyWarningLightsAnswer?.actualValue === "true";

    if (isWarningLightAnswerRequired) {
        const warningLightAnswers = questionGroup?.questionItemModels?.filter(a =>
            warningLightQuestions.includes(a.questionId)
        );

        warningLightCheck = warningLightAnswers.some(a => a.actualValue);
    }

    const filteredAnswers = questionGroup?.questionItemModels?.filter(
        a => !warningLightQuestions.includes(a.questionId)
    );
    const filteredQuestions = section?.questions?.filter(q => !warningLightQuestions.includes(q.id));

    return warningLightCheck && filteredAnswers?.filter(a => a.actualValue)?.length === filteredQuestions?.length;
};

const validateOffsiteMarketing = (questionGroup: QuestionGroupModel, section: SectionModel): boolean => {
    if (!section) {
        return true;
    }

    return questionGroup?.questionItemModels?.filter(q => q.actualValue)?.length === section?.questions?.length;
};

const validateSignalTyre = (questionGroups: QuestionGroupModel[], section: SectionModel): boolean => {
    if (!section) {
        return true;
    }

    const spareSectionName = sections.signalTyre.sections.spare.name;

    const questionsToIncludeNoSpare: string[] = [];
    questionsToIncludeNoSpare.push(sections.signalTyre.questions.tyreDepth.id);

    const flattenedAnswersNoSpare = questionGroups
        ?.filter(qg => qg.questionGroupText !== spareSectionName)
        ?.flatMap(g => g.questionItemModels.filter(q => questionsToIncludeNoSpare.includes(q.questionId)));

    const flattenedQuestionsNoSpare = section?.sections
        ?.filter(s => s.name !== spareSectionName)
        ?.flatMap(s => s.questions.filter(q => questionsToIncludeNoSpare.includes(q.id)));

    const spareSection = section?.sections?.find(s => s.name === spareSectionName);
    const spare = {
        answers: [],
        questions: [],
    };

    if (spareSection) {
        const spareQuestionIds = [sections.signalTyre.sections.spare.questions.spareType.id];

        const spareAnswers =
            questionGroups?.find(qg => qg.questionGroupText === spareSectionName)?.questionItemModels ?? [];
        const spareTypeAnswer = spareAnswers.find(
            a => a.questionId === sections.signalTyre.sections.spare.questions.spareType.id
        );

        const isRepairKitExpiryRequired =
            spareTypeAnswer?.actualValue ===
            sections.signalTyre.sections.spare.questions.spareType.choiceGroup.choiceItems.repairKit.id;

        if (isRepairKitExpiryRequired) {
            spareQuestionIds.push(sections.signalTyre.sections.spare.questions.repairKitExpiry.id);
        }

        const isTyreManufacturerRequired =
            spareTypeAnswer?.actualValue ===
            sections.signalTyre.sections.spare.questions.spareType.choiceGroup.choiceItems.tyre.id;

        if (isTyreManufacturerRequired) {
            spareQuestionIds.push(sections.signalTyre.sections.spare.questions.tyreManufacturer.id);
        }

        spare.answers = spareAnswers.filter(a => spareQuestionIds.includes(a.questionId));
        spare.questions = spareSection?.questions?.filter(q => spareQuestionIds.includes(q.id));
    }

    const flattenedAnswers = flattenedAnswersNoSpare?.concat(spare.answers);
    const flattenedQuestions = flattenedQuestionsNoSpare?.concat(spare.questions);

    return flattenedAnswers.filter(a => a.actualValue).length === flattenedQuestions?.length;
};

const validateVehicleDetails = (questionGroup: QuestionGroupModel, section: SectionModel): boolean => {
    if (!section) {
        return true;
    }

    if (!questionGroup?.questionItemModels?.length) {
        return false;
    }

    const serviceHistoryAnswer = questionGroup.questionItemModels.find(
        a => a.questionId === sections.vehicleDetails.questions.vehicleServiceHistory.id
    );

    const questionsToExclude: string[] = [];

    const isNumberOfServiceStampsRequired =
        serviceHistoryAnswer?.actualValue !==
        sections.vehicleDetails.questions.vehicleServiceHistory.choiceGroup.choiceItems.none.id;

    if (!isNumberOfServiceStampsRequired) {
        questionsToExclude.push(sections.vehicleDetails.questions.numberOfServiceStamps.id);
    }

    const filteredAnswers = questionGroup.questionItemModels.filter(q => !questionsToExclude.includes(q.questionId));
    const filteredQuestions = section.questions.filter(q => !questionsToExclude.includes(q.id));

    return filteredAnswers.filter(a => a.actualValue).length === filteredQuestions.length;
};

const validateFullSection = (questionGroup: QuestionGroupModel, section: SectionModel, isRequired = false): boolean => {
    if (isRequired && !section) {
        return false;
    }

    if (!section) {
        return true;
    }

    // Ensure an asset exists for the mileage question if applicable
    if (
        section.name === SectionNames.Interior &&
        questionGroup?.questionItemModels?.some(
            q => q.questionId === sections.interior.questions.mileage.id && !q.assetId
        )
    ) {
        return false;
    }

    return questionGroup?.questionItemModels?.filter(q => q.actualValue)?.length >= section?.questions?.length;
};

const validateAudio = (inspection: OfflineInspectionDetailModel): boolean => {
    return inspection.inspection?.audioAssetId ? true : false;
};

const validateSpareSection = (questionGroup: QuestionGroupModel, section: SectionModel): boolean => {
    if (!section) {
        return false;
    }

    return questionGroup?.questionItemModels?.filter(q => q.actualValue)?.length > 0;
};

const validateInspection = async (inspectionId: string, onlyValidate?: InspectionValidationKey[]): Promise<boolean> => {
    const inspectionDetail = await inspectionDetailStore.load(inspectionId);
    const inspectionSections = await inspectionSectionStore.loadInspectionSections(inspectionId);

    const signalTyreSectionNames = [
        sections.signalTyre.sections.nearSideFront.name,
        sections.signalTyre.sections.nearSideRear.name,
        sections.signalTyre.sections.offSideFront.name,
        sections.signalTyre.sections.offSideRear.name,
        sections.signalTyre.sections.spare.name,
    ];

    const validation: InspectionValidation = {};

    switch (inspectionDetail.inspection.inspectionTypeId) {
        case InspectionType.Signal:
            if (shouldValidate(onlyValidate, "vehicleDetails")) {
                validation.vehicleDetails = validateVehicleDetails(
                    inspectionSections.find(s => s.questionGroupText === sections.vehicleDetails.name),
                    inspectionDetail.sections.find(s => s.name === sections.vehicleDetails.name)
                );
            }

            if (shouldValidate(onlyValidate, "marketing")) {
                validation.marketing = validateOffsiteMarketing(
                    inspectionSections.find(s => s.questionGroupText === sections.offsiteMarketing.name),
                    inspectionDetail.sections.find(s => s.name === sections.offsiteMarketing.name)
                );
            }

            if (shouldValidate(onlyValidate, "signalTyre")) {
                validation.signalTyre = validateSignalTyre(
                    inspectionSections.filter(s => signalTyreSectionNames.includes(s.questionGroupText)),
                    inspectionDetail.sections.find(s => s.name === sections.signalTyre.name)
                );
            }

            if (shouldValidate(onlyValidate, "mechanicalCondition")) {
                validation.mechanicalCondition = validateMehanicalCondition(
                    inspectionSections.find(s => s.questionGroupText === sections.mechanicalCondition.name),
                    inspectionDetail.sections.find(s => s.name === sections.mechanicalCondition.name)
                );
            }

            break;
        case InspectionType.SignalLite:
            if (shouldValidate(onlyValidate, "marketing")) {
                validation.marketing = validateOffsiteMarketing(
                    inspectionSections.find(s => s.questionGroupText === sections.offsiteMarketing.name),
                    inspectionDetail.sections.find(s => s.name === sections.offsiteMarketing.name)
                );
            }
            break;
        case InspectionType.StandardInspection:
            if (shouldValidate(onlyValidate, "exterior")) {
                validation.exterior = validateFullSection(
                    inspectionSections.find(s => s.questionGroupText === sections.exterior.name),
                    inspectionDetail.sections.find(s => s.name === sections.exterior.name)
                );
            }

            if (shouldValidate(onlyValidate, "interior")) {
                validation.interior = validateFullSection(
                    inspectionSections.find(s => s.questionGroupText === sections.interior.name),
                    inspectionDetail.sections.find(s => s.name === sections.interior.name)
                );
            }

            if (shouldValidate(onlyValidate, "audio")) {
                validation.audio = validateAudio(inspectionDetail);
            }

            if (shouldValidate(onlyValidate, "inspection")) {
                validation.inspection = validateFullSection(
                    inspectionSections.find(s => s.questionGroupText === sections.inspection.name),
                    inspectionDetail.sections.find(s => s.name === sections.inspection.name)
                );
            }

            if (shouldValidate(onlyValidate, "nsf")) {
                validation.nsf =
                    inspectionDetail.inspection.vehicleTypeId === VehicleType.Motorbike
                        ? true
                        : validateFullSection(
                              inspectionSections.find(s => s.questionGroupText === sections.tyre.nsf.name),
                              inspectionDetail.sections
                                  .find(s => s.name === sections.tyre.name)
                                  .sections.find(s => s.name === sections.tyre.nsf.name),
                              true
                          );
            }

            if (shouldValidate(onlyValidate, "nsr")) {
                validation.nsr =
                    inspectionDetail.inspection.vehicleTypeId === VehicleType.Motorbike
                        ? true
                        : validateFullSection(
                              inspectionSections.find(s => s.questionGroupText === sections.tyre.nsr.name),
                              inspectionDetail.sections
                                  .find(s => s.name === sections.tyre.name)
                                  .sections.find(s => s.name === sections.tyre.nsr.name),
                              true
                          );
            }

            if (shouldValidate(onlyValidate, "osf")) {
                validation.osf =
                    inspectionDetail.inspection.vehicleTypeId === VehicleType.Motorbike
                        ? true
                        : validateFullSection(
                              inspectionSections.find(s => s.questionGroupText === sections.tyre.osf.name),
                              inspectionDetail.sections

                                  .find(s => s.name === sections.tyre.name)
                                  .sections.find(s => s.name === sections.tyre.osf.name),
                              true
                          );
            }

            if (shouldValidate(onlyValidate, "osr")) {
                validation.osr =
                    inspectionDetail.inspection.vehicleTypeId === VehicleType.Motorbike
                        ? true
                        : validateFullSection(
                              inspectionSections.find(s => s.questionGroupText === sections.tyre.osr.name),
                              inspectionDetail.sections

                                  .find(s => s.name === sections.tyre.name)
                                  .sections.find(s => s.name === sections.tyre.osr.name),
                              true
                          );
            }

            if (shouldValidate(onlyValidate, "spare")) {
                validation.spare =
                    inspectionDetail.inspection.vehicleTypeId === VehicleType.Motorbike
                        ? true
                        : validateSpareSection(
                              inspectionSections.find(s => s.questionGroupText === sections.tyre.spare.name),
                              inspectionDetail.sections
                                  .find(s => s.name === sections.tyre.name)
                                  .sections.find(s => s.name === sections.tyre.spare.name)
                          );
            }

            if (shouldValidate(onlyValidate, "front")) {
                validation.front =
                    inspectionDetail.inspection.vehicleTypeId !== VehicleType.Motorbike
                        ? true
                        : validateFullSection(
                              inspectionSections.find(s => s.questionGroupText === sections.tyre.front.name),
                              inspectionDetail.sections
                                  .find(s => s.name === sections.tyre.name)
                                  .sections.find(s => s.name === sections.tyre.front.name)
                          );
            }

            if (shouldValidate(onlyValidate, "back")) {
                validation.back =
                    inspectionDetail.inspection.vehicleTypeId !== VehicleType.Motorbike
                        ? true
                        : validateFullSection(
                              inspectionSections.find(s => s.questionGroupText === sections.tyre.back.name),
                              inspectionDetail.sections
                                  .find(s => s.name === sections.tyre.name)
                                  .sections.find(s => s.name === sections.tyre.back.name)
                          );
            }
            break;
        case InspectionType.AssuredInspection:
            if (shouldValidate(onlyValidate, "assuredUnderBonnet")) {
                validation.assuredUnderBonnet = validateFullSection(
                    inspectionSections.find(s => s.questionGroupText === sections.assuredUnderBonnet.name),
                    inspectionDetail.sections.find(s => s.name === sections.assuredUnderBonnet.name)
                );
            }

            if (shouldValidate(onlyValidate, "assuredChecks")) {
                validation.assuredChecks = validateFullSection(
                    inspectionSections.find(s => s.questionGroupText === sections.assuredChecks.name),
                    inspectionDetail.sections.find(s => s.name === sections.assuredChecks.name)
                );
            }

            if (shouldValidate(onlyValidate, "exterior")) {
                validation.exterior = validateFullSection(
                    inspectionSections.find(s => s.questionGroupText === sections.exterior.name),
                    inspectionDetail.sections.find(s => s.name === sections.exterior.name)
                );
            }

            if (shouldValidate(onlyValidate, "interior")) {
                validation.interior = validateFullSection(
                    inspectionSections.find(s => s.questionGroupText === sections.interior.name),
                    inspectionDetail.sections.find(s => s.name === sections.interior.name)
                );
            }

            if (shouldValidate(onlyValidate, "audio")) {
                validation.audio = validateAudio(inspectionDetail);
            }

            if (shouldValidate(onlyValidate, "inspection")) {
                validation.inspection = validateFullSection(
                    inspectionSections.find(s => s.questionGroupText === sections.inspection.name),
                    inspectionDetail.sections.find(s => s.name === sections.inspection.name)
                );
            }

            if (shouldValidate(onlyValidate, "nsf")) {
                validation.nsf =
                    inspectionDetail.inspection.vehicleTypeId === VehicleType.Motorbike
                        ? true
                        : validateFullSection(
                              inspectionSections.find(s => s.questionGroupText === sections.assuredTyre.nsf.name),
                              inspectionDetail.sections
                                  .find(s => s.name === sections.assuredTyre.name)
                                  .sections.find(s => s.name === sections.assuredTyre.nsf.name),
                              true
                          );
            }

            if (shouldValidate(onlyValidate, "nsr")) {
                validation.nsr =
                    inspectionDetail.inspection.vehicleTypeId === VehicleType.Motorbike
                        ? true
                        : validateFullSection(
                              inspectionSections.find(s => s.questionGroupText === sections.assuredTyre.nsr.name),
                              inspectionDetail.sections

                                  .find(s => s.name === sections.assuredTyre.name)
                                  .sections.find(s => s.name === sections.assuredTyre.nsr.name),
                              true
                          );
            }

            if (shouldValidate(onlyValidate, "osf")) {
                validation.osf =
                    inspectionDetail.inspection.vehicleTypeId === VehicleType.Motorbike
                        ? true
                        : validateFullSection(
                              inspectionSections.find(s => s.questionGroupText === sections.assuredTyre.osf.name),
                              inspectionDetail.sections
                                  .find(s => s.name === sections.assuredTyre.name)
                                  .sections.find(s => s.name === sections.assuredTyre.osf.name),
                              true
                          );
            }

            if (shouldValidate(onlyValidate, "osr")) {
                validation.osr =
                    inspectionDetail.inspection.vehicleTypeId === VehicleType.Motorbike
                        ? true
                        : validateFullSection(
                              inspectionSections.find(s => s.questionGroupText === sections.assuredTyre.osr.name),
                              inspectionDetail.sections

                                  .find(s => s.name === sections.assuredTyre.name)
                                  .sections.find(s => s.name === sections.assuredTyre.osr.name),
                              true
                          );
            }

            if (shouldValidate(onlyValidate, "spare")) {
                validation.spare =
                    inspectionDetail.inspection.vehicleTypeId === VehicleType.Motorbike
                        ? true
                        : validateSpareSection(
                              inspectionSections.find(s => s.questionGroupText === sections.assuredTyre.spare.name),
                              inspectionDetail.sections
                                  .find(s => s.name === sections.assuredTyre.name)
                                  .sections.find(s => s.name === sections.assuredTyre.spare.name)
                          );
            }

            if (shouldValidate(onlyValidate, "front")) {
                validation.front =
                    inspectionDetail.inspection.vehicleTypeId !== VehicleType.Motorbike
                        ? true
                        : validateFullSection(
                              inspectionSections.find(s => s.questionGroupText === sections.assuredTyre.front.name),
                              inspectionDetail.sections
                                  .find(s => s.name === sections.assuredTyre.name)
                                  .sections.find(s => s.name === sections.assuredTyre.front.name)
                          );
            }

            if (shouldValidate(onlyValidate, "back")) {
                validation.back =
                    inspectionDetail.inspection.vehicleTypeId !== VehicleType.Motorbike
                        ? true
                        : validateFullSection(
                              inspectionSections.find(s => s.questionGroupText === sections.assuredTyre.back.name),
                              inspectionDetail.sections
                                  .find(s => s.name === sections.assuredTyre.name)
                                  .sections.find(s => s.name === sections.assuredTyre.back.name)
                          );
            }

            break;
    }

    return Object.keys(validation).every(k => validation[k]);
};

const validateTyres = async (inspectionId: string): Promise<boolean> => {
    const isValid = await validateInspection(inspectionId, [
        "back",
        "front",
        "nsf",
        "nsr",
        "osf",
        "osr",
        "signalTyre",
        "spare",
    ]);

    return isValid;
};

const inspectionValidationService = {
    validateInspection,
    validateTyres,
};

export default inspectionValidationService;
