import { IconButton } from "@g3r-developers/g3-common";
import { useMemo } from "react";
import { Card, CardImg, CardImgOverlay, CardSubtitle, Col, Row } from "reactstrap";

interface MarketingImageProps {
    imageTaken: boolean;
    name: string;
    onClick?: () => void;
    onRemove?: () => void;
    src: string;
}

export function MarketingImage({ imageTaken, name, onClick, onRemove, src }: MarketingImageProps) {
    const background = useMemo(() => {
        return "pt-1 ps-2 text-white text-bold text-center " + (imageTaken ? "bg-success" : "bg-danger");
    }, [imageTaken]);

    return (
        <Card onClick={onClick}>
            <CardImg alt={name} src={src} width="100%" top />

            {!!onRemove && (
                <CardImgOverlay>
                    <Row className={"justify-content-end"}>
                        <Col xs={"auto"}>
                            <IconButton
                                faIconString={"fas fa-times"}
                                onClick={onRemove}
                                size={"sm"}
                                tooltip={"Delete"}
                                variant={"danger"}
                            />
                        </Col>
                    </Row>
                </CardImgOverlay>
            )}

            <CardSubtitle className={background}>
                {name}
                <span className="float-end pe-2">
                    {imageTaken && <i className="float-right fa fa-check-circle"></i>}
                </span>
            </CardSubtitle>
        </Card>
    );
}
