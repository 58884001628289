import { SetRecorder } from "Types/Recorder";

export async function startRecording(setRecorderState: SetRecorder) {
    try {
        const stream: MediaStream = await navigator.mediaDevices.getUserMedia({ audio: true });

        setRecorderState(prevState => {
            return {
                ...prevState,
                initRecording: true,
                mediaStream: stream,
            };
        });
    } catch (err) {
        // ignored
    }
}

export function saveRecording(recorder: any) {
    if (recorder.state !== "inactive") recorder.stop();
}
