import { Col, Row } from "reactstrap";

export default function InspectionCompletePage() {
    return (
        <>
            <Row className={"mt-3"}>
                <Col className={"text-center"}>
                    <img src="https://media.g3r.co.uk/signal/signal-logo.png" alt="Signal" className={"logo"} />
                </Col>
            </Row>
            <Row className={"mb-4 pt-4"}>
                <Col className={"text-center"}>
                    <h1>Inspection Complete</h1>
                </Col>
            </Row>
            <Row>
                <Col className={"m-3 text-center"}>
                    Your inspection is complete and has been received
                    <br /> Thank you
                </Col>
            </Row>
        </>
    );
}
