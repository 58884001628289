import { IconButton, showerrormodal } from "@g3r-developers/g3-common";
import Camera from "Components/Camera/Camera";
import utils from "Services/Utils";
import { InspectionProfileMatrixModel } from "Types/Inspection/InspectionProfileMatrixModel";
import {
    ComponentModel,
    ComponentRepairMethodModel,
    NamaSplatOverlayItemModel,
} from "Types/Inspection/NamaSplatOverlayItemModel";
import { ChoiceItemModel } from "Types/Question/ChoiceItemModel";
import { QuestionModel } from "Types/Question/QuestionModel";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Button, Col, Label, ListGroup, Modal, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";
import { InspectionDamageItem } from "../../../Types/Inspection/InspectionDamageItem";
import { DamageListItem } from "./ComponentListItem";
import InteractiveDamageImage from "./InteractiveDamageImage";

export interface AddDamageModalProps {
    components: NamaSplatOverlayItemModel[];
    damageItem: InspectionDamageItem;
    matrices: InspectionProfileMatrixModel[];
    saveDamage(dmg: InspectionDamageItem): void;
    toggleModal(): void;
}

export interface AddDamageErrors {
    selectedComponent?: string;
    selectedDamage?: string;
    selectedSeverity?: string;
    selectedRepairMethod?: string;
}

export function AddDamageModal({ components, damageItem, matrices, saveDamage, toggleModal }: AddDamageModalProps) {
    const [existingPhotos] = useState<string[]>(damageItem?.images ?? []);
    const [photo, setPhoto] = useState<string>(undefined); // This is now deprecated, kept around for "just in case" reasons. Try to use photos instead.
    const [photos, setPhotos] = useState<string[]>(damageItem?.images ?? []);
    const [selectedComponent, setSelectedComponent] = useState<ComponentModel>(undefined);
    const [selectedDamage, setSelectedDamage] = useState<QuestionModel>(undefined);
    const [selectedRepairMethod, setSelectedRepairMethod] = useState<ComponentRepairMethodModel>(undefined);
    const [selectedSeverity, setSelectedSeverity] = useState<ChoiceItemModel>(undefined);

    const checkIsNew = useCallback((photo: string) => !existingPhotos.includes(photo), [existingPhotos]);

    const photoNeeded = useMemo(() => {
        if (!selectedComponent || !selectedRepairMethod) {
            return false;
        }

        const ipm = matrices.find(
            m => m.namaComponentId === selectedComponent.id && m.repairMethodId === selectedRepairMethod.id
        );

        if (!ipm) {
            return false;
        }

        return ipm.requiresImage;
    }, [selectedComponent, selectedRepairMethod, matrices]);

    const canSave = useMemo(() => {
        if (!selectedComponent || !selectedDamage || !selectedSeverity || !selectedRepairMethod) {
            return false;
        }

        if (photoNeeded && !photos.length) {
            return false;
        }

        return true;
    }, [photos, photoNeeded, selectedComponent, selectedDamage, selectedRepairMethod, selectedSeverity]);

    const [showCamera, setShowCamera] = useState<boolean>(false);

    const possibleComponents = useMemo(
        () =>
            components
                .filter(c =>
                    utils.intersectRect(
                        {
                            bottom: damageItem.yCoordinate + 10,
                            left: damageItem.xCoordinate,
                            right: damageItem.xCoordinate + 10,
                            top: damageItem.yCoordinate,
                        },
                        c.box
                    )
                )
                .sort((a, b) => a.width - b.width),
        [components, damageItem]
    );

    const toggleCamera = useCallback(() => setShowCamera(!showCamera), [showCamera]);

    const handleSelectedChanged = useCallback(newValue => {
        setSelectedComponent(newValue);

        setSelectedDamage(undefined);
        setSelectedSeverity(undefined);
        setSelectedRepairMethod(undefined);
    }, []);

    const handleSeverityChanged = useCallback(newValue => {
        setSelectedSeverity(newValue);
    }, []);

    const handleDamageChanged = useCallback(newValue => {
        setSelectedDamage(newValue);

        setSelectedSeverity(undefined);
    }, []);

    const handleRepairMethodChanged = useCallback(newValue => {
        setSelectedRepairMethod(newValue);
    }, []);

    const photoCaptured = useCallback((img: string) => {
        setPhotos(p => [...p, img]);
        setPhoto(img);
        setShowCamera(false);
    }, []);

    const photoColor = useMemo(() => (photos.length > 0 ? "success" : "danger"), [photos]);

    const handleAddDamage = useCallback(() => {
        if (!canSave) {
            showerrormodal("Please select a component, damage, repair method and severity");
            return;
        }

        const damage = {
            ...damageItem,
            componentId: selectedComponent.id,
            damageId: selectedDamage.id,
            image: photo,
            repairMethodId: selectedRepairMethod.id,
            severityId: selectedSeverity.id,
            images: photos,
        } as InspectionDamageItem;

        if (photo !== damageItem.image) {
            damage.assetId = undefined;
        }

        saveDamage(damage);
    }, [
        damageItem,
        canSave,
        photo,
        photos,
        saveDamage,
        selectedComponent,
        selectedDamage,
        selectedRepairMethod,
        selectedSeverity,
    ]);

    const handleRemoveImage = useCallback((imgSrc: string) => {
        setPhotos(p => {
            const updated = [...p];

            const toRemoveIndex = updated.findIndex(img => img === imgSrc);

            if (toRemoveIndex > -1) {
                updated.splice(toRemoveIndex, 1);
            }

            return updated;
        });
    }, []);

    useEffect(() => {
        const component = possibleComponents.flatMap(c => c.component).find(c => c.id === damageItem.componentId);
        setSelectedComponent(component);

        const damage = component?.severities?.find(s => s.id === damageItem.damageId);
        setSelectedDamage(damage);

        const severity = damage?.choiceGroup?.choiceItems?.find(ci => ci.id === damageItem.severityId);
        setSelectedSeverity(severity);

        const repairMethod = component?.repairMethods?.find(rm => rm.id === damageItem.repairMethodId);
        setSelectedRepairMethod(repairMethod);
    }, [components, damageItem, possibleComponents]);

    return (
        <>
            <Modal isOpen fullscreen="xl" toggle={toggleModal}>
                <ModalHeader toggle={toggleModal}>Add Damage</ModalHeader>
                <ModalBody>
                    <Row>
                        <Col md="3">
                            <Label>Component</Label>
                            <ListGroup>
                                {possibleComponents.map(c => (
                                    <React.Fragment key={c.id}>
                                        <DamageListItem
                                            item={c.component}
                                            selected={selectedComponent}
                                            changed={handleSelectedChanged}
                                        />
                                    </React.Fragment>
                                ))}
                            </ListGroup>
                        </Col>

                        <Col md="3">
                            <Label>Damage</Label>
                            {selectedComponent && (
                                <ListGroup>
                                    {selectedComponent.severities.map(s => (
                                        <React.Fragment key={s.id}>
                                            <DamageListItem
                                                item={s}
                                                selected={selectedDamage}
                                                changed={handleDamageChanged}
                                            />
                                        </React.Fragment>
                                    ))}
                                </ListGroup>
                            )}
                        </Col>
                        <Col md="3">
                            <Label>Severity</Label>
                            {selectedDamage && (
                                <ListGroup>
                                    {selectedDamage.choiceGroup.choiceItems.map(ci => (
                                        <React.Fragment key={ci.id}>
                                            <DamageListItem
                                                item={ci}
                                                selected={selectedSeverity}
                                                changed={handleSeverityChanged}
                                            />
                                        </React.Fragment>
                                    ))}
                                </ListGroup>
                            )}
                        </Col>
                        <Col md="3">
                            <Label>Repair Method</Label>
                            {selectedSeverity && (
                                <ListGroup>
                                    {selectedComponent.repairMethods.map(rm => (
                                        <React.Fragment key={rm.id}>
                                            <DamageListItem
                                                item={rm}
                                                selected={selectedRepairMethod}
                                                changed={handleRepairMethodChanged}
                                            />
                                        </React.Fragment>
                                    ))}
                                </ListGroup>
                            )}
                        </Col>
                    </Row>
                </ModalBody>
                {!!photos.length && (
                    <ModalFooter>
                        <Row className={"justify-content-start justify-content-xl-center w-100"}>
                            {photos.map(p => (
                                <Col className={"mb-2"} key={p} xl={6} xs={3}>
                                    <InteractiveDamageImage
                                        isNew={checkIsNew(p)}
                                        onDelete={() => handleRemoveImage(p)}
                                        src={p}
                                    />
                                </Col>
                            ))}
                        </Row>
                    </ModalFooter>
                )}
                <ModalFooter>
                    <Button onClick={toggleCamera} color={photoColor}>
                        Take Photo
                    </Button>
                    {canSave && (
                        <IconButton onClick={handleAddDamage} text="Add Damage" variant="signal-lite-blue"></IconButton>
                    )}
                </ModalFooter>
            </Modal>

            {showCamera && (
                <Modal className="p-0 m-0" isOpen fullscreen={true}>
                    <ModalBody className="p-0">
                        <Camera onPhoto={photoCaptured} toggleCamera={toggleCamera} />
                    </ModalBody>
                </Modal>
            )}
        </>
    );
}
