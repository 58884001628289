import sections from "Constants/Sections";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Button } from "reactstrap";
import inspectionDetailStore from "Services/DbStores/InspectionDetailStore";
import { InspectionType } from "Types/Enums/InspectionType";
import { TyreSectionName } from "Types/Inspection/Enums/TyreSectionName";
import { OfflineInspectionDetailModel } from "Types/Inspection/OfflineInspectionDetailModel";
import { QuestionAnswerModel } from "Types/Question/QuestionAnswerModel";
import TyreModal from "./TyreModal";

interface TyreButtonProps {
    className?: string;
    handleChange(sectionId: string, questionId: string, newValue: string, textValue: string): void;
    inspectionId: string;
    questions?: QuestionAnswerModel[];
    tyreSection: TyreSectionName;
}

export default function TyreButton({
    className,
    handleChange,
    inspectionId,
    questions,
    tyreSection,
}: TyreButtonProps): JSX.Element {
    const [inspectionDetails, setInspectionDetails] = useState<OfflineInspectionDetailModel>(undefined);
    const [showTyreModal, setShowTyreModal] = useState<boolean>(false);

    const toggleTyreModal = useCallback(() => setShowTyreModal(p => !p), []);

    const signalRequiredTyreQuestionsAnswered = useCallback(() => {
        const questionsToInclude: string[] = [];
        questionsToInclude.push(sections.signalTyre.questions.tyreDepth.id);

        return questions?.filter(q => questionsToInclude.includes(q.id)).every(q => q?.answer !== undefined);
    }, [questions]);

    const color = useMemo(() => {
        const allQuestionsAnswered =
            inspectionDetails?.inspection?.inspectionTypeId === InspectionType.Signal &&
            tyreSection !== TyreSectionName.Spare
                ? signalRequiredTyreQuestionsAnswered()
                : questions?.every(q => q?.answer !== undefined);
        return allQuestionsAnswered ? "success" : "danger";
    }, [inspectionDetails?.inspection?.inspectionTypeId, questions, signalRequiredTyreQuestionsAnswered, tyreSection]);

    const getLabel = useCallback((name: string) => {
        switch (name) {
            case TyreSectionName.NearsideFront: {
                return "Nearside Front";
            }
            case TyreSectionName.NearsideRear: {
                return "Nearside Rear";
            }
            case TyreSectionName.OffsideFront: {
                return "Offside Front";
            }
            case TyreSectionName.OffsideRear: {
                return "Offside Rear";
            }
            case TyreSectionName.Spare: {
                return "Spare";
            }
            case TyreSectionName.Front: {
                return "Front";
            }
            case TyreSectionName.Back: {
                return "Back";
            }
            default: {
                return name;
            }
        }
    }, []);

    const label = useMemo(() => getLabel(tyreSection), [getLabel, tyreSection]);

    useEffect(() => {
        inspectionDetailStore.load(inspectionId).then(setInspectionDetails);
    }, [inspectionId]);

    return (
        <>
            <Button className={className} color={color} onClick={toggleTyreModal}>
                {label}
            </Button>

            {showTyreModal && (
                <TyreModal
                    close={toggleTyreModal}
                    handleChange={handleChange}
                    header={label}
                    inspectionDetails={inspectionDetails}
                    inspectionId={inspectionId}
                    questions={questions}
                    tyreSection={tyreSection}
                />
            )}
        </>
    );
}
