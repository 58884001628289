import IndexedDb from "./IndexedDb";

export const DB_NAMES = {
    INSPECTION_ASSET: "inspection-asset",
    INSPECTION_CONFIG: "inspection-config",
    INSPECTION_DAMAGE: "inspection-damage",
    INSPECTION_DETAIL: "inspection-detail",
    INSPECTION_SECTION: "inspection-section",
    INSPECTION_SPLAT_COMPONENT: "inspection-splat-component",
};

// When adding a new table to this db, be sure to increment the version number in IndexedDb.ts
export const initDb = async (): Promise<IndexedDb> => {
    const db = new IndexedDb("avid");
    await db.createObjectStore(
        [
            DB_NAMES.INSPECTION_ASSET,
            DB_NAMES.INSPECTION_CONFIG,
            DB_NAMES.INSPECTION_DAMAGE,
            DB_NAMES.INSPECTION_DETAIL,
            DB_NAMES.INSPECTION_SECTION,
            DB_NAMES.INSPECTION_SPLAT_COMPONENT,
        ],
        ["inspectionAssetId", "inspectionId", "inspectionDamageId", "inspectionId", "inspectionSectionId", "splatId"]
    );

    return db;
};

const questionStore = {
    initDb,
};

export default questionStore;
