import { Loading, showerrormodal } from "@g3r-developers/g3-common";
import { VehicleSearchConfirmation } from "Components/Vehicle/VehicleSearchConfirmation";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import inspectionService from "Services/InspectionService";
import { InspectionModel } from "Types/Inspection/InspectionModel";

export default function VehicleSearchPage(): JSX.Element {
    const { vrm } = useParams<{
        vrm: string;
    }>();

    const navigate = useNavigate();
    const [inspection, setInspection] = useState<InspectionModel>(undefined);
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        setLoading(true);
        inspectionService
            .getByVrm(vrm)
            .then(setInspection)
            .catch(() => {
                showerrormodal(`Could not find vehicle ${vrm}`);
                navigate("/");
            })
            .finally(() => setLoading(false));
    }, [vrm, navigate]);

    return (
        <>
            {loading && <Loading />}
            {inspection && <VehicleSearchConfirmation inspection={inspection} />}
        </>
    );
}
