import { BoundingBox } from "Types/Inspection/NamaSplatOverlayItemModel";

export const DateFormat = "dd/MM/yyyy";
export const DateTimeFormat = "dd/MM/yyyy HH:mm";

const convertBlobToBase64 = (blob: Blob) =>
    new Promise<string>((resolve, reject) => {
        const reader = new FileReader();
        reader.onerror = reject;
        reader.onload = () => {
            const dataUrl = reader.result;
            resolve(dataUrl.toString());
        };
        reader.readAsDataURL(blob);
    });

const convertBase64ToBlob = async (base64String: string, contentType: string) => {
    const b64String = base64String.split("base64,")[1];
    const byteCharacters = atob(b64String);
    const byteArrays = [];

    for (let i = 0; i < byteCharacters.length; i++) {
        byteArrays.push(byteCharacters.charCodeAt(i));
    }

    const byteArray = new Uint8Array(byteArrays);
    return new Blob([byteArray], { type: contentType });
};

const getOfflineImageUrl = async (url: string): Promise<string> => {
    const response = await fetch(url);
    const blob = await response.blob();
    const image = await convertBlobToBase64(blob);
    return image;
};

const intersectRect = (r1: BoundingBox, r2: BoundingBox): boolean => {
    return !(r2.left > r1.right || r2.right < r1.left || r2.top > r1.bottom || r2.bottom < r1.top);
};

export const isEmpty = (obj: unknown): boolean => {
    return !obj || !Object.keys(obj).length;
};

const utils = {
    convertBlobToBase64,
    convertBase64ToBlob,
    getOfflineImageUrl,
    intersectRect,
    isEmpty,
};

export default utils;
