import { G3Dropdown } from "@g3r-developers/g3-common";
import { DropdownModel } from "Pages/Inspection";
import { QuestionAnswerModel } from "Types/Question/QuestionAnswerModel";
import { useCallback, useMemo } from "react";
import { OptionProps, components } from "react-select";

interface QuestionDropdownProps {
    error?: string;
    onChange(val: DropdownModel): void;
    question: QuestionAnswerModel;
}

export function QuestionDropdown({ error, onChange, question }: QuestionDropdownProps) {
    const dropdownItems: DropdownModel[] = useMemo(
        () =>
            question?.choiceGroup?.choiceItems.map(ci => ({
                label: ci.displayName,
                value: ci.id,
            })) ?? [],
        [question]
    );

    const getDropdownItems = useCallback(
        (query: string) => {
            return Promise.resolve(dropdownItems);
        },
        [dropdownItems]
    );

    const isHEX = ch => "0123456789abcdef".includes(ch.toLowerCase());

    const isGuidValid = useCallback(guid => {
        guid = guid.replaceAll("-", ""); // Format it first!
        return guid.length === 32 && [...guid].every(isHEX);
    }, []);

    const option = useMemo(() => {
        if (!question.answer) {
            return undefined;
        }
        if (isGuidValid(question.answer)) {
            return question.answer.toUpperCase();
        }

        return question.answer;
    }, [isGuidValid, question.answer]);

    return (
        <>
            <G3Dropdown
                classNamePrefix="question-dropdown"
                defaultOption={option}
                error={error}
                loadOptions={getDropdownItems}
                onChange={onChange}
                isSearchable={false}
                full
                components={{ Option }}
            />
        </>
    );
}

const menuHeaderStyle = {
    fontSize: "xx-large",
    fontWeight: "bold",
};

const Option = (props: OptionProps) => {
    return (
        <div style={menuHeaderStyle}>
            <components.Option {...props}>{props.children}</components.Option>
        </div>
    );
};
