export enum SectionNames {
    AssuredTyre = "Assured Tyre",
    VehicleEquipment = "Vehicle Equipment",
    VehicleDetails = "Vehicle Details",
    MechianicalCondition = "Mechanical Condition",
    Damage = "Damage",
    OffsiteMarketing = "Offsite Marketing",
    SignalTyre = "Signal Tyre",
    Tyre = "Tyre",
    Audio = "Audio",
    Interior = "Interior",
    WarningLights = "Warning Lights",
    EV = "EV",
}
