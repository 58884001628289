/* eslint-disable no-console */
import { G3DropdownButton } from "@g3r-developers/g3-common";
import { AddDamageModal } from "Components/Inspection/Damage/AddDamageModal";
import { DamageCard } from "Components/Inspection/Damage/DamageCard";
import inspectionDamageStore from "Services/DbStores/InspectionDamageStore";
import inspectionSplatComponentStore from "Services/DbStores/InspectionSplatComponentStore";
import { InspectionDamageItem } from "Types/Inspection/InspectionDamageItem";
import { InspectionProfileMatrixModel } from "Types/Inspection/InspectionProfileMatrixModel";
import { NamaSplatOverlayItemModel } from "Types/Inspection/NamaSplatOverlayItemModel";
import { OfflineSplatModel } from "Types/Inspection/OfflineSplatModel";
import { SectionModel } from "Types/Inspection/SectionModel";
import { nanoid } from "nanoid";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Col, Row } from "reactstrap";
import TyreSection from "../Tyre/TyreSection";
import SplatImage from "./SplatImage";

enum LocationType {
    Interior = "94518431-5B1B-4397-90AE-DFDDFFD51D6D",
    Exterior = "819291D5-5EE7-4EE2-BA7A-60FB52AF4E75",
}

interface QuickAction {
    key: string;
    label: string;
    splatId: string;
    componentId: string;
    repairMethodId: string;
    damageId: string;
    severityId: string;
    locationId: string;
    xCoordinate?: number;
    yCoordinate?: number;
}

interface DamageSplatTabProps {
    inspectionId: string;
    splat: OfflineSplatModel;
    tyreSection: SectionModel;
    idx: number;
    handleDamageSectionComplete(state: boolean): void;
    handleTyreSectionComplete(state: boolean): void;
    matrices: InspectionProfileMatrixModel[];
}

export function DamageSplatTab({
    inspectionId,
    splat,
    tyreSection,
    idx,
    handleDamageSectionComplete,
    handleTyreSectionComplete,
    matrices,
}: DamageSplatTabProps) {
    const [anyDamages, setAnyDamages] = useState<boolean>(false);
    const [components, setComponents] = useState<NamaSplatOverlayItemModel[]>(undefined);
    const [damageItem, setDamageItem] = useState<InspectionDamageItem>(undefined);
    const [damages, setDamages] = useState<InspectionDamageItem[]>([]);

    const closeModal = useCallback(() => setDamageItem(undefined), []);

    const quickActionConfig = useMemo(
        () =>
            [
                // interior
                {
                    key: nanoid(),
                    label: "Requires Valet",
                    componentId: "2c08ed03-7437-4093-8729-c4cd8054756e",
                    repairMethodId: "405c8dba-a60a-4326-9e36-19b109d1034f",
                    damageId: "c652f695-e29f-451d-84e8-30c6b0034e9c",
                    severityId: "a697f82a-93de-415e-bd86-3ef07bb12567",
                    locationId: LocationType.Interior,
                    xCoordinate: 340,
                    yCoordinate: 301,
                } as QuickAction,
                // exterior
                {
                    key: nanoid(),
                    label: "Vehicle Wrapped",
                    componentId: "198a4727-1615-4cbf-8e91-2c9e97644829",
                    repairMethodId: "405c8dba-a60a-4326-9e36-19b109d1034f",
                    damageId: "fe5f8be2-2bc5-4674-bc8c-677af622e820",
                    severityId: "12426d55-28be-433d-b287-c9342f241a5f",
                    locationId: LocationType.Exterior,
                    xCoordinate: 0,
                    yCoordinate: 0,
                } as QuickAction,
                {
                    key: nanoid(),
                    label: "Missing Key",
                    componentId: "4f114cfc-bbdc-4c2a-88b0-18f8502d2dde",
                    repairMethodId: "c0bc5be8-3e25-4fe6-ab17-60731812079a",
                    damageId: "eede4bb6-fde5-41c3-a9b7-bdaabcabc42a",
                    severityId: "77c13283-faa7-4110-8bce-be0e6fd3eb66",
                    locationId: LocationType.Exterior,
                    xCoordinate: 0,
                    yCoordinate: 0,
                } as QuickAction,
                {
                    key: nanoid(),
                    label: "Mechanical Polish",
                    componentId: "473159aa-f356-4f11-8e9e-4bff9182db9a",
                    repairMethodId: "58e4bf2e-543d-4aa1-8e55-411b24d5061a",
                    damageId: "f064000d-e288-4d3a-b2b9-4119438b8588",
                    severityId: "eb432d11-d520-4f19-9282-fe1bfbad5428",
                    locationId: LocationType.Exterior,
                    xCoordinate: 0,
                    yCoordinate: 0,
                } as QuickAction,
                {
                    key: nanoid(),
                    label: "Previous Paint Protection",
                    componentId: "576e09ba-6577-4294-8066-91158a3d2c03",
                    repairMethodId: "405c8dba-a60a-4326-9e36-19b109d1034f",
                    damageId: "fe5f8be2-2bc5-4674-bc8c-677af622e820",
                    severityId: "90d3e86f-bda2-410a-b1a4-0e4b0389b75c",
                    locationId: LocationType.Exterior,
                    xCoordinate: 0,
                    yCoordinate: 0,
                } as QuickAction,
            ].filter(a => a.locationId === splat.locationContext.id),
        [splat.locationContext.id]
    );

    const loadDamages = useCallback(async () => {
        const splats = await inspectionDamageStore.getDamages(inspectionId);
        setAnyDamages(!!splats.flatMap(d => d.damages).length);

        const ourDamages = splats.find(d => d.splatId.toUpperCase() === splat.id.toUpperCase())?.damages ?? [];
        setDamages(ourDamages);
    }, [inspectionId, splat.id]);

    const saveDamage = useCallback(
        async (dmg: InspectionDamageItem) => {
            await inspectionDamageStore.addDamage(inspectionId, dmg);
            loadDamages();

            closeModal();
        },
        [closeModal, inspectionId, loadDamages]
    );

    const handleAddDamage = useCallback(
        (xCoordinate: number, yCoordinate: number) =>
            setDamageItem({
                splatId: splat.id,
                xCoordinate: xCoordinate,
                yCoordinate: yCoordinate,
            } as InspectionDamageItem),
        [splat]
    );

    const handleDeleteDamage = useCallback(
        async (dmg: InspectionDamageItem) => {
            await inspectionDamageStore.deleteDamage(inspectionId, dmg.inspectionDamageItemId);
            loadDamages();
        },
        [inspectionId, loadDamages]
    );

    const handleEditDamage = useCallback((damage: InspectionDamageItem) => setDamageItem(damage), []);

    const handleQuickAction = useCallback(
        (selectedItem: QuickAction) => {
            const quickDamage = {
                componentId: selectedItem.componentId.toUpperCase(),
                repairMethodId: selectedItem.repairMethodId.toUpperCase(),
                damageId: selectedItem.damageId.toUpperCase(),
                severityId: selectedItem.severityId.toUpperCase(),
                splatId: splat.id.toUpperCase(),
                xCoordinate: selectedItem.xCoordinate,
                yCoordinate: selectedItem.yCoordinate,
            } as InspectionDamageItem;

            saveDamage(quickDamage);
        },
        [saveDamage, splat.id]
    );

    useEffect(() => {
        if (!damages) {
            return;
        }

        const isSectionComplete = anyDamages || damages.length > 0;
        handleDamageSectionComplete(isSectionComplete);
    }, [anyDamages, damages, handleDamageSectionComplete]);

    const loadComponents = useCallback(async () => {
        const storedComponents = await inspectionSplatComponentStore.load(splat.id);

        const comps = storedComponents.map(component => {
            component.box = {
                bottom: component.yCoordinate + component.height,
                left: component.xCoordinate,
                right: component.xCoordinate + component.width,
                top: component.yCoordinate,
            };

            return component;
        });

        setComponents(comps);
    }, [splat]);

    useEffect(() => {
        loadDamages();
        loadComponents();
    }, [loadComponents, loadDamages]);

    return (
        <>
            {damages && (
                <div className="text-center">
                    {splat.locationContext.id === LocationType.Exterior ? (
                        <TyreSection
                            section={tyreSection}
                            inspectionId={inspectionId}
                            sectionIndex={idx}
                            handleSectionComplete={(idx, state) => handleTyreSectionComplete(state)}
                        >
                            <SplatImage damages={damages} onClick={handleAddDamage} splatImg={splat.offlineUrl} />
                        </TyreSection>
                    ) : (
                        <SplatImage damages={damages} onClick={handleAddDamage} splatImg={splat.offlineUrl} />
                    )}
                </div>
            )}

            {!!damageItem && (
                <AddDamageModal
                    components={components}
                    damageItem={damageItem}
                    toggleModal={closeModal}
                    saveDamage={saveDamage}
                    matrices={matrices}
                />
            )}

            {!!components?.length && (
                <Row className="mt-3">
                    {damages.map(d => (
                        <Col md="4" key={`img__${d.inspectionDamageItemId}`}>
                            <DamageCard
                                componentModel={components.find(c => c.component.id === d.componentId)?.component}
                                damageItem={d}
                                onDelete={() => handleDeleteDamage(d)}
                                onSelect={() => handleEditDamage(d)}
                            ></DamageCard>
                        </Col>
                    ))}
                </Row>
            )}

            <div className={"float-start my-3"}>
                <G3DropdownButton
                    text="Quick Action"
                    items={quickActionConfig}
                    onClick={handleQuickAction}
                    disabled={quickActionConfig.length === 0}
                />
            </div>
        </>
    );
}
