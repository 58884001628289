import { OfflineMarketingImageModel } from "Types/Inspection/OfflineSplatModel";
import { QuestionAnswerModel } from "Types/Question/QuestionAnswerModel";
import { MarketingImage } from "./MarketingImage";

export interface OffsiteMarketingImageProps {
    onClick(): void;
    question: QuestionAnswerModel;
    placeholder: OfflineMarketingImageModel;
}

export default function OffsiteMarketingImage({ onClick, question, placeholder }: OffsiteMarketingImageProps) {
    return (
        <MarketingImage
            imageTaken={!!question.answer}
            name={question.questionText}
            onClick={onClick}
            src={question.answer ?? placeholder.offlineUrl}
        />
    );
}
