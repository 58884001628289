import { IconButton } from "@g3r-developers/g3-common";
import { useCallback, useMemo } from "react";
import { RecorderControlsProps } from "Types/Recorder";

export default function RecorderControls({
    onBeginRecording,
    onEndRecording,
    recorderState,
    handlers,
    variant,
}: RecorderControlsProps) {
    const { recordingMinutes, recordingSeconds } = recorderState;
    const { startRecording, saveRecording } = handlers;

    const recordingNotStarted = useMemo(
        () => recordingMinutes === 0 && recordingSeconds === 0,
        [recordingMinutes, recordingSeconds]
    );

    const buttonText = useMemo(() => {
        if (recordingNotStarted) {
            return "Start Recording";
        }
        return "Stop Recording";
    }, [recordingNotStarted]);

    const handleClick = useCallback(() => {
        if (recordingNotStarted) {
            startRecording();
            if (onBeginRecording) {
                onBeginRecording();
            }
            return;
        } else {
            saveRecording();
            if (onEndRecording) {
                onEndRecording();
            }
        }
    }, [onBeginRecording, onEndRecording, recordingNotStarted, saveRecording, startRecording]);

    return (
        <>
            <IconButton
                faIconString="fa fa-microphone"
                variant={variant}
                className="w-100 p-3"
                text={buttonText}
                onClick={handleClick}
            ></IconButton>
            {!recordingNotStarted && (
                <div className="text-center">
                    <h4>
                        {recordingMinutes} : {recordingSeconds}
                    </h4>
                </div>
            )}
        </>
    );
}
