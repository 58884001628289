import { IconButton } from "@g3r-developers/g3-common";
import { useMemo } from "react";
import { Card, CardBody, Col, Row } from "reactstrap";
import { InspectionDamageItem } from "Types/Inspection/InspectionDamageItem";
import { ComponentModel } from "Types/Inspection/NamaSplatOverlayItemModel";

interface DamageCardProps {
    componentModel: ComponentModel;
    damageItem: InspectionDamageItem;
    onDelete: () => void;
    onSelect: () => void;
}

export function DamageCard({ componentModel, damageItem, onDelete, onSelect }: DamageCardProps) {
    const damage = useMemo(
        () => componentModel?.severities?.find(s => s.id === damageItem.damageId),
        [componentModel?.severities, damageItem.damageId]
    );

    return (
        <Card>
            <CardBody>
                <Row className={"align-items-center"}>
                    <Col>
                        <h6 className={"mb-0"}>
                            {componentModel?.componentName} - {damage?.questionText}{" "}
                            {!!damageItem?.images?.length && <i className="ms-2 fa fa-camera"></i>}
                        </h6>
                    </Col>

                    <Col className={"px-1"} xs={"auto"}>
                        <IconButton faIconString="fas fa-edit" onClick={onSelect} tooltip="Edit" />
                    </Col>

                    <Col className={"ps-1"} xs={"auto"}>
                        <IconButton variant="danger" faIconString="fas fa-trash" onClick={onDelete} tooltip="Delete" />
                    </Col>
                </Row>
            </CardBody>
        </Card>
    );
}
