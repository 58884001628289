import { IconButton } from "@g3r-developers/g3-common";
import useWindowSize from "Hooks/useWindowSize";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import Webcam from "react-webcam";
import { Button, Col, Row } from "reactstrap";
import styles from "./Camera.module.scss";

export interface CameraProps {
    toggleCamera(): void;
    onPhoto(img: string): void;
    overlayUrl?: string;
    label?: string;
    description?: string;
    imageNumber?: number;
    imageTotal?: number;
}

export default function Camera({
    onPhoto,
    toggleCamera,
    overlayUrl,
    label,
    description,
    imageNumber,
    imageTotal,
}: CameraProps) {
    const [previewImg, setPreviewImg] = useState<string>(undefined);
    const [deviceId, setDeviceId] = useState<string>(undefined);

    const size = useWindowSize();

    const aspectRatio = useMemo(() => {
        const isLandscape = size.height <= size.width;
        return isLandscape ? size.width / size.height : size.height / size.width;
    }, [size]);

    const constraints = useMemo(() => {
        return {
            aspectRatio: aspectRatio,
            deviceId: deviceId,
            facingMode: "environment",
            height: 1080,
            width: 1920,
        };
    }, [aspectRatio, deviceId]);

    const webcamRef = useRef(undefined);

    const capture = useCallback(() => {
        const src = webcamRef.current.getScreenshot();
        setPreviewImg(src);
    }, [webcamRef]);

    const confirm = useCallback(() => {
        onPhoto(previewImg);
        setPreviewImg(undefined);
    }, [onPhoto, previewImg]);

    const retake = useCallback(() => {
        setPreviewImg(undefined);
    }, []);

    const closeCamera = useCallback(() => {
        toggleCamera();
    }, [toggleCamera]);
    useEffect(() => {
        const storedDeviceId = localStorage.getItem("CameraDeviceId");
        if (storedDeviceId) {
            setDeviceId(storedDeviceId);
        }
    }, []);

    return (
        <>
            {previewImg && (
                <>
                    <img alt={"Preview"} src={previewImg} className="w-100" />
                    <div style={{ position: "fixed", top: "50%", right: "5px", transform: "translateY(-50%)" }}>
                        <IconButton
                            variant="primary"
                            className="mb-1"
                            block
                            faIconString="fas fa-redo fa-2x"
                            onClick={retake}
                            size="lg"
                        />
                        <IconButton
                            variant="success"
                            block
                            faIconString="fas fa-check fa-2x"
                            onClick={confirm}
                            size="lg"
                        />
                    </div>
                </>
            )}
            {!previewImg && (
                <>
                    {overlayUrl && (
                        <div className={styles.camOverlay}>
                            <img
                                style={{
                                    height: size.height,
                                    width: size.width,
                                }}
                                alt={"Overlay"}
                                src={overlayUrl}
                            />
                        </div>
                    )}

                    <Webcam
                        audio={false}
                        height={"100%"}
                        width={"100%"}
                        forceScreenshotSourceSize
                        videoConstraints={constraints}
                        screenshotFormat="image/jpeg"
                        ref={webcamRef}
                    />

                    <div className={styles.topBar}>
                        <Row className={"align-items-center py-1"}>
                            <Col xs={2}>
                                <IconButton
                                    aria-label="Close Camera"
                                    className="ms-2"
                                    variant="danger"
                                    faIconString="fas fa-arrow-left"
                                    onClick={closeCamera}
                                    size="lg"
                                />
                            </Col>

                            <Col className={"text-center"} xs={8}>
                                <h5 className={"text-white"}>{label}</h5>
                            </Col>

                            <Col xs={2}>
                                <span className={"text-white ms-3"}>
                                    {imageNumber}
                                    {imageNumber && imageTotal && " / "}
                                    {imageTotal}
                                </span>
                            </Col>
                        </Row>
                    </div>

                    <div className={styles.bottomBar}>
                        <Row className={"align-items-center py-1"}>
                            <Col xs={12}>
                                <span className={"text-white ms-3"}>{description}</span>
                            </Col>
                        </Row>
                    </div>

                    <div style={{ position: "fixed", top: "50%", right: "2%", transform: "translateY(-50%)" }}>
                        <Button
                            aria-label="Capture"
                            style={{ borderRadius: "50%" }}
                            color="success"
                            outline
                            onClick={capture}
                            size="lg"
                        >
                            <i className="fas fa-2x fa-camera pt-1 pb-2" />
                        </Button>
                    </div>
                </>
            )}
        </>
    );
}
