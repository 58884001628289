import { useMemo } from "react";
import { Alert, Col, Container, Row } from "reactstrap";

export default function IncorrectOrientation() {
    const isPortratiUserAgent = useMemo(() => {
        const lowerUserAgent = navigator.userAgent?.toLowerCase() ?? "";

        return ["fban", "fbav", "instagram"].some(ua => lowerUserAgent.includes(ua));
    }, []);

    return (
        <>
            <Container className="p-3 text-center h-100">
                <Row>
                    <Col>
                        <img src="https://media.g3r.co.uk/signal/signal-logo.png" alt="Signal" className={"w-25"} />
                    </Col>
                </Row>
                <Row className="p-4">
                    <>
                        {isPortratiUserAgent && (
                            <>
                                <Alert color="danger">
                                    <h1>Please open this page in your browser to continue</h1>
                                </Alert>
                                <Col>
                                    To do this, click on the menu button in the top right of your screen, and then
                                    select "Open in Chrome/Firefox/Safari"
                                </Col>
                            </>
                        )}
                        {!isPortratiUserAgent && <Col>Please rotate your device</Col>}
                    </>
                </Row>
                <Row>
                    <Col>
                        <i className="fas fa-mobile-alt fa-lg"></i>

                        <i className="fas fa-directions fa-lg mx-3"></i>

                        <i className="fas fa-mobile-alt fa-rotate-90 fa-lg"></i>
                    </Col>
                </Row>
            </Container>
        </>
    );
}
