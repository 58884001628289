import { IconButton, showconfirmmodal } from "@g3r-developers/g3-common";
import CameraSelector from "Components/Settings/CameraSelector";
import { useCallback } from "react";
import { Col, Container, Row } from "reactstrap";

export default function SettingsPage(): JSX.Element {
    const handleCleanDb = useCallback(async () => {
        const x = await showconfirmmodal(
            "Are you sure?",
            "This will delete all saved inspections and log you out - are you sure you want to continue?"
        );

        if (!x) {
            return;
        }
        window.indexedDB
            .databases()
            .then(r => {
                for (let i = 0; i < r.length; i++) window.indexedDB.deleteDatabase(r[i].name);
            })
            .then(() => {
                window.location.href = "/dashboard";
            });
    }, []);
    return (
        <Container className="p-4">
            <Row className="mt-1">
                <Col xs="9">
                    <h1>AVID Settings</h1>
                    <p className="text-muted mb-3">Please set your preferences for AVID on this device below</p>
                </Col>
                <Col xs="3" className="text-end">
                    <IconButton
                        className="me-2"
                        faIconString={"fas fa-chevron-circle-left"}
                        tooltip={"Back to Dashboard"}
                        url={"/"}
                        variant={"secondary"}
                    />
                </Col>
            </Row>
            <CameraSelector />
            <Row className="mt-3">
                <Col>
                    <IconButton
                        faIconString="fa fa-trash-alt"
                        text="Clean Database"
                        variant="primary"
                        block
                        onClick={handleCleanDb}
                    />
                </Col>
            </Row>
        </Container>
    );
}
