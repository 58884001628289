import Camera from "Components/Camera/Camera";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import inspectionAssetStore from "Services/DbStores/InspectionAssetStore";
import { InspectionType } from "Types/Enums/InspectionType";
import { MediaType } from "Types/Enums/MediaType";
import { TyreSectionName, TyreSectionNameToOfflineAssetType } from "Types/Inspection/Enums/TyreSectionName";
import { OfflineAssetModel } from "Types/Inspection/OfflineAssetModel";
import { OfflineInspectionDetailModel } from "Types/Inspection/OfflineInspectionDetailModel";
import { QuestionAnswerModel } from "Types/Question/QuestionAnswerModel";
import SignalTyreQuestions from "./SignalTyreQuestions";
import TyreQuestions from "./TyreQuestions";

interface TyreModalProps {
    close(): void;
    handleChange(sectionId: string, questionId: string, newValue: string, textValue: string): void;
    header: string;
    inspectionId: string;
    questions: QuestionAnswerModel[];
    tyreSection: TyreSectionName;
    inspectionDetails: OfflineInspectionDetailModel;
}

export default function TyreModal({
    close,
    handleChange,
    header,
    inspectionDetails,
    inspectionId,
    questions,
    tyreSection,
}: TyreModalProps): JSX.Element {
    const [showCamera, setShowCamera] = useState<boolean>(false);
    const [tyreImageAsset, setTyreImageAsset] = useState<OfflineAssetModel>(undefined);

    useEffect(() => {
        // grab existing tyre image
        inspectionAssetStore
            .getOfflineAssets(inspectionId, TyreSectionNameToOfflineAssetType(tyreSection))
            .then(assets => {
                const imgAsset = assets[0];
                setTyreImageAsset(imgAsset);
            });
    }, [inspectionId, tyreSection]);

    const toggleShowCamera = useCallback(() => setShowCamera(p => !p), []);

    const handlePhoto = useCallback(
        async (image: string) => {
            let imgAsset = {
                inspectionAssetId: tyreImageAsset?.inspectionAssetId,
                asset: image,
                inspectionId: inspectionId,
                mediaType: MediaType.Photo,
                type: TyreSectionNameToOfflineAssetType(tyreSection),
            } as OfflineAssetModel;

            imgAsset = await inspectionAssetStore.storeOfflineAsset(imgAsset);

            setTyreImageAsset(imgAsset);
            toggleShowCamera();
        },
        [inspectionId, toggleShowCamera, tyreImageAsset, tyreSection]
    );

    const tyreQuestions = useMemo(() => {
        if (
            inspectionDetails?.inspection?.inspectionTypeId === InspectionType.Signal &&
            tyreSection !== TyreSectionName.Spare
        ) {
            return (
                <SignalTyreQuestions
                    handleChange={handleChange}
                    questions={questions}
                    toggleShowCamera={toggleShowCamera}
                    tyreImageAsset={tyreImageAsset}
                />
            );
        } else {
            return (
                <TyreQuestions
                    handleChange={handleChange}
                    questions={questions}
                    toggleShowCamera={toggleShowCamera}
                    tyreImageAsset={tyreImageAsset}
                />
            );
        }
    }, [
        handleChange,
        inspectionDetails?.inspection?.inspectionTypeId,
        questions,
        toggleShowCamera,
        tyreImageAsset,
        tyreSection,
    ]);

    return (
        <Modal isOpen toggle={close}>
            <ModalHeader toggle={close}>{header}</ModalHeader>

            <ModalBody>
                {tyreQuestions}

                {showCamera && (
                    <Modal className="p-0 m-0" isOpen fullscreen={true}>
                        <ModalBody className="p-0">
                            <Camera
                                label={"Tyre"}
                                description={"Tyre"}
                                onPhoto={(image: string) => handlePhoto(image)}
                                toggleCamera={toggleShowCamera}
                            />
                        </ModalBody>
                    </Modal>
                )}
            </ModalBody>
            <ModalFooter>
                <Button variant="secondary" onClick={close}>
                    Close
                </Button>
            </ModalFooter>
        </Modal>
    );
}
