import SyncInspectionLite from "Components/SignalLite/SyncInspectionLite";
import { useParams } from "react-router-dom";

export default function SyncInspectionLitePage(): JSX.Element {
    const { inspectionId } = useParams<{
        inspectionId: string;
    }>();

    return <SyncInspectionLite inspectionId={inspectionId} />;
}
