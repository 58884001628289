import ConfirmInspection from "Pages/ConfirmInspectionPage";
import Dashboard from "Pages/Dashboard";
import Inspection from "Pages/Inspection";
import InspectionCompletePage from "Pages/InspectionCompletePage";
import InspectionsPage from "Pages/InspectionsPage";
import Login from "Pages/Login";
import SettingsPage from "Pages/Settings";
import ConfirmInspectionLitePage from "Pages/SignalLite/ConfirmInspectionLitePage";
import SyncInspectionLitePage from "Pages/SignalLite/SyncInspectionLitePage";
import VehicleSearchPage from "Pages/VehicleSearch";
import { Route, Routes } from "react-router-dom";
import PrivateRoute from "./PrivateRoute";

export default function Index() {
    return (
        <Routes>
            {/* note: routes are resolved top-down. please mind the order of the routes */}
            <Route path="/" element={<Login />} />
            <Route path="/inspection/confirm/:inspectionId" element={<ConfirmInspection />} />
            <Route path="/inspection-lite/confirm/:inspectionId" element={<ConfirmInspectionLitePage />} />
            <Route path="/inspections" element={<InspectionsPage />} />
            <Route path="/inspection-lite/sync/:inspectionId" element={<SyncInspectionLitePage />} />
            <Route path="/inspection/complete" element={<InspectionCompletePage />} />
            <Route path="/inspection/:inspectionId" element={<Inspection />} />
            <Route path="/vehicle/search/:vrm" element={<VehicleSearchPage />} />
            <Route path="/settings" element={<SettingsPage />} />
            <Route element={<PrivateRoute />}>
                <Route index path="/dashboard" element={<Dashboard />} />
            </Route>
        </Routes>
    );
}
