import { useMemo } from "react";
import { Button, Col, Row } from "reactstrap";
import { QuestionAnswerModel } from "Types/Question/QuestionAnswerModel";

interface QuestionToggleColorConfig {
    no: string;
    yes: string;
}

interface QuestionToggleProps {
    inverted?: boolean;
    onChange(value: string): void;
    question: QuestionAnswerModel;
}

export function QuestionToggle({ inverted, onChange, question }: QuestionToggleProps) {
    const colors: QuestionToggleColorConfig = useMemo(() => {
        const negative = "danger";
        const neutral = "light";
        const positive = "success";

        return {
            no: question.answer === "false" ? (inverted ? positive : negative) : neutral,
            yes: question.answer === "true" ? (inverted ? negative : positive) : neutral,
        };
    }, [inverted, question.answer]);

    return (
        <Row>
            <Col>
                <Button className="p-3" block color={colors.yes} onClick={() => onChange("true")}>
                    Yes
                </Button>
            </Col>

            <Col>
                <Button className="p-3" block color={colors.no} onClick={() => onChange("false")}>
                    No
                </Button>
            </Col>
        </Row>
    );
}
