import RecorderControls from "Components/Audio/Recorder";
import useRecorder from "Components/Audio/UseRecorder";
import inspectionAssetStore from "Services/DbStores/InspectionAssetStore";
import inspectionDetailStore from "Services/DbStores/InspectionDetailStore";
import utils from "Services/Utils";
import { MediaType } from "Types/Enums/MediaType";
import { InspectionAsset } from "Types/Inspection/InspectionConfig";
import { OfflineInspectionDetailModel } from "Types/Inspection/OfflineInspectionDetailModel";
import { UseRecorder } from "Types/Recorder";
import { Guid } from "guid-typescript";
import { useCallback, useEffect, useMemo } from "react";

interface AudioButtonProps {
    inspection: OfflineInspectionDetailModel;
    updateInspectionDetail(inspection: OfflineInspectionDetailModel): void;
}

export function AudioButton({ inspection, updateInspectionDetail }: AudioButtonProps) {
    const { recorderState, ...handlers }: UseRecorder = useRecorder();

    const { audio } = recorderState;

    const storeAudioAsset = useCallback(
        async (newAudio: Blob) => {
            if (!newAudio) {
                return;
            }

            const blobString = await utils.convertBlobToBase64(newAudio);

            if (inspection?.inspection?.audioAssetId) {
                const existingAsset = await inspectionAssetStore.loadInspectionAsset(
                    inspection.inspection.audioAssetId
                );

                // don't save the same audio file
                if (existingAsset?.asset === blobString) {
                    return;
                }
            }

            const assetId = await inspectionAssetStore.storeAsset({
                asset: blobString,
                mediaType: MediaType.Audio,
                inspectionAssetId: inspection?.inspection?.audioAssetId ?? Guid.create().toString(),
            } as InspectionAsset);

            const newInspectionDetails = {
                ...inspection,
                inspection: {
                    ...inspection.inspection,
                    audioAssetId: assetId,
                },
            };

            inspectionDetailStore.save(newInspectionDetails);
            updateInspectionDetail(newInspectionDetails);
        },
        [inspection, updateInspectionDetail]
    );

    useEffect(() => {
        storeAudioAsset(audio);
    }, [audio, storeAudioAsset]);

    const btnVariant = useMemo(() => {
        return inspection?.inspection?.audioAssetId ? "success" : "danger";
    }, [inspection?.inspection?.audioAssetId]);

    return (
        <div className="recorder-container">
            <RecorderControls recorderState={recorderState} handlers={handlers} variant={btnVariant} />
        </div>
    );
}
