import { IconButton, Loading } from "@g3r-developers/g3-common";
import { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Col, Container, Modal, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";
import inspectionService from "Services/InspectionService";
import { InspectionModel } from "Types/Inspection/InspectionModel";

interface CopyInspectionModalProps {
    close: () => void;
    inspection: InspectionModel;
}

export default function CopyInspectionModal({ close, inspection }: CopyInspectionModalProps) {
    const [loading, setLoading] = useState<boolean>(false);

    const navigate = useNavigate();

    const handleCopyInspection = useCallback(async () => {
        setLoading(true);

        try {
            const res = await inspectionService.copy(inspection.id);

            await inspectionService.storeDetail(res.newInspectionDetail);

            await inspectionService.updateDetailWithCompletedInspection(
                res.newInspectionDetail.inspection.id,
                res.oldInspectionDetails
            );

            navigate(`/inspection/${res.newInspectionDetail.inspection.id}`);
        } catch (e) {
            alert(e?.message ?? "An error has occurred");
            setLoading(false);
        }
    }, [navigate, inspection]);

    return (
        <Modal
            isOpen
            toggle={
                loading
                    ? () => {
                          /* no-op */
                      }
                    : close
            }
        >
            <ModalHeader
                toggle={
                    loading
                        ? () => {
                              /* no-op */
                          }
                        : close
                }
            >
                Copy Inspection - {inspection.vrm}
            </ModalHeader>

            <ModalBody>Please confirm that the inspection for {inspection.vrm} should be copied</ModalBody>

            <ModalFooter>
                <Container fluid>
                    <Row>
                        <Col>
                            <IconButton
                                block
                                disabled={loading}
                                onClick={handleCopyInspection}
                                text={"Confirm"}
                                variant={"primary"}
                            />
                        </Col>
                    </Row>

                    {loading && (
                        <Row className={"mt-4"}>
                            <Col>
                                <Loading text={"Copying Inspection..."} />
                            </Col>
                        </Row>
                    )}
                </Container>
            </ModalFooter>
        </Modal>
    );
}
