import { MediaType } from "Types/Enums/MediaType";
import { InspectionAsset, InspectionDamageSection } from "Types/Inspection/InspectionConfig";
import { InspectionDamageItem } from "Types/Inspection/InspectionDamageItem";
import { InspectionSplatModel } from "Types/Inspection/InspectionSplatModel";
import { Guid } from "guid-typescript";
import { DB_NAMES, initDb } from "../QuestionStore";
import inspectionAssetStore from "./InspectionAssetStore";

const getDamage = (splats: InspectionSplatModel[], inspectionDamageItemId: string): InspectionDamageItem =>
    splats.flatMap(s => s.damages).find(d => d.inspectionDamageItemId === inspectionDamageItemId);

const saveDamage = async (inspectionId: string, splats: InspectionSplatModel[]) => {
    const db = await initDb();

    await db.putValue(DB_NAMES.INSPECTION_DAMAGE, {
        inspectionDamageId: `inspection-damage-${inspectionId}`,
        splats: splats,
    } as InspectionDamageSection);
};

const loadDamage = async (inspectionId: string): Promise<InspectionSplatModel[]> => {
    const db = await initDb();
    const value = (await db.getValue(
        DB_NAMES.INSPECTION_DAMAGE,
        `inspection-damage-${inspectionId}`
    )) as InspectionDamageSection;

    const splats = value?.splats;

    return Array.isArray(splats) ? splats : [];
};

const getDamages = async (inspectionId: string): Promise<InspectionSplatModel[]> => {
    const damages = await loadDamage(inspectionId);
    return damages;
};

const addDamage = async (inspectionId: string, damage: InspectionDamageItem): Promise<void> => {
    if (!damage.inspectionDamageItemId) {
        damage.inspectionDamageItemId = Guid.create().toString();
    }

    const newImages = [...(damage.images ?? [])];

    const assetIds = [...(damage.assetIds ?? [])];

    const newAssetIds: string[] = [];

    for (const assetId of assetIds) {
        // eslint-disable-next-line no-await-in-loop
        const asset = await inspectionAssetStore.loadInspectionAsset(assetId);

        if (!asset) {
            continue;
        }

        const imageIndex = newImages.indexOf(asset.asset);

        if (imageIndex > -1) {
            newImages.splice(imageIndex, 1);
            newAssetIds.push(assetId);
        } else {
            // eslint-disable-next-line no-await-in-loop
            await inspectionAssetStore.remove(assetId);
        }
    }

    damage.assetIds = newAssetIds;

    for (const image of newImages) {
        // eslint-disable-next-line no-await-in-loop
        const assetId = await inspectionAssetStore.storeAsset({
            asset: image,
            mediaType: MediaType.Photo,
        } as InspectionAsset);

        damage.assetIds.push(assetId);
    }

    const splats = await getDamages(inspectionId);
    let splat = splats.find(d => d.splatId.toUpperCase() === damage.splatId.toUpperCase());

    if (!splat) {
        splat = {
            splatId: damage.splatId.toUpperCase(),
            damages: [],
        };
        splats.push(splat);
    }

    const existingDamageIndex = splat.damages.findIndex(
        d => d.inspectionDamageItemId === damage.inspectionDamageItemId
    );

    if (existingDamageIndex >= 0) {
        splat.damages[existingDamageIndex] = damage;
    } else {
        splat.damages.push(damage);
    }

    await saveDamage(inspectionId, splats);
};

const deleteDamage = async (inspectionId: string, inspectionDamageItemId: string): Promise<void> => {
    const splats = await getDamages(inspectionId);
    const damage = getDamage(splats, inspectionDamageItemId);

    if (!damage) {
        return;
    }

    const splat = splats.find(s => s.splatId.toUpperCase() === damage.splatId.toUpperCase());

    const damageIndex = splat.damages.indexOf(damage);

    splat.damages.splice(damageIndex, 1);

    await saveDamage(inspectionId, splats);
};

const remove = async (inspectionId: string): Promise<void> => {
    const db = await initDb();
    await db.deleteValue(DB_NAMES.INSPECTION_DAMAGE, `inspection-damage-${inspectionId}`);
};

const inspectionDamageStore = {
    addDamage,
    deleteDamage,
    getDamages,
    remove,
};

export default inspectionDamageStore;
