import InspectionSyncTable from "Components/Inspection/InspectionSyncTable";
import React from "react";
import { Col, Row } from "reactstrap";

export default function InspectionsPage() {
    return (
        <>
            <Row className={"mb-4 pt-4"}>
                <Col className={"text-center"}>
                    <h1>Downloaded Inspections</h1>
                </Col>
            </Row>

            <Row>
                <Col className={"m-3"}>
                    <InspectionSyncTable />
                </Col>
            </Row>
        </>
    );
}
