import { InspectionConfig } from "Types/Inspection/InspectionConfig";
import { DB_NAMES, initDb } from "../QuestionStore";

const loadInspectionConfig = async (inspectionId: string): Promise<string[]> => {
    const db = await initDb();
    const value = (await db.getValue(DB_NAMES.INSPECTION_CONFIG, inspectionId)) as InspectionConfig;
    return value.sectionIds;
};

const remove = async (inspectionId: string): Promise<void> => {
    const db = await initDb();
    await db.deleteValue(DB_NAMES.INSPECTION_CONFIG, inspectionId);
};

const saveInspectionConfig = async (config: InspectionConfig): Promise<void> => {
    const db = await initDb();
    await db.putValue(DB_NAMES.INSPECTION_CONFIG, config);
};

const inspectionConfigStore = {
    loadInspectionConfig,
    remove,
    saveInspectionConfig,
};

export default inspectionConfigStore;
