import { InspectionStatus } from "Types/Enums/InspectionStatus";
import { LookupModel } from "Types/Lookup/LookupModel";

interface InspectionStatuses {
    inProgress: LookupModel;
    pending: LookupModel;
    deferred: LookupModel;
    canceled: LookupModel;
    completed: LookupModel;
}

const inspectionStatusLookupTypeId = "18324757-36d0-45fc-a86e-a8e851844fa2";

const inspectionStatuses: InspectionStatuses = {
    inProgress: {
        id: InspectionStatus.InProgress,
        reference: "InProgress",
        display: "In Progress",
        lookupTypeId: inspectionStatusLookupTypeId,
        orderIndex: 0,
    },
    pending: {
        id: InspectionStatus.Pending,
        reference: "pending",
        display: "Pending",
        lookupTypeId: inspectionStatusLookupTypeId,
        orderIndex: 0,
    },
    deferred: {
        id: InspectionStatus.Deferred,
        reference: "Deferred",
        display: "Deferred",
        lookupTypeId: inspectionStatusLookupTypeId,
        orderIndex: 0,
    },
    canceled: {
        id: InspectionStatus.Canceled,
        reference: "Canceled",
        display: "Canceled",
        lookupTypeId: inspectionStatusLookupTypeId,
        orderIndex: 0,
    },
    completed: {
        id: InspectionStatus.Completed,
        reference: "Completed",
        display: "Completed",
        lookupTypeId: inspectionStatusLookupTypeId,
        orderIndex: 0,
    },
};

export default inspectionStatuses;
