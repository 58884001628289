import inspectionSectionStore from "Services/DbStores/InspectionSectionStore";
import inspectionValidationService from "Services/InspectionValidationService";
import { TyreSectionName } from "Types/Inspection/Enums/TyreSectionName";
import { SectionModel } from "Types/Inspection/SectionModel";
import { QuestionAnswerModel } from "Types/Question/QuestionAnswerModel";
import { useCallback, useEffect, useState } from "react";
import { Col, Row } from "reactstrap";
import TyreButton from "./TyreButton";
import styles from "./TyreSection.module.scss";

export interface TyreSectionProps {
    children: JSX.Element | JSX.Element[];
    handleSectionComplete(index: number, state: boolean): void;
    inspectionId: string;
    section: SectionModel;
    sectionIndex: number;
}

export default function TyreSection({
    children,
    handleSectionComplete,
    inspectionId,
    section,
    sectionIndex,
}: TyreSectionProps): JSX.Element {
    const [questionAnswerSections, setQuestionAnswerSections] = useState<Record<string, QuestionAnswerModel[]>>({});

    const sortQuestions = useCallback((questions: QuestionAnswerModel[]) => {
        const sortedQuestions = [...questions].sort((a, b) => b.questionText.localeCompare(a.questionText));

        const spareTypeQuestion = sortedQuestions.find(q => q.questionText === "Spare Type");

        if (!spareTypeQuestion) {
            return sortedQuestions;
        }

        const spareTypeAnswer = spareTypeQuestion.choiceGroup?.choiceItems?.find(
            ci => ci.id === spareTypeQuestion.answer
        )?.displayName;

        if (spareTypeAnswer === "Repair Kit") {
            const repairKitExpiryQuestion = sortedQuestions.find(q => q.questionText === "Repair Kit Expiry");
            if (repairKitExpiryQuestion !== undefined) {
                return [spareTypeQuestion, repairKitExpiryQuestion];
            }
        }

        if (spareTypeAnswer === "Tyre") {
            const tyreManufacturerQuestion = sortedQuestions.find(q => q.questionText === "Tyre Manufacturer");
            if (tyreManufacturerQuestion !== undefined) {
                return [spareTypeQuestion, tyreManufacturerQuestion];
            }
        }

        return [spareTypeQuestion];
    }, []);

    const loadQuestionAnswerSections = useCallback(async () => {
        const qaSections: Record<string, QuestionAnswerModel[]> = {};

        const sectionPromises =
            section.sections?.map(s => inspectionSectionStore.getSectionFromSection(inspectionId, s)) ?? [];

        const sections = await Promise.all(sectionPromises);

        sections.forEach((questionAnswers, i) => {
            const sectionName = section.sections[i].name;
            qaSections[sectionName] = sortQuestions(questionAnswers);
        });

        setQuestionAnswerSections(qaSections);
    }, [inspectionId, section?.sections, sortQuestions]);

    useEffect(() => {
        loadQuestionAnswerSections();
    }, [loadQuestionAnswerSections]);

    const handleChange = useCallback(
        async (sectionId: string, questionId: string, newValue: string, textValue: string) => {
            await inspectionSectionStore.updateInspectionSectionAnswer({
                inspectionId: inspectionId,
                sectionId: sectionId,
                questionId: questionId,
                answer: newValue,
                textValue: textValue,
            });

            let unanasweredTyresArray = [] as QuestionAnswerModel[];

            Object.entries(questionAnswerSections).forEach(([, question]) => {
                unanasweredTyresArray = unanasweredTyresArray.concat(question);
            });

            for await (const question of unanasweredTyresArray) {
                if (question.id === questionId && question.answer === undefined)
                    await inspectionSectionStore.updateInspectionSectionAnswer({
                        inspectionId: inspectionId,
                        sectionId: question.sectionId,
                        questionId: questionId,
                        answer: newValue,
                        textValue: textValue,
                    });
            }

            loadQuestionAnswerSections();
        },
        [inspectionId, loadQuestionAnswerSections, questionAnswerSections]
    );

    const checkForCompletion = useCallback(async () => {
        const isComplete = await inspectionValidationService.validateTyres(inspectionId);

        handleSectionComplete(sectionIndex, isComplete);
    }, [handleSectionComplete, inspectionId, sectionIndex]);

    useEffect(() => {
        checkForCompletion();
    }, [checkForCompletion, questionAnswerSections]);

    return (
        <Row className={"align-items-center flex-nowrap justify-content-center"}>
            <Col>
                {questionAnswerSections[TyreSectionName.Front] && (
                    <Row className="mt-3">
                        <Col>
                            <div className={styles.tyreButtonRow}>
                                <TyreButton
                                    className={`${styles.tyreButton} ${styles.front}`}
                                    handleChange={handleChange}
                                    inspectionId={inspectionId}
                                    tyreSection={TyreSectionName.Front}
                                    questions={questionAnswerSections[TyreSectionName.Front]}
                                />

                                <TyreButton
                                    className={`${styles.tyreButton} ${styles.rear}`}
                                    handleChange={handleChange}
                                    inspectionId={inspectionId}
                                    tyreSection={TyreSectionName.Back}
                                    questions={questionAnswerSections[TyreSectionName.Back]}
                                />
                            </div>
                        </Col>
                    </Row>
                )}
                {questionAnswerSections[TyreSectionName.OffsideFront] && (
                    <Row className="">
                        <Col>
                            <div className={styles.tyreButtonRow}>
                                <TyreButton
                                    className={`${styles.tyreButton} ${styles.front}`}
                                    handleChange={handleChange}
                                    inspectionId={inspectionId}
                                    tyreSection={TyreSectionName.OffsideFront}
                                    questions={questionAnswerSections[TyreSectionName.OffsideFront]}
                                />

                                <TyreButton
                                    className={`${styles.tyreButton} ${styles.rear}`}
                                    handleChange={handleChange}
                                    inspectionId={inspectionId}
                                    tyreSection={TyreSectionName.OffsideRear}
                                    questions={questionAnswerSections[TyreSectionName.OffsideRear]}
                                />
                            </div>
                        </Col>
                    </Row>
                )}

                {questionAnswerSections[TyreSectionName.Spare] && (
                    <Row className={"align-items-center flex-nowrap justify-content-center"}>
                        <Col>{/* layout */}</Col>
                        <Col>{children}</Col>
                        <Col xs={"auto"}>
                            <TyreButton
                                handleChange={handleChange}
                                inspectionId={inspectionId}
                                tyreSection={TyreSectionName.Spare}
                                questions={questionAnswerSections[TyreSectionName.Spare]}
                            />
                        </Col>
                        <Col>{/* layout */}</Col>
                    </Row>
                )}

                {!questionAnswerSections[TyreSectionName.Spare] && (
                    <Row className={"align-items-center flex-nowrap justify-content-center"}>
                        <Col>{children}</Col>
                    </Row>
                )}

                {questionAnswerSections[TyreSectionName.NearsideFront] && (
                    <Row className={"justify-content-around"}>
                        <Col>
                            <div className={styles.tyreButtonRow}>
                                <TyreButton
                                    className={`${styles.tyreButton} ${styles.front}`}
                                    handleChange={handleChange}
                                    inspectionId={inspectionId}
                                    tyreSection={TyreSectionName.NearsideFront}
                                    questions={questionAnswerSections[TyreSectionName.NearsideFront]}
                                />

                                <TyreButton
                                    className={`${styles.tyreButton} ${styles.rear}`}
                                    handleChange={handleChange}
                                    inspectionId={inspectionId}
                                    tyreSection={TyreSectionName.NearsideRear}
                                    questions={questionAnswerSections[TyreSectionName.NearsideRear]}
                                />
                            </div>
                        </Col>
                    </Row>
                )}
            </Col>
        </Row>
    );
}
