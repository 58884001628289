import React, { useMemo } from "react";
import { Badge } from "reactstrap";

interface BadgeConfig {
    color: string;
    label: string;
}

interface InspectionStoredStatusBadgeProps {
    className?: string;
    stored: boolean;
}

export default function InspectionStoredStatusBadge({ className, stored }: InspectionStoredStatusBadgeProps) {
    const badgeConfig: BadgeConfig = useMemo(
        () => ({
            color: stored ? "success" : "secondary",
            label: stored ? "Stored for offline use" : "No local copy",
        }),
        [stored]
    );

    return (
        <Badge className={className} color={badgeConfig.color}>
            {badgeConfig.label}
        </Badge>
    );
}
