import { useCallback, useState } from "react";
import { Badge, Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import styles from "./Damage.module.scss";

interface InteractiveDamageImageProps {
    isNew?: boolean;
    onDelete?: () => void;
    src: string;
}

export default function InteractiveDamageImage({ isNew, onDelete, src }: InteractiveDamageImageProps) {
    const [showModal, setShowModal] = useState<boolean>(false);

    const toggleShowModal = useCallback(() => setShowModal(p => !p), []);

    const handleDelete = useCallback(() => {
        if (onDelete) {
            onDelete();
        }

        toggleShowModal();
    }, [onDelete, toggleShowModal]);

    return (
        <>
            <button className={`${styles.damage_img_btn}`} onClick={toggleShowModal}>
                <div className={`${styles.damage_img_container}`}>
                    <img alt={"Damage Item Evidence"} className={`img-thumbnail`} src={src} />

                    {isNew && (
                        <Badge className={`${styles.damage_img_badge}`} color={"success"}>
                            NEW
                        </Badge>
                    )}
                </div>
            </button>

            {showModal && (
                <Modal fullscreen={"xl"} isOpen toggle={toggleShowModal}>
                    <ModalHeader toggle={toggleShowModal}></ModalHeader>

                    <ModalBody>
                        <img alt={"Damage Item Evidence"} className={"img-fluid mb-2"} src={src} />

                        {isNew && (
                            <div className={"text-center"}>
                                <Badge className={"fs-6"} color={"success"}>
                                    NEW
                                </Badge>
                            </div>
                        )}
                    </ModalBody>

                    {!!onDelete && (
                        <ModalFooter>
                            <Button block color={"danger"} onClick={handleDelete} size={"lg"}>
                                Delete
                            </Button>
                        </ModalFooter>
                    )}
                </Modal>
            )}
        </>
    );
}
