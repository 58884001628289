import { Loading, showokmodal } from "@g3r-developers/g3-common";
import inspectionStagingService from "Services/InspectionStagingService";
import inspectionValidationService from "Services/InspectionValidationService";
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Col, Row } from "reactstrap";

interface SyncInspectionLiteProps {
    inspectionId: string;
}

export default function SyncInspectionLite({ inspectionId }: SyncInspectionLiteProps) {
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

    const navigate = useNavigate();

    const handleComplete = useCallback(async () => {
        setIsSubmitting(true);

        const isComplete = await inspectionValidationService.validateInspection(inspectionId);

        if (!isComplete) {
            await showokmodal("Inspection is not complete. Please ensure you have completed all sections.");

            navigate(`/inspection/${inspectionId}`);

            return;
        }

        await inspectionStagingService.completeInspection(inspectionId, () => {});

        navigate("/inspection/complete");
    }, [navigate, inspectionId]);

    useEffect(() => {
        if (!isSubmitting) {
            handleComplete();
        }
    }, [handleComplete, isSubmitting]);

    return (
        <Row className={"flex-column mt-3"}>
            <Col>
                <Loading text={"Submitting Your Inspection"} />
            </Col>

            <Col className={"text-center"}>
                Please wait and do not refresh the page while your inspection is submitting
            </Col>
        </Row>
    );
}
