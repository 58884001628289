import { Guid } from "guid-typescript";
import { DateTime } from "luxon";
import utils from "Services/Utils";
import { MediaType } from "Types/Enums/MediaType";
import { OfflineAssetType } from "Types/Inspection/Enums/OfflineAssetType";
import { InspectionAsset } from "Types/Inspection/InspectionConfig";
import { OfflineAssetModel } from "Types/Inspection/OfflineAssetModel";
import { DB_NAMES, initDb } from "../QuestionStore";

const TYRE_SPLAT_ID = "inspection-tyre-splat";

const getOfflineAssets = async (inspectionId: string, offlineAssetType?: OfflineAssetType) => {
    const db = await initDb();
    const assets: OfflineAssetModel[] = await db.getAllValue(DB_NAMES.INSPECTION_ASSET);
    let offlineAssets = assets.filter(a => a.inspectionId && a.inspectionId === inspectionId && a.type !== undefined);

    if (offlineAssetType !== undefined) {
        offlineAssets = offlineAssets.filter(a => a.type === offlineAssetType);
    }

    return offlineAssets;
};

const loadInspectionAsset = async (assetId: string): Promise<InspectionAsset> => {
    const db = await initDb();
    const value = await db.getValue<InspectionAsset>(DB_NAMES.INSPECTION_ASSET, assetId);
    return value;
};
const loadTyreSplat = async (): Promise<InspectionAsset> => loadInspectionAsset(TYRE_SPLAT_ID);
const remove = async (assetId: string): Promise<void> => {
    const db = await initDb();
    await db.deleteValue(DB_NAMES.INSPECTION_ASSET, assetId);
};
const saveInspectionAsset = async (asset: InspectionAsset) => {
    const db = await initDb();
    await db.putValue(DB_NAMES.INSPECTION_ASSET, asset);
};
const storeAsset = async (asset: InspectionAsset): Promise<string> => {
    asset.inspectionAssetId = Guid.create().toString();
    await saveInspectionAsset(asset);
    return asset.inspectionAssetId;
};
const storeGenericAssetsIfRequired = async (): Promise<void> => {
    const db = await initDb();
    const tyreSplat = await db.getValue<InspectionAsset>(DB_NAMES.INSPECTION_ASSET, TYRE_SPLAT_ID);
    if (!tyreSplat) {
        const remoteUrl = "https://g3-web.s3.eu-west-2.amazonaws.com/car-tyre-splat.png";
        const offlineUrl = await utils.getOfflineImageUrl(remoteUrl);
        await db.putValue(DB_NAMES.INSPECTION_ASSET, {
            inspectionAssetId: TYRE_SPLAT_ID,
            asset: offlineUrl,
            mediaType: MediaType.Photo,
        });
    }
};

const storeOfflineAsset = async (asset: OfflineAssetModel) => {
    if (!asset.inspectionAssetId) {
        asset.inspectionAssetId = Guid.create().toString();
    }

    asset.added = DateTime.now().toISO();

    const db = await initDb();
    await db.putValue(DB_NAMES.INSPECTION_ASSET, asset);

    return asset;
};

const inspectionAssetStore = {
    getOfflineAssets,
    loadInspectionAsset,
    loadTyreSplat,
    remove,
    storeAsset,
    storeGenericAssetsIfRequired,
    storeOfflineAsset,
};

export default inspectionAssetStore;
