import * as Sentry from "@sentry/react";
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from "react-router-dom";
import { combineReducers, createStore } from "redux";
import { SessionServiceOptions, sessionReducer, sessionService } from "redux-react-session";
import App from "./App";
import "./index.scss";
import reportWebVitals from "./reportWebVitals";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";

const sentryDsn = process.env.REACT_APP_SENTRY_DSN;

if (sentryDsn) {
    Sentry.init({
        dsn: sentryDsn,
        transport: Sentry.makeBrowserOfflineTransport(Sentry.makeFetchTransport),
        integrations: [
            Sentry.browserTracingIntegration(),
            Sentry.reactRouterV6BrowserTracingIntegration({
                useEffect: React.useEffect,
                useLocation,
                useNavigationType,
                createRoutesFromChildren,
                matchRoutes,
            }),
        ],
        ignoreErrors: ["Non-Error exception captured", "Non-Error promise rejection captured"],
        tracesSampleRate: 1,
    });
}

export type RootState = ReturnType<typeof reducer>;

const reducer = combineReducers({
    session: sessionReducer,
});

const validateSession = session => {
    if (!session) {
        return false;
    }

    const date = new Date(session.expires);

    if (date < new Date()) {
        return false;
    }

    return true;
};
const store = createStore(reducer);

const options = {
    redirectPath: "/",
    refreshOnCheckAuth: true,
    validateSession,
    driver: "INDEXEDDB",
    expires: 9999999999,
} as SessionServiceOptions;

sessionService.initSessionService(store, options).then(() => {
    ReactDOM.render(
        <Provider store={store}>
            <React.StrictMode>
                <App />
            </React.StrictMode>
        </Provider>,
        document.getElementById("root")
    );
});

const update = () => {
    window.alert("New version is available. Click OK to reload");

    if (caches) {
        // Service worker cache should be cleared with caches.delete()
        caches.keys().then(function (names) {
            for (const name of names) caches.delete(name);
        });
    }
    // delete browser cache and hard reload
    window.location.reload();
};

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register({
    onUpdate: update,
});

reportWebVitals();
