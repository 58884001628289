import { Loading } from "@g3r-developers/g3-common";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Col, ListGroup, ListGroupItem, Row, Table } from "reactstrap";
import inspectionDamageStore from "Services/DbStores/InspectionDamageStore";
import inspectionSplatComponentStore from "Services/DbStores/InspectionSplatComponentStore";
import { InspectionDamageItem } from "Types/Inspection/InspectionDamageItem";
import { ComponentModel } from "Types/Inspection/NamaSplatOverlayItemModel";
import { OfflineInspectionDetailModel } from "Types/Inspection/OfflineInspectionDetailModel";

interface DamageRow {
    component: string;
    cost: number;
    damage: string;
    location: string;
    points: number;
    repairMethod: string;
    severity: string;
}

interface Calculated {
    cost: number;
    grade: string;
    gradeClass: string;
    nextGrade: string;
    points: number;
    pointsToNextGrade: string;
    showZeroDamageWarning: boolean;
}

interface DamageSummaryProps {
    inspectionDetail: OfflineInspectionDetailModel;
}

export default function DamageSummary({ inspectionDetail }: DamageSummaryProps) {
    const [components, setComponents] = useState<ComponentModel[]>([]);
    const [damages, setDamages] = useState<InspectionDamageItem[]>([]);
    const [loaded, setLoaded] = useState<boolean>(false);

    const damageRows: DamageRow[] = useMemo(
        () =>
            damages
                .map(d => {
                    const component = components.find(c => c.id === d.componentId);
                    const damage = component?.severities?.find(s => s.id === d.damageId);
                    const repairMethod = component?.repairMethods?.find(rm => rm.id === d.repairMethodId);
                    const matrix = inspectionDetail.matrices.find(
                        m => m.namaComponentId === component?.id && m.repairMethodId === repairMethod?.id
                    );
                    const severity = damage?.choiceGroup?.choiceItems?.find(s => s.id === d.severityId);
                    const splat = inspectionDetail.splats.find(s => s.id === d.splatId);

                    return {
                        component: component?.componentName,
                        cost: matrix?.cost,
                        damage: damage?.questionText,
                        location: splat?.locationContext?.display,
                        points: matrix?.repairScore,
                        repairMethod: repairMethod?.repairMethod,
                        severity: severity?.displayName,
                    };
                })
                .sort(
                    (a, b) =>
                        (b.location ?? "").localeCompare(a.location) || (a.component ?? "").localeCompare(b.component)
                ),
        [components, damages, inspectionDetail]
    );

    const calculated: Calculated = useMemo(() => {
        const points = damageRows.reduce((prev, curr) => (prev += curr?.points ?? 0), 0);
        const scorings =
            inspectionDetail.scorings?.filter(
                s => s.location?.reference === "interior" && s.scoreType?.reference === "points"
            ) ?? [];
        const scoring = scorings.find(s => points >= s.lowValue && points <= s.highValue);
        const nextScoring = [...scorings]
            .sort((a, b) => a.lowValue - b.lowValue)
            .find(s => s.lowValue > scoring.highValue);

        const grade = scoring?.inspectionProfileScoreValue?.scoreValue ?? "TBC";
        const gradeClass = grade === "TBC" ? "" : grade === "1" ? "text-danger" : `text-grade-${grade}`;

        return {
            cost: damageRows.reduce((prev, curr) => (prev += curr?.cost ?? 0), 0),
            grade: grade,
            gradeClass: gradeClass,
            nextGrade: nextScoring?.inspectionProfileScoreValue?.scoreValue ?? "TBC",
            points: points,
            pointsToNextGrade: nextScoring ? (nextScoring.lowValue - points).toString() : "Unknown",
            showZeroDamageWarning: points === 0,
        };
    }, [damageRows, inspectionDetail]);

    const loadData = useCallback(async (inspection: OfflineInspectionDetailModel) => {
        setLoaded(false);

        const dmgs = await inspectionDamageStore.getDamages(inspection.inspectionId);

        const mapped = dmgs.flatMap(d => d.damages);

        setDamages(mapped);

        let comps: ComponentModel[] = [];

        for (const splat of inspection.splats) {
            // eslint-disable-next-line no-await-in-loop
            const splatComps = await inspectionSplatComponentStore.load(splat.id);
            comps = comps.concat(splatComps.map(c => c.component));
        }

        setComponents(comps);

        setLoaded(true);
    }, []);

    const moneyFormatter = useMemo(
        () =>
            new Intl.NumberFormat("en-GB", {
                style: "currency",
                currency: "GBP",
            }),
        []
    );

    useEffect(() => {
        loadData(inspectionDetail);
    }, [inspectionDetail, loadData]);

    return (
        <>
            <h2>Damage Summary</h2>

            {loaded ? (
                <Row>
                    <Col md={9}>
                        <Table striped>
                            <thead>
                                <tr>
                                    <th>Location</th>

                                    <th>Component</th>

                                    <th>Damage</th>

                                    <th>Severity</th>

                                    <th>Repair Method</th>

                                    <th>Points</th>

                                    <th>Cost</th>
                                </tr>
                            </thead>

                            <tbody>
                                {damageRows.map(dr => (
                                    <tr>
                                        <td>{dr.location}</td>

                                        <td>{dr.component}</td>

                                        <td>{dr.damage}</td>

                                        <td>{dr.severity}</td>

                                        <td>{dr.repairMethod}</td>

                                        <td>{dr.points}</td>

                                        <td>{moneyFormatter.format(dr.cost)}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </Col>

                    <Col md={3}>
                        <ListGroup>
                            <ListGroupItem>
                                <Row className={"mb-3"}>
                                    <Col xs={"auto"}>
                                        <h5>Total Points</h5>
                                    </Col>
                                </Row>

                                <Row className={"justify-content-center"}>
                                    <Col xs={"auto"}>
                                        <h3>{calculated.points}</h3>
                                    </Col>
                                </Row>

                                <Row className={"justify-content-center"}>
                                    <Col xs={"auto"}>
                                        <small className={"fw-bold text-muted"}>
                                            {calculated.pointsToNextGrade} Points to Next Grade ({calculated.nextGrade})
                                        </small>
                                    </Col>
                                </Row>
                            </ListGroupItem>

                            <ListGroupItem>
                                <Row className={"mb-3"}>
                                    <Col xs={"auto"}>
                                        <h5>Total Cost</h5>
                                    </Col>
                                </Row>

                                <Row className={"justify-content-center"}>
                                    <Col xs={"auto"}>
                                        <h3>{moneyFormatter.format(calculated.cost)}</h3>
                                    </Col>
                                </Row>
                            </ListGroupItem>

                            <ListGroupItem>
                                <Row className={"mb-3"}>
                                    <Col xs={"auto"}>
                                        <h5>Nama Grade</h5>
                                    </Col>
                                </Row>

                                <Row className={"justify-content-center"}>
                                    <Col xs={"auto"}>
                                        <h3 className={calculated.gradeClass}>{calculated.grade}</h3>
                                    </Col>
                                </Row>

                                {calculated.showZeroDamageWarning && (
                                    <Row className={"justify-content-center"}>
                                        <Col xs={"auto"}>
                                            <small className={"fw-bold text-danger"}>Zero Damage - are you sure?</small>
                                        </Col>
                                    </Row>
                                )}
                            </ListGroupItem>
                        </ListGroup>
                    </Col>
                </Row>
            ) : (
                <Loading />
            )}
        </>
    );
}
