import { IDBPDatabase, openDB } from "idb";

class IndexedDb {
    private database: string;
    private db: IDBPDatabase;

    constructor(database: string) {
        this.database = database;
    }

    public async createObjectStore(tableName: string[], keyPath: string[]): Promise<boolean> {
        try {
            this.db = await openDB(this.database, 4, {
                upgrade(db: IDBPDatabase) {
                    for (let i = 0; i < tableName.length; i++) {
                        const name = tableName[i];
                        const path = keyPath[i];
                        if (db.objectStoreNames.contains(name)) {
                            continue;
                        }
                        db.createObjectStore(name, { keyPath: path });
                    }
                },
            });
        } catch (error) {
            return false;
        }
    }

    public async getValue<T>(tableName: string, id: string): Promise<T> {
        const tx = this.db.transaction(tableName, "readonly");
        const store = tx.objectStore(tableName);
        const result = await store.get(id);
        return result;
    }

    public async getValueById(tableName: string, inspectionId: string) {
        const tx = this.db.transaction(tableName, "readonly");
        const store = tx.objectStore(tableName);
        const result = await store.get;
        return result;
    }

    public async getAllValue(tableName: string) {
        const tx = this.db.transaction(tableName, "readonly");
        const store = tx.objectStore(tableName);
        const result = await store.getAll();
        return result;
    }

    public async putValue(tableName: string, value: unknown) {
        const tx = this.db.transaction(tableName, "readwrite");
        const store = tx.objectStore(tableName);
        const result = await store.put(value);
        return result;
    }

    public async putBulkValue(tableName: string, values: unknown[]) {
        const tx = this.db.transaction(tableName, "readwrite");
        const store = tx.objectStore(tableName);

        values.forEach(async value => {
            await store.put(value);
        });
        return this.getAllValue(tableName);
    }

    public async deleteValue(tableName: string, id: string) {
        const tx = this.db.transaction(tableName, "readwrite");
        const store = tx.objectStore(tableName);
        const result = await store.get(id);
        if (!result) {
            return result;
        }
        await store.delete(id);
        return id;
    }
}

export default IndexedDb;
