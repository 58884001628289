import { IconButton, Loading } from "@g3r-developers/g3-common";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Col, Container, Row } from "reactstrap";
import inspectionAssetStore from "Services/DbStores/InspectionAssetStore";
import inspectionDetailStore from "Services/DbStores/InspectionDetailStore";
import inspectionService from "Services/InspectionService";
import { InspectionModel } from "Types/Inspection/InspectionModel";

interface ConfirmInspectionLiteProps {
    inspectionId: string;
}

export default function ConfirmInspectionLite({ inspectionId }: ConfirmInspectionLiteProps) {
    const [initialInspectionState, setInitialInspectionState] = useState<InspectionModel>(undefined);
    const [inspection, setInspection] = useState<InspectionModel>(undefined);
    const [isDownloading, setIsDownloading] = useState<boolean>(false);
    const [loaded, setLoaded] = useState<boolean>(false);

    const navigate = useNavigate();

    const fullDescription = useMemo(() => {
        if (!inspection) {
            return "";
        }

        return `${inspection.vehicleManufacturer.name} ${inspection.vehicleModel.model} ${inspection.vehicleType.display}`;
    }, [inspection]);

    const loadInspection = useCallback(async (inspectionId: string) => {
        try {
            let inspection: InspectionModel = undefined;

            const storedInspectionDetail = await inspectionDetailStore.load(inspectionId);

            if (storedInspectionDetail) {
                inspection = storedInspectionDetail.inspection;
            } else {
                inspection = await inspectionService.getById(inspectionId);
            }

            setInspection(inspection);
            setInitialInspectionState(inspection);
        } catch (e) {
            alert("There was an issue retrieving your Inspection. Please try again");
        } finally {
            setLoaded(true);
        }
    }, []);

    const getAndSaveInspectionToDevice = useCallback(
        async (inspectionId: string) => {
            const inspectionDetail = await inspectionService.start(inspectionId);

            await inspectionService.storeDetail(inspectionDetail);

            await loadInspection(inspectionId);
        },
        [loadInspection]
    );

    const inspectionHasChanges = useMemo(() => {
        if (!initialInspectionState || !inspection) {
            return false;
        }

        const propertyChecks = Object.keys(inspection).map(k => inspection[k] === initialInspectionState[k]);
        const hasChanges = !propertyChecks.every(check => check);
        return hasChanges;
    }, [initialInspectionState, inspection]);

    const handleStart = useCallback(async () => {
        try {
            setIsDownloading(true);

            if (inspectionHasChanges) {
                await inspectionService.update(inspectionId, inspection);
            }

            await getAndSaveInspectionToDevice(inspectionId);

            await inspectionAssetStore.storeGenericAssetsIfRequired();

            setIsDownloading(false);

            navigate(`/inspection/${inspectionId}`, { replace: true });
        } catch (e) {
            alert("There was an issue when starting your Inspection. Please try again");
        }
    }, [getAndSaveInspectionToDevice, navigate, inspection, inspectionHasChanges, inspectionId]);

    const confirmBtn = useMemo(() => {
        return isDownloading ? (
            <>
                <Loading size={"sm"} />
                <span>Getting Everything Ready...</span>
            </>
        ) : (
            <IconButton block text="Begin Inspection" onClick={handleStart} variant={"primary"} size={"lg"} />
        );
    }, [handleStart, isDownloading]);

    useEffect(() => {
        loadInspection(inspectionId);
    }, [inspectionId, loadInspection]);

    return (
        <>
            {loaded ? (
                <>
                    <Container className="p-3 text-center h-100">
                        {!isDownloading ? (
                            <>
                                <Row>
                                    <Col>
                                        <img
                                            src="https://media.g3r.co.uk/signal/signal-logo.png"
                                            alt="Signal"
                                            className={"logo"}
                                        />
                                    </Col>
                                </Row>
                                <hr />
                                <Row className="my-1">
                                    <Col className="text-center text-bold text-0-8">
                                        We'd like to collect some information on the condition of your vehicle by taking
                                        some photos
                                    </Col>
                                </Row>
                                <Row className="my-2">
                                    <Col sm={3} />
                                    <Col>
                                        <span className="form-control bg-warning">
                                            <h3 className="text-bold mb-0">{inspection?.vrm}</h3>
                                        </span>
                                    </Col>
                                    <Col sm={3} />
                                </Row>
                                <Row className="my-1">
                                    <Col>
                                        <h3 className="text-primary">{fullDescription}</h3>
                                    </Col>
                                </Row>
                            </>
                        ) : (
                            <></>
                        )}
                        <Row className="mt-2 px-4 py-2">
                            <Col xs={12}>{confirmBtn}</Col>
                        </Row>
                    </Container>
                </>
            ) : (
                <Loading text="Loading Inspection Details" />
            )}
        </>
    );
}
