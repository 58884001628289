import { MediaType } from "Types/Enums/MediaType";
import { InspectionAsset, InspectionQuestionSection } from "Types/Inspection/InspectionConfig";
import { SectionModel } from "Types/Inspection/SectionModel";
import { QuestionGroupModel } from "Types/InspectionStaging/QuestionGroupModel";
import { QuestionItemModel } from "Types/InspectionStaging/QuestionItemModel";
import { QuestionAnswerModel } from "Types/Question/QuestionAnswerModel";
import { DB_NAMES, initDb } from "../QuestionStore";
import inspectionAssetStore from "./InspectionAssetStore";

const getSectionFromSection = async (inspectionId: string, section: SectionModel): Promise<QuestionAnswerModel[]> => {
    let sections = await loadInspectionSections(inspectionId);

    if (!sections) {
        saveInspectionSections(inspectionId, [
            {
                questionSectionId: section.id,
                questionItemModels: [],
                questionGroupText: section.name,
            } as QuestionGroupModel,
        ]);

        sections = await loadInspectionSections(inspectionId);
    }

    let savedSection = sections.find(s => s.questionSectionId === section.id);

    if (!savedSection) {
        savedSection = {
            questionSectionId: section.id,
            questionItemModels: [],
            questionGroupText: section.name,
        } as QuestionGroupModel;

        sections.push(savedSection);

        await saveInspectionSections(inspectionId, sections);
    }

    return section.questions?.map(q => {
        const value = savedSection.questionItemModels.find(a => a.questionId === q.id)?.actualValue;
        const asset = savedSection.questionItemModels.find(a => a.questionId === q.id)?.assetId;

        return {
            ...q,
            answer: value,
            asset: asset,
            sectionId: section.id,
        } as QuestionAnswerModel;
    });
};

const loadInspectionSections = async (inspectionId: string): Promise<QuestionGroupModel[]> => {
    const db = await initDb();
    const sectionModel = (await db.getValue(
        DB_NAMES.INSPECTION_SECTION,
        `inspection-sections-${inspectionId}`
    )) as InspectionQuestionSection;

    const sections = sectionModel?.sections;

    return Array.isArray(sections) ? sections : [];
};

const remove = async (inspectionId: string): Promise<void> => {
    const db = await initDb();
    await db.deleteValue(DB_NAMES.INSPECTION_SECTION, `inspection-sections-${inspectionId}`);
};

const saveInspectionSections = async (inspectionId: string, sections: QuestionGroupModel[]): Promise<IDBValidKey> => {
    const db = await initDb();
    return await db.putValue(DB_NAMES.INSPECTION_SECTION, {
        inspectionSectionId: `inspection-sections-${inspectionId}`,
        sections: sections,
    } as InspectionQuestionSection);
};

export interface UpdateInspectionSectionAnswerModel {
    inspectionId: string;
    sectionId: string;
    questionId: string;
    answer: string;
    textValue: string;
    asset?: string;
    assetMediaType?: MediaType;
    assetOnlyUpdate?: boolean;
}

const updateInspectionSectionAnswer = async (answer: UpdateInspectionSectionAnswerModel): Promise<void> => {
    const sections = await loadInspectionSections(answer.inspectionId);
    const savedSectionIdx = sections.findIndex(s => s.questionSectionId === answer.sectionId);

    if (savedSectionIdx === -1) {
        return;
    }
    const idx = sections[savedSectionIdx].questionItemModels.findIndex(a => a.questionId === answer.questionId);

    let assetId = undefined;

    if (answer.asset) {
        assetId = await inspectionAssetStore.storeAsset({
            asset: answer.asset,
            mediaType: answer.assetMediaType,
        } as InspectionAsset);
    }

    if (idx === -1) {
        sections[savedSectionIdx].questionItemModels.push({
            actualValue: answer.answer,
            textValue: answer.textValue,
            questionId: answer.questionId,
            assetId: assetId,
            assetMediaType: answer.assetMediaType,
        } as QuestionItemModel);
    } else {
        if (answer.assetOnlyUpdate) {
            sections[savedSectionIdx].questionItemModels[idx] = {
                ...sections[savedSectionIdx].questionItemModels[idx],
                assetId: assetId,
                assetMediaType: answer.assetMediaType,
            } as QuestionItemModel;
        } else {
            sections[savedSectionIdx].questionItemModels[idx] = {
                questionId: answer.questionId,
                actualValue: answer.answer,
                textValue: answer.textValue,
                assetId: assetId,
                assetMediaType: answer.assetMediaType,
            } as QuestionItemModel;
        }
    }

    await saveInspectionSections(answer.inspectionId, sections);
};

const inspectionSectionStore = {
    getSectionFromSection,
    loadInspectionSections,
    remove,
    saveInspectionSections,
    updateInspectionSectionAnswer,
};

export default inspectionSectionStore;
