import { G3DataTable } from "@g3r-developers/g3-common";
import InspectionSyncTableActionCell from "Components/Inspection/InspectionSyncTableActionCell";
import { DateTime } from "luxon";
import { useCallback, useMemo, useState } from "react";
import inspectionDetailStore from "Services/DbStores/InspectionDetailStore";
import { OfflineInspectionDetailModel } from "Types/Inspection/OfflineInspectionDetailModel";

export default function InspectionSyncTable() {
    const [filters, setFilters] = useState<Record<string, never>>({});

    const reload = useCallback(() => setFilters({}), []);

    const columns = useMemo(
        () => [
            {
                selector: (row: OfflineInspectionDetailModel) => row.inspection.vrm,
                name: "VRM",
            },
            {
                selector: (row: OfflineInspectionDetailModel) => row.inspection.vehicleManufacturer.name,
                name: "Make",
            },
            {
                selector: (row: OfflineInspectionDetailModel) => row.inspection.vehicleModel.model,
                name: "Model",
            },
            {
                selector: (row: OfflineInspectionDetailModel) => row.inspection.vehicleType.display,
                name: "Vehicle Type",
            },
            {
                selector: (row: OfflineInspectionDetailModel) =>
                    DateTime.fromISO(row.inspection.dateCreated).toLocaleString(),
                name: "Date Requested",
            },
            {
                selector: (row: OfflineInspectionDetailModel) => row.inspection.status.display,
                name: "Status",
            },
            {
                cell: (row: OfflineInspectionDetailModel) => (
                    <InspectionSyncTableActionCell reload={reload} row={row} />
                ),
            },
        ],
        [reload]
    );

    const loadInspectionDetails = useCallback(async _paging => {
        const inspectionDetails = await inspectionDetailStore.loadAll();

        return {
            count: inspectionDetails.length,
            data: inspectionDetails,
        };
    }, []);

    return (
        <>
            <G3DataTable
                columns={columns}
                filters={filters}
                load={data => loadInspectionDetails(data)}
                pagination={false}
            />
        </>
    );
}
