import { IconButton } from "@g3r-developers/g3-common";
import { useCallback, useState } from "react";
import { OfflineInspectionDetailModel } from "Types/Inspection/OfflineInspectionDetailModel";
import InspectionNotesModal from "./InspectionNotesModal";

interface InspectionNotesProps {
    inspection: OfflineInspectionDetailModel;
    updateInspectionDetail(inspection: OfflineInspectionDetailModel): void;
}

export default function InspectionNotes({ inspection, updateInspectionDetail }: InspectionNotesProps) {
    const [showNotesModal, setShowNotesModal] = useState<boolean>(false);

    const toggleNotesModal = useCallback(() => {
        setShowNotesModal(!showNotesModal);
    }, [showNotesModal, setShowNotesModal]);

    return (
        <>
            <IconButton
                text="Add Notes"
                faIconString="fa fa-sticky-note"
                onClick={toggleNotesModal}
                className="me-1 ms-1"
            />
            {showNotesModal && (
                <InspectionNotesModal
                    inspection={inspection}
                    toggle={toggleNotesModal}
                    updateInspectionDetail={updateInspectionDetail}
                />
            )}
        </>
    );
}
