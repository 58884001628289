/* eslint-disable no-restricted-globals */
import IncorrectOrientation from "Components/IncorrectOrientation";
import { useCallback, useEffect } from "react";
import { connect } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import DeviceOrientation, { Orientation } from "react-screen-orientation";
import Index from "Routes/Index";
import questionStore from "Services/QuestionStore";

export interface AppProps {
    authenticated: boolean;
    checked?: boolean;
    session?: any;
}

function App() {
    const renderApp = useCallback(
        () => (
            <Router>
                <Index />
            </Router>
        ),
        []
    );

    useEffect(() => {
        questionStore.initDb();
    }, []);

    return (
        <>
            <DeviceOrientation lockOrientation={"landscape"}>
                <Orientation orientation="landscape" alwaysRender={false}>
                    {renderApp()}
                </Orientation>
                <Orientation orientation="portrait" alwaysRender={false}>
                    <IncorrectOrientation />
                </Orientation>
            </DeviceOrientation>
        </>
    );
}

const mapState = ({ session }) => ({
    authenticated: session.authenticated,
    checked: session.checked,
    session: session,
});

export default connect(mapState)(App);
