import { IconButton, LoadingModal, showconfirmmodal, showerrormodal } from "@g3r-developers/g3-common";
import { useCallback, useMemo, useState } from "react";
import { Col, Row } from "reactstrap";
import inspectionStagingService from "Services/InspectionStagingService";
import { InspectionStatus } from "Types/Enums/InspectionStatus";
import { OfflineInspectionDetailModel } from "Types/Inspection/OfflineInspectionDetailModel";

interface InspectionSyncTableAction {
    element: JSX.Element;
    key: string;
}

interface InspectionSyncTableActionCellProps {
    reload(): void;
    row: OfflineInspectionDetailModel;
}

export default function InspectionSyncTableActionCell({ reload, row }: InspectionSyncTableActionCellProps) {
    const [loading, setLoading] = useState(false);
    const [loadingTxt, setLoadingTxt] = useState("Uploading Inspection");
    const canSync = useMemo(() => row.inspection.status.id === InspectionStatus.Completed, [row]);

    const handleInspectionSync = useCallback(async () => {
        setLoading(true);

        try {
            await inspectionStagingService.completeInspection(row.inspection.id, setLoadingTxt);
            // IF signal, redirect to signal portal
            // HARDCODED for now
            if (row.inspection.customerId === "E3428AED-49C9-4BA7-8663-24C3FDCD405C") {
                window.location.href = "https://app.g3signal.co.uk/appraisal-portal";
            }
            reload();
            setLoading(false);
        } catch (e) {
            await showerrormodal(e.message);
        } finally {
            setLoading(false);
        }
    }, [reload, row, setLoading]);

    const handleDelete = useCallback(async () => {
        const confirm = await showconfirmmodal(
            "Confirm Delete",
            "Are you sure you want to delete this inspection? It will not be synced and the inspection will be lost"
        );

        if (!confirm) {
            return;
        }

        await inspectionStagingService.clearDownInspection(row.inspection.id);
        reload();
    }, [reload, row.inspection.id]);

    const actions: InspectionSyncTableAction[] = useMemo(
        () => [
            {
                element: (
                    <IconButton
                        faIconString={"fas fa-search"}
                        url={`/inspection/${row.inspection.id}`}
                        variant={"secondary"}
                    />
                ),
                key: "go-to-inspection",
            },
            {
                element: (
                    <IconButton
                        disabled={!canSync}
                        faIconString={"fas fa-cloud-upload-alt"}
                        onClick={handleInspectionSync}
                        variant={canSync ? "success" : "secondary"}
                    />
                ),
                key: "complete-inspection",
            },
            {
                element: <IconButton faIconString={"fas fa-trash-alt"} onClick={handleDelete} variant={"primary"} />,
                key: "delete-inspection",
            },
        ],
        [canSync, handleDelete, handleInspectionSync, row.inspection.id]
    );

    return (
        <Row>
            {actions.map(a => (
                <Col className={"px-1"} key={a.key} xs={"auto"}>
                    {a.element}
                </Col>
            ))}
            {loading && <LoadingModal message={loadingTxt} show size={"md"} />}
        </Row>
    );
}
