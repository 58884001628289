import { useCallback, useEffect, useMemo, useState } from "react";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import { SectionNames } from "Types/Inspection/Enums/SectionNames";
import { InspectionProfileMatrixModel } from "Types/Inspection/InspectionProfileMatrixModel";
import { OfflineInspectionDetailModel } from "Types/Inspection/OfflineInspectionDetailModel";
import { OfflineSplatModel } from "Types/Inspection/OfflineSplatModel";
import { DamageSplatTab } from "./DamageSplatTab";

export interface DamageSectionProps {
    displayedTabIndex: number;
    setDisplayedTabIndex(index: number): void;
    handleSectionComplete(index: number, state: boolean): void;
    inspectionDetail: OfflineInspectionDetailModel;
    inspectionId: string;
    sectionIndex: number;
    splats: OfflineSplatModel[];
    matrices: InspectionProfileMatrixModel[];
}

export function DamageSection({
    displayedTabIndex,
    setDisplayedTabIndex,
    handleSectionComplete,
    inspectionDetail,
    inspectionId,
    sectionIndex,
    splats,
    matrices,
}: DamageSectionProps) {
    const [damageSectionComplete, setDamageSectionComplete] = useState<boolean>(false);
    const [tyreSectionComplete, setTyreSectionComplete] = useState<boolean>(false);

    useEffect(() => {
        handleSectionComplete(sectionIndex, damageSectionComplete && tyreSectionComplete);
    }, [damageSectionComplete, handleSectionComplete, sectionIndex, tyreSectionComplete]);

    const tyreSection = useMemo(
        () =>
            inspectionDetail.sections.find(
                s =>
                    s.name === SectionNames.SignalTyre ||
                    s.name === SectionNames.AssuredTyre ||
                    s.name === SectionNames.Tyre
            ),
        [inspectionDetail.sections]
    );

    const btnClass = useCallback(
        idx => {
            return idx === displayedTabIndex ? "btn btn-danger col-12" : "btn btn-secondary col-12";
        },
        [displayedTabIndex]
    );

    return (
        <>
            <Nav tabs className="row">
                {splats.map((s, idx) => (
                    <NavLink key={s.id} className="col-md-6">
                        <NavItem className={btnClass(idx)} onClick={() => setDisplayedTabIndex(idx)}>
                            {s.locationContext.display}
                        </NavItem>
                    </NavLink>
                ))}
            </Nav>
            <TabContent activeTab={displayedTabIndex}>
                {splats.map((s, idx) => (
                    <TabPane key={s.id} tabId={idx}>
                        <DamageSplatTab
                            matrices={matrices}
                            inspectionId={inspectionId}
                            splat={s}
                            tyreSection={tyreSection}
                            idx={sectionIndex}
                            handleDamageSectionComplete={setDamageSectionComplete}
                            handleTyreSectionComplete={setTyreSectionComplete}
                        />
                    </TabPane>
                ))}
            </TabContent>
        </>
    );
}
