import inspectionStatuses from "Constants/InspectionStatuses";
import utils from "Services/Utils";
import { InspectionStatus } from "Types/Enums/InspectionStatus";
import { InspectionDetailModel } from "Types/Inspection/InspectionDetailModel";
import { OfflineInspectionDetailModel } from "Types/Inspection/OfflineInspectionDetailModel";
import { OfflineMarketingImageModel, OfflineSplatModel } from "Types/Inspection/OfflineSplatModel";
import { DB_NAMES, initDb } from "../QuestionStore";

const load = async (inspectionId: string): Promise<OfflineInspectionDetailModel> => {
    const db = await initDb();
    const value = await db.getValue<OfflineInspectionDetailModel>(DB_NAMES.INSPECTION_DETAIL, inspectionId);

    return value;
};

const loadAll = async (): Promise<OfflineInspectionDetailModel[]> => {
    const db = await initDb();
    const values = await db.getAllValue(DB_NAMES.INSPECTION_DETAIL);
    return values ?? [];
};

const remove = async (inspectionId: string): Promise<void> => {
    const db = await initDb();
    await db.deleteValue(DB_NAMES.INSPECTION_DETAIL, inspectionId);
};

const save = async (inspectionDetail: InspectionDetailModel): Promise<void> => {
    const splatsToStore = inspectionDetail.splats.map(async s => {
        const imageUrl = await utils.getOfflineImageUrl(s.downloadDocumentUrl);
        const offlineSplat: OfflineSplatModel = { ...s, offlineUrl: imageUrl };
        return offlineSplat;
    });

    const section = inspectionDetail.sections.find(s => s.sectionType.reference === "MarketingImages");

    let placeholdersToStore = undefined;

    if (section) {
        placeholdersToStore = section.questions?.map(async q => {
            const img = `https://media.g3r.co.uk/splats/placeholders/${q.id.toUpperCase()}`;
            const imageUrl = await utils.getOfflineImageUrl(img);

            return {
                id: q.id,
                offlineUrl: imageUrl,
            } as OfflineMarketingImageModel;
        });
    }

    const inspectionDetailToStore: OfflineInspectionDetailModel = {
        ...inspectionDetail,
        inspectionId: inspectionDetail.inspection.id,
        splats: await Promise.all(splatsToStore),
    };

    if (placeholdersToStore) {
        inspectionDetailToStore.marketingImages = await Promise.all(placeholdersToStore);
    }

    const db = await initDb();
    await db.putValue(DB_NAMES.INSPECTION_DETAIL, inspectionDetailToStore);
};

const updateStatus = async (inspectionId: string, status: InspectionStatus): Promise<OfflineInspectionDetailModel> => {
    const db = await initDb();

    const inspectionDetail = await db.getValue<OfflineInspectionDetailModel>(DB_NAMES.INSPECTION_DETAIL, inspectionId);

    if (!inspectionDetail) {
        return undefined;
    }

    const inspectionStatus = Object.values(inspectionStatuses).find(s => s.id === status);

    if (!inspectionStatus) {
        return inspectionDetail;
    }

    inspectionDetail.inspection.status = inspectionStatus;

    await db.putValue(DB_NAMES.INSPECTION_DETAIL, inspectionDetail);

    return inspectionDetail;
};

const inspectionDetailStore = {
    load,
    loadAll,
    remove,
    save,
    updateStatus,
};

export default inspectionDetailStore;
