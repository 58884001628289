import { Loading } from "@g3r-developers/g3-common";
import InspectionSidebar from "Components/Inspection/InspectionSidebar";
import inspectionDetailStore from "Services/DbStores/InspectionDetailStore";
import { InspectionStatus } from "Types/Enums/InspectionStatus";
import { InspectionType } from "Types/Enums/InspectionType";
import { SectionNames } from "Types/Inspection/Enums/SectionNames";
import { OfflineInspectionDetailModel } from "Types/Inspection/OfflineInspectionDetailModel";
import { DateTime } from "luxon";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Col, Row, TabContent } from "reactstrap";
import { useImmer } from "use-immer";
import { InspectionSectionTab } from "../Components/Inspection/InspectionSectionTab";

export interface DropdownModel {
    label: string;
    value: string;
}

export default function Inspection(): JSX.Element {
    const { inspectionId } = useParams<{
        inspectionId?: string;
    }>();

    const [activeSection, setActiveSection] = useState<number>(0);
    const [inspectionDetail, setInspectionDetail] = useState<OfflineInspectionDetailModel>(undefined);
    const [loaded, setLoaded] = useState<boolean>(false);
    const [sectionState, setSectionState] = useImmer<boolean[]>([]);

    const navigate = useNavigate();

    const loadInspectionDetail = useCallback(async (inspectionId: string) => {
        let storedInspectionDetail = await inspectionDetailStore.load(inspectionId);

        if (!storedInspectionDetail) {
            throw new Error("This inspection has not been saved to the device for offline use");
        }

        if (storedInspectionDetail.inspection.status.id === InspectionStatus.Pending) {
            storedInspectionDetail = await inspectionDetailStore.updateStatus(
                inspectionId,
                InspectionStatus.InProgress
            );

            if (!storedInspectionDetail?.inspection?.inspectionStartedTimeStamp) {
                storedInspectionDetail.inspection.inspectionStartedTimeStamp = DateTime.now().toISO();
                await inspectionDetailStore.save(storedInspectionDetail);
            }
        }
        setInspectionDetail(storedInspectionDetail);
    }, []);

    const updateInspectionDetail = useCallback(
        (inpection: OfflineInspectionDetailModel) => {
            setInspectionDetail(inpection);
        },
        [setInspectionDetail]
    );

    const loadData = useCallback(
        async (inspectionId: string) => {
            try {
                await loadInspectionDetail(inspectionId);
                setLoaded(true);
            } catch (e) {
                alert(e.message);
                navigate("/");
            }
        },
        [navigate, loadInspectionDetail]
    );

    useEffect(() => {
        loadData(inspectionId);
    }, [inspectionId, loadData]);

    const displaySections = useMemo(
        () =>
            inspectionDetail?.sections?.filter(
                s =>
                    s.name !== SectionNames.SignalTyre &&
                    s.name !== SectionNames.Tyre &&
                    s.name !== SectionNames.AssuredTyre &&
                    s.name !== SectionNames.Audio
            ),
        [inspectionDetail?.sections]
    );

    useEffect(() => setSectionState(displaySections?.map(() => false) ?? []), [displaySections, setSectionState]);

    const handleSectionComplete = useCallback(
        (index: number, result: boolean) => {
            setSectionState(draft => {
                draft[index] = result;
            });
        },
        [setSectionState]
    );

    const handleSetActiveSection = useCallback(
        (sectionName: string) => {
            if (!inspectionDetail) {
                return;
            }

            const section = displaySections.findIndex(s => s.name === sectionName);
            setActiveSection(section);
        },
        [displaySections, inspectionDetail]
    );

    const isInspectionComplete = useMemo(() => {
        return sectionState.every(s => s === true);
    }, [sectionState]);

    const nextSectionAvailable = useMemo(() => {
        if (!inspectionDetail) {
            return false;
        }

        return activeSection < displaySections.length - 1;
    }, [activeSection, displaySections, inspectionDetail]);

    const handleNextActiveSection = useCallback(() => {
        if (nextSectionAvailable) {
            setActiveSection(p => p + 1);
        }
    }, [nextSectionAvailable]);

    const showSidebar = useMemo(() => {
        return inspectionDetail?.inspection?.inspectionTypeId !== InspectionType.SignalLite;
    }, [inspectionDetail?.inspection?.inspectionTypeId]);

    const active = useMemo(() => {
        if (!displaySections) {
            return undefined;
        }
        return displaySections[activeSection];
    }, [activeSection, displaySections]);

    return (
        <>
            {loaded ? (
                <>
                    {showSidebar && (
                        <InspectionSidebar
                            activeSection={activeSection}
                            inspection={inspectionDetail.inspection}
                            isInspectionComplete={isInspectionComplete}
                            isSectionComplete={sectionState}
                            sections={displaySections}
                            setActiveSection={handleSetActiveSection}
                        />
                    )}
                    <div className={`${showSidebar ? "page-wrapper" : "ps-5 pe-5"} ps-3 pe-3`}>
                        <TabContent activeTab={activeSection}>
                            <InspectionSectionTab
                                key={active.id}
                                inspectionDetail={inspectionDetail}
                                section={active}
                                idx={activeSection}
                                isComplete={sectionState[activeSection]}
                                handleSectionComplete={handleSectionComplete}
                                next={handleNextActiveSection}
                                nextAvailable={nextSectionAvailable}
                                isInspectionComplete={isInspectionComplete}
                                updateInspectionDetail={updateInspectionDetail}
                                reloadInspectionDetail={loadInspectionDetail}
                            />
                        </TabContent>
                    </div>
                </>
            ) : (
                <Row className="mt-50 align-items-center align-self-center">
                    <Col>
                        <Loading text="Loading Inspection" />
                    </Col>
                </Row>
            )}
        </>
    );
}
