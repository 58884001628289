import { G3DataTable, G3Date, G3Dropdown, IconButton } from "@g3r-developers/g3-common";
import InspectionSyncTable from "Components/Inspection/InspectionSyncTable";
import { VehicleSearch } from "Components/Vehicle/VehicleSearch";
import { useTypedSearchParams } from "Hooks/UseTypedSearchParams";
import { DateTime } from "luxon";
import { useCallback, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { Col, Row } from "reactstrap";
import { sessionService } from "redux-react-session";
import dropdownService from "Services/DropdownService";
import inspectionService from "Services/InspectionService";
import ParameterUtils from "Services/ParameterUtils";
import { InspectionModel } from "Types/Inspection/InspectionModel";
import { InspectionSearchPagingModel } from "Types/Inspection/InspectionSearchPagingModel";

export default function Dashboard(): JSX.Element {
    const navigate = useNavigate();

    const logout = useCallback(() => {
        sessionService.deleteUser();
        sessionService.deleteSession();
        navigate("/");
    }, [navigate]);

    return (
        <>
            <Row className="mt-1">
                <Col className="text-end">
                    <IconButton
                        text="Settings"
                        className="me-2"
                        faIconString={"fas fa-cog"}
                        tooltip={"Settings"}
                        url={`/settings`}
                    />
                </Col>
                <Col xs="auto" className="text-end">
                    <IconButton
                        text="Log out"
                        className="me-2"
                        faIconString={"fas fa-sign-out-alt"}
                        tooltip={"Log out"}
                        onClick={logout}
                        variant="default"
                    />
                </Col>
            </Row>

            <Row>
                <Col className="text-center mb-3">
                    <h1 className="text-bold">AVID</h1>
                </Col>
            </Row>

            <VehicleSearch />

            <Row className={"mb-4 pt-4 gy-3"}>
                <Col xs={12} className={"text-center"}>
                    <h1>All Inspections</h1>
                </Col>
                <Col xs={12}>
                    <InspectionSearchTable />
                </Col>
            </Row>

            <Row className={"mb-4 pt-4 gy-3"}>
                <Col xs={12} className={"text-center"}>
                    <h1>Downloaded Inspections</h1>
                </Col>
                <Col xs={12}>
                    <InspectionSyncTable />
                </Col>
            </Row>
        </>
    );
}

const InspectionSearchTable = () => {
    const { searchParams, setSearchParams } = useTypedSearchParams<InspectionSearchPagingModel>();

    const filters = useMemo(() => {
        return {
            vendors: ParameterUtils.getStringArrayFromParams(searchParams, "vendors"),
            next_sale_date: ParameterUtils.tryGetParam(searchParams, "next_sale_date"),
        } as InspectionSearchPagingModel;
    }, [searchParams]);

    const changeUrl = useCallback(
        (newFilters: InspectionSearchPagingModel, reload = false) => {
            setSearchParams(ParameterUtils.getUrlParams(reload ? { ...newFilters, clear: true } : newFilters));
        },
        [setSearchParams]
    );

    const reload = useCallback(() => changeUrl(filters, true), [changeUrl, filters]);

    const columns = useMemo(
        () => [
            {
                selector: (row: InspectionModel) => row.vrm,
                name: "VRM",
            },
            {
                selector: (row: InspectionModel) => row.vehicleManufacturer.name,
                name: "Make",
            },
            {
                selector: (row: InspectionModel) => row.vehicleModel.model,
                name: "Model",
            },
            {
                selector: (row: InspectionModel) => row.vehicleType.display,
                name: "Vehicle Type",
            },
            {
                selector: (row: InspectionModel) => DateTime.fromISO(row.dateCreated).toLocaleString(),
                name: "Date Requested",
            },
            {
                selector: (row: InspectionModel) => row.vendor,
                name: "Vendor",
            },
            {
                selector: (row: InspectionModel) =>
                    row.dateInSale ? DateTime.fromISO(row.dateInSale).toFormat("dd/MM/yyyy HH:mm") : "N/A",
                name: "Next Sale Date",
            },
            {
                selector: (row: InspectionModel) => row.status.display,
                name: "Status",
            },
            {
                cell: (row: InspectionModel) => (
                    <IconButton
                        faIconString={"fas fa-list"}
                        url={`/inspection/confirm/${row.id}`}
                        variant="secondary"
                    />
                ),
            },
        ],
        []
    );
    const loadInspectionDetails = useCallback(async (data: any) => {
        const inspections = await inspectionService.getByStatus("pending", data);

        return inspections;
    }, []);

    return (
        <>
            <Row className="px-2 mb-3">
                <Col>
                    <G3Dropdown
                        defaultOption={filters.vendors}
                        isSearchable={false}
                        label="Vendor"
                        loadOptions={dropdownService.pendingInspectionVendors}
                        multiple
                        onChange={(val: string[]) => changeUrl({ ...filters, vendors: val })}
                    />
                </Col>
                <Col>
                    <G3Date
                        label="Next Sale Date"
                        onChange={(val: string) => changeUrl({ ...filters, next_sale_date: val })}
                        selectedDate={filters.next_sale_date}
                    />
                </Col>
            </Row>
            <G3DataTable
                columns={columns}
                filters={filters}
                load={data => loadInspectionDetails(data)}
                pagination={true}
            />
        </>
    );
};
