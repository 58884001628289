import { useEffect } from "react";
import { OfflineInspectionDetailModel } from "Types/Inspection/OfflineInspectionDetailModel";
import DamageSummary from "./DamageSummary";
import AssuredSummary from "../Question/AssuredSummary";
import { Col, Row } from "reactstrap";

interface SummarySectionProps {
    handleSectionComplete(index: number, state: boolean): void;
    inspectionDetail: OfflineInspectionDetailModel;
    sectionIndex: number;
}

export default function SummarySection({ handleSectionComplete, inspectionDetail, sectionIndex }: SummarySectionProps) {
    useEffect(() => {
        handleSectionComplete(sectionIndex, true);
    }, [handleSectionComplete, sectionIndex]);

    return (
        <>
            <Row className={"mb-4"}>
                <Col>
                    <DamageSummary inspectionDetail={inspectionDetail} />
                </Col>
            </Row>

            <Row>
                <Col>
                    <AssuredSummary inspection={inspectionDetail} />
                </Col>
            </Row>
        </>
    );
}
