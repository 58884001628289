import { sessionService } from "redux-react-session";
import { AuthenticationResponse } from "Types/Authentication/UserModel";

export const _delete = async <T>(url: string): Promise<T> => {
    const apiUrl = `${process.env.REACT_APP_API_URL}${url}`;

    const finalApi = new URL(apiUrl);

    const options = await getOptions("DELETE");

    return fetch(finalApi.toString(), options).then(res => handleResponse<T>(res));
};

export const get = async <TIn, TOut>(url: string, data?: TIn): Promise<TOut> => {
    const apiUrl = `${process.env.REACT_APP_API_URL}${url}`;

    let finalApi = new URL(apiUrl);

    if (data) {
        finalApi = applyQueryParams(finalApi, data);
    }

    const options = await getOptions("GET", data);

    return fetch(finalApi.toString(), options).then(res => handleResponse(res));
};

export const post = async <TOut, TIn>(url: string, data?: TIn): Promise<TOut> => {
    const apiUrl = `${process.env.REACT_APP_API_URL}${url}`;

    const finalApi = new URL(apiUrl);

    const options = await getOptions("POST", data);

    return fetch(finalApi.toString(), options).then(res => handleResponse<TOut>(res));
};

export const put = async <TOut, TIn>(url: string, data: TIn): Promise<TOut> => {
    const apiUrl = `${process.env.REACT_APP_API_URL}${url}`;

    const finalApi = new URL(apiUrl);

    const options = await getOptions("PUT", data);

    return fetch(finalApi.toString(), options).then(res => handleResponse<TOut>(res));
};

function applyQueryParams(url: URL, data: unknown): URL {
    const urlWithQueryParams = new URL(url.toString());

    Object.keys(data).forEach(key => {
        if (data[key] !== undefined) {
            if (Array.isArray(data[key])) {
                data[key].forEach((_: string) => {
                    urlWithQueryParams.searchParams.append(key, _);
                });
            } else {
                urlWithQueryParams.searchParams.append(key, data[key]);
            }
        }
    });

    return urlWithQueryParams;
}

async function getHeaders(): Promise<Record<string, string>> {
    return sessionService
        .loadSession()
        .then((session: AuthenticationResponse) => {
            return {
                "Content-Type": "application/json",
                Authorization: `Bearer ${session.webToken}`,
            };
        })
        .catch((e: unknown) => {
            return {
                "Content-Type": "application/json",
            };
        });
}

async function getOptions(method: string, data?: unknown): Promise<RequestInit> {
    const options = {
        headers: await getHeaders(),
        method: method,
    } as RequestInit;

    if (hasNoBody(method, data)) {
        return options;
    }

    options.body = JSON.stringify(data);

    return options;
}

const buildFormData = (formData, data, parentKey?) => {
    if (data && typeof data === "object" && !(data instanceof Date) && !(data instanceof File)) {
        Object.keys(data).forEach(key => {
            buildFormData(formData, data[key], parentKey ? `${parentKey}[${key}]` : key);
        });
    } else {
        const value = data == null ? "" : data;

        formData.append(parentKey, value);
    }
};

export const postFile = async <T>(url: string, fileToUpload: File, data?: unknown): Promise<T> => {
    const formData = new FormData();
    if (data) {
        buildFormData(formData, data);
    }
    formData.append("File", fileToUpload);

    const apiUrl = `${process.env.REACT_APP_API_URL}${url}`;

    const finalApi = new URL(apiUrl);

    const headers = {};

    try {
        const session = await sessionService.loadSession().catch();
        if (session && session.resp && session.resp.token) {
            headers["Authorization"] = `Bearer ${session.resp.token}`;
        }
    } catch (e) {
        //
    }

    const options = {
        headers: headers,
        method: "POST",
    } as RequestInit;

    options.body = formData;

    return fetch(finalApi.toString(), options).then(res => handleResponse<T>(res));
};

async function handleResponse<T>(response: Response): Promise<T> {
    if (!response.ok) {
        if (response.status === 401) {
            await sessionService.deleteSession();
            await sessionService.deleteUser();
            return Promise.reject(response.status);
        }
        return response.json().then((error: Error) => {
            if (error.message === "") {
                return Promise.reject(response.statusText);
            }

            return Promise.reject(error);
        });
    }

    return response.text().then((text: string) => {
        const data = text && JSON.parse(text);

        if (Array.isArray(data) && response.headers.has("X-Total-Entries")) {
            return {
                data: data,
                count: response.headers.get("X-Total-Entries"),
            };
        }

        return data;
    });
}

function hasNoBody(method: string, data: unknown) {
    return !data || method === "GET";
}

const api = {
    _delete,
    get,
    post,
    put,
    postFile,
};

export default api;
