import { IconButton, showconfirmmodal, showerrormodal, showokmodal } from "@g3r-developers/g3-common";
import VideoRecorder from "Components/Camera/VideoRecorder";
import { DamageSection } from "Components/Inspection/Damage/DamageSection";
import MarketingSection from "Components/Inspection/Marketing/MarketingSection";
import { QuestionSection } from "Components/Inspection/Question/QuestionSection";
import inspectionDamageStore from "Services/DbStores/InspectionDamageStore";
import inspectionDetailStore from "Services/DbStores/InspectionDetailStore";
import inspectionSectionStore from "Services/DbStores/InspectionSectionStore";
import inspectionValidationService from "Services/InspectionValidationService";
import { InspectionStatus } from "Types/Enums/InspectionStatus";
import { InspectionType } from "Types/Enums/InspectionType";
import { SectionNames } from "Types/Inspection/Enums/SectionNames";
import { OfflineInspectionDetailModel } from "Types/Inspection/OfflineInspectionDetailModel";
import { SectionModel } from "Types/Inspection/SectionModel";
import { useCallback, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { TabPane } from "reactstrap";
import InspectionNotes from "./Notes/InspectionNotes";
import SummarySection from "./Summary/SummarySection";

export interface InspectionSectionTabProps {
    inspectionDetail: OfflineInspectionDetailModel;
    section: SectionModel;
    idx: number;
    isComplete: boolean;
    handleSectionComplete(index: number, state: boolean): void;
    next(): void;
    nextAvailable: boolean;
    isInspectionComplete: boolean;
    updateInspectionDetail(inspection: OfflineInspectionDetailModel): void;
    reloadInspectionDetail(inspectionId: string): void;
}

export function InspectionSectionTab({
    inspectionDetail,
    section,
    idx,
    isComplete,
    next,
    nextAvailable,
    handleSectionComplete,
    isInspectionComplete,
    updateInspectionDetail,
    reloadInspectionDetail,
}: InspectionSectionTabProps) {
    const [highlightUnanswered, setHighlightUnanswered] = useState<boolean>(false);

    const navigate = useNavigate();

    const completeInspection = useCallback(() => {
        inspectionDetailStore.updateStatus(inspectionDetail.inspectionId, InspectionStatus.Completed).then(async () => {
            if (inspectionDetail.inspection.inspectionTypeId === InspectionType.SignalLite) {
                // immediately sync lite inspection
                navigate(`/inspection-lite/sync/${inspectionDetail.inspectionId}`);
            } else {
                await showokmodal(
                    "Inspection has been completed and saved to the device. Please sync the inspection once you are online."
                );
                navigate("/inspections");
            }
        });
    }, [navigate, inspectionDetail.inspection.inspectionTypeId, inspectionDetail.inspectionId]);

    const handleComplete = useCallback(async () => {
        const isComplete = await inspectionValidationService.validateInspection(inspectionDetail.inspectionId);

        setHighlightUnanswered(!isComplete);

        if (!isComplete) {
            await showokmodal("Inspection is not complete. Please ensure you have completed all sections.");
        } else {
            completeInspection();
        }
    }, [completeInspection, inspectionDetail.inspectionId]);

    const [damageSectionTabIndex, setDamageSectionTabIndex] = useState<number>(0);

    const glassCheckComponentIds = useMemo(
        () => [
            "7d62d623-3c94-45e6-af89-ff13e3c3f099", // Windscreen
            "78c6abc3-1c4a-49ae-bad1-1b536a503ca5", // Rear Window (Tailgate)
        ],
        []
    );

    const checkShowGlassCheckConfirm = useCallback(async () => {
        const exteriorIndex = inspectionDetail.splats.findIndex(
            s => s.locationContext?.id === "819291d5-5ee7-4ee2-ba7a-60fb52af4e75"
        );

        const isExterior = section.name === SectionNames.Damage && damageSectionTabIndex === exteriorIndex;

        if (!isExterior) {
            return false;
        }

        const inspectionSplats = await inspectionDamageStore.getDamages(inspectionDetail.inspection.id);
        const damageComponentIds = inspectionSplats.flatMap(splat => splat.damages).map(d => d.componentId);
        const glassHasDamages = glassCheckComponentIds.some(id => damageComponentIds.includes(id));

        return !glassHasDamages;
    }, [
        damageSectionTabIndex,
        glassCheckComponentIds,
        inspectionDetail.inspection.id,
        inspectionDetail.splats,
        section.name,
    ]);

    const getSectionNotCompleteMessage = useCallback((sectionName: string) => {
        switch (sectionName) {
            case SectionNames.OffsiteMarketing: {
                return "You've not taken all the marketing photos - are you sure you want to go to the next step?";
            }
            case SectionNames.Damage: {
                return "You've not selected any damage and/or examined the tyres - are you sure there is no damage on the vehicle?";
            }
            case SectionNames.WarningLights: {
                return "Are you sure there are no warning lights present?";
            }
            default: {
                return "Section not complete - are you sure you want to continue to the next step?";
            }
        }
    }, []);

    const nextDamageTab = useCallback(async () => {
        let canGoNext = false;

        const isTyreValid = await inspectionValidationService.validateTyres(inspectionDetail.inspectionId);

        const showGlassCheckConfirm = await checkShowGlassCheckConfirm();

        const isFinalTab = damageSectionTabIndex === inspectionDetail.splats.length - 1;

        const next = isFinalTab ? damageSectionTabIndex : damageSectionTabIndex + 1;

        if (isFinalTab) {
            if (!isTyreValid) {
                showerrormodal("All tyre sections need completing before you can continue");
            } else if (showGlassCheckConfirm) {
                canGoNext = await showconfirmmodal(
                    "Glass check",
                    "Please check the Windscreen and Rear Window and confirm there is no damage. Are you sure you want to continue?"
                );

                if (canGoNext) {
                    setDamageSectionTabIndex(next);
                }
            } else if (isFinalTab && !isComplete) {
                canGoNext = await showconfirmmodal(
                    "No Damage",
                    "No damage has been added. Are you sure you would like to continue?"
                );

                if (canGoNext) {
                    setDamageSectionTabIndex(next);
                }
            }
        } else {
            setDamageSectionTabIndex(next);
        }

        return isFinalTab && canGoNext;
    }, [
        checkShowGlassCheckConfirm,
        damageSectionTabIndex,
        inspectionDetail.inspectionId,
        inspectionDetail.splats,
        isComplete,
    ]);

    const handleNextStep = useCallback(async () => {
        setHighlightUnanswered(!isComplete);

        let canGoNext = false;

        switch (section.name) {
            case SectionNames.Damage: {
                canGoNext = await nextDamageTab();

                break;
            }
            case SectionNames.WarningLights: {
                const questions = await inspectionSectionStore.getSectionFromSection(
                    inspectionDetail.inspectionId,
                    section
                );

                if (questions.every(q => q.answer === "not illuminated")) {
                    canGoNext = await showconfirmmodal(
                        "No Warning Lights Illuminated",
                        "Are you sure there are no warning lights present?"
                    );
                } else {
                    canGoNext = true;
                }

                break;
            }
            default: {
                canGoNext = isComplete;
            }
        }

        if (canGoNext && !isComplete) {
            canGoNext = await showconfirmmodal("Section not complete", getSectionNotCompleteMessage(section.name));
        }

        if (canGoNext) {
            next();
        }
    }, [getSectionNotCompleteMessage, inspectionDetail.inspectionId, isComplete, next, section, nextDamageTab]);

    const sectionComponent = useMemo(() => {
        switch (section.sectionType.reference) {
            case "Questions": {
                return (
                    <QuestionSection
                        handleSectionComplete={handleSectionComplete}
                        highlightUnanswered={highlightUnanswered}
                        inspection={inspectionDetail}
                        section={section}
                        sectionIndex={idx}
                        updateInspectionDetail={updateInspectionDetail}
                    />
                );
            }
            case "MarketingImages": {
                return (
                    <MarketingSection
                        handleComplete={handleComplete}
                        handleNextStep={handleNextStep}
                        handleSectionComplete={handleSectionComplete}
                        inspection={inspectionDetail}
                        inspectionId={inspectionDetail.inspection.id}
                        nextAvailable={nextAvailable}
                        placeholders={inspectionDetail.marketingImages}
                        reloadInspectionDetail={reloadInspectionDetail}
                        section={section}
                        sectionIndex={idx}
                    />
                );
            }
            case "Damage": {
                return (
                    <DamageSection
                        displayedTabIndex={damageSectionTabIndex}
                        handleSectionComplete={handleSectionComplete}
                        inspectionDetail={inspectionDetail}
                        inspectionId={inspectionDetail.inspection.id}
                        matrices={inspectionDetail.matrices}
                        sectionIndex={idx}
                        setDisplayedTabIndex={setDamageSectionTabIndex}
                        splats={inspectionDetail.splats}
                    />
                );
            }
            case "Summary": {
                return (
                    <SummarySection
                        handleSectionComplete={handleSectionComplete}
                        inspectionDetail={inspectionDetail}
                        sectionIndex={idx}
                    />
                );
            }
        }
    }, [
        damageSectionTabIndex,
        handleComplete,
        handleNextStep,
        handleSectionComplete,
        highlightUnanswered,
        idx,
        inspectionDetail,
        nextAvailable,
        reloadInspectionDetail,
        section,
        updateInspectionDetail,
    ]);

    const sectionName = useMemo(() => {
        return section.name === "Offsite Marketing" ? "Photos" : section.name;
    }, [section.name]);

    return (
        <TabPane tabId={idx}>
            {section.name !== "Damage" && (
                <h3 className="text-bold pt-3">
                    {sectionName}
                    <div className="float-end">
                        <span className="form-control bg-warning">
                            <h5 className="text-bold mb-0">{inspectionDetail?.inspection.vrm}</h5>
                        </span>
                    </div>
                </h3>
            )}

            {sectionComponent}

            {nextAvailable && (
                <div className={"float-end my-3"}>
                    <IconButton
                        text="Next Step"
                        faIconString="fa fa-caret-right"
                        variant="signal-lite-blue"
                        onClick={handleNextStep}
                    />
                </div>
            )}

            {!nextAvailable && (
                <div className={"float-end my-3"}>
                    <IconButton
                        text="Complete"
                        variant={isInspectionComplete ? "success" : "danger"}
                        onClick={handleComplete}
                    />
                </div>
            )}
            <div className={"float-end my-3"}>
                <InspectionNotes inspection={inspectionDetail} updateInspectionDetail={updateInspectionDetail} />
            </div>
            <div className={"float-end my-3"}>
                <VideoRecorder inspection={inspectionDetail} updateInspectionDetail={updateInspectionDetail} />
            </div>
        </TabPane>
    );
}
