import { useCallback, useEffect, useRef } from "react";
import { InspectionDamageItem } from "Types/Inspection/InspectionDamageItem";

export default function SplatImage({
    damages,
    onClick,
    splatImg,
}: {
    damages: InspectionDamageItem[];
    onClick: (xCoordinate: number, yCoordinate: number) => void;
    splatImg: string;
}) {
    const canvasRef = useRef(undefined);

    const drawPoint = useCallback(
        (x, y, idx) => {
            const canvas = canvasRef.current;
            const context = canvas.getContext("2d");
            context.beginPath();
            context.arc(x, y, 10, 0, 2 * Math.PI, true);
            context.fillStyle = "red";
            context.fill();
            context.font = "14px bold";
            context.fillStyle = "white";
            context.fillText(idx + 1, x - 3, y + 4);
        },
        [canvasRef]
    );
    const draw = useCallback(() => {
        const img = new Image();
        img.src = splatImg;
        img.onload = function () {
            const canvas = canvasRef.current;

            if (!canvas) {
                return;
            }

            const ctx = canvas.getContext("2d");
            ctx.canvas.width = img.naturalWidth;
            ctx.canvas.height = img.naturalHeight;
            ctx.drawImage(img, 0, 0);

            for (let i = 0; i < damages.length; i++) {
                const dmg = damages[i];
                drawPoint(dmg.xCoordinate, dmg.yCoordinate, i);
            }
        };
    }, [canvasRef, damages, drawPoint, splatImg]);

    const setupCanvas = useCallback(() => {
        draw();
    }, [draw]);

    useEffect(() => {
        setupCanvas();
    }, [damages, setupCanvas]);

    const handleClick = useCallback(
        e => {
            const rect = canvasRef.current.getBoundingClientRect();

            const xCoordinate = e.clientX - rect.left;
            const yCoordinate = e.clientY - rect.top;

            onClick(xCoordinate, yCoordinate);
        },
        [canvasRef, onClick]
    );

    return <canvas onMouseDown={handleClick} ref={canvasRef} />;
}
