import { G3Input, IconButton } from "@g3r-developers/g3-common";
import { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Col, Row } from "reactstrap";

export function VehicleSearch(): JSX.Element {
    const [vrm, setVrm] = useState<string>("");
    const navigate = useNavigate();

    const handleSearch = useCallback((): void => {
        if (vrm.trim().length === 0) {
            return;
        }

        navigate(`/vehicle/search/${vrm}`);
    }, [navigate, vrm]);

    const handleVrmChanged = useCallback(
        (e: string) => {
            setVrm(e.replace(" ", "").toUpperCase());
        },
        [setVrm]
    );

    return (
        <>
            <Row className="mb-2">
                <Col className="text-center">
                    <h3>What is the vehicle registration number?</h3>
                </Col>
            </Row>
            <Row className="mb-4">
                <Col sm={{ offset: 3, size: 6 }}>
                    <G3Input
                        value={vrm}
                        type="text"
                        onChange={handleVrmChanged}
                        className="bg-warning text-bold text-x2 text-center mb-2"
                    />
                    <IconButton
                        block
                        faIconString={"fas fa-search"}
                        onClick={handleSearch}
                        text="Search for Appraisal"
                        variant={"primary"}
                    />
                </Col>
            </Row>
        </>
    );
}
