import { InfoTag } from "@g3r-developers/g3-common";
import inspectionSectionStore from "Services/DbStores/InspectionSectionStore";
import { OfflineInspectionDetailModel } from "Types/Inspection/OfflineInspectionDetailModel";
import { QuestionItemModel } from "Types/InspectionStaging/QuestionItemModel";
import { QuestionAnswerModel } from "Types/Question/QuestionAnswerModel";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Card, CardBody, Col, Row } from "reactstrap";

interface AssuredSummaryProps {
    inspection: OfflineInspectionDetailModel;
}

const AssuredSummary = ({ inspection }: AssuredSummaryProps) => {
    const [questions, setQuestions] = useState<QuestionItemModel[]>([]);
    const [questionTitles, setQuestionTitles] = useState<QuestionAnswerModel[]>([]);

    const assuredSections = useMemo(() => {
        const assuredLike = ["Assured", "Express"];
        return inspection.sections.filter(_ => assuredLike.some(a => _.name.includes(a)));
    }, [inspection.sections]);

    const isAssured = useMemo(() => {
        if (!assuredSections || assuredSections.length === 0) {
            return false;
        }

        return true;
    }, [assuredSections]);

    const getQuestions = useCallback(async () => {
        const qs = [] as QuestionItemModel[];
        const qts = [] as QuestionAnswerModel[];

        const sections = await inspectionSectionStore.loadInspectionSections(inspection.inspectionId);
        await asyncForEach(assuredSections, async section => {
            const s = sections.find(_ => _.questionSectionId === section.id);
            if (s !== undefined) {
                s.questionItemModels.map(_ => qs.push(_));
            }
            const i = await inspectionSectionStore.getSectionFromSection(inspection.inspectionId, section);
            if (i !== undefined) {
                i.map(_ => qts.push(_));
            }
        });

        setQuestions(qs);

        setQuestionTitles(qts);
    }, [assuredSections, inspection.inspectionId]);

    async function asyncForEach(array, callback) {
        for (let index = 0; index < array.length; index++) {
            // eslint-disable-next-line no-await-in-loop
            await callback(array[index], index, array);
        }
    }

    useEffect(() => {
        if (!isAssured) {
            return;
        }

        getQuestions();
    }, [getQuestions, isAssured]);

    const failedQuestions = useMemo(() => {
        const failed = ["Fail", "illuminated", "No", "Replace"];
        const allFailed = questions.filter(_ => failed.includes(_.textValue));

        const textValues = allFailed.map(_ => questionTitles.find(qt => qt.id === _.questionId));

        return textValues;
    }, [questionTitles, questions]);

    const passedQuestions = useMemo(() => {
        const passed = ["Pass", "Not Illuminated", "NA", "Yes"];

        const allPassed = questions.filter(_ => passed.includes(_.textValue));

        const textValues = allPassed.map(_ => questionTitles.find(qt => qt.id === _.questionId));

        return textValues;
    }, [questionTitles, questions]);

    return (
        <>
            {isAssured && (
                <Card>
                    <CardBody>
                        <h5 className="text-bold">Assured Summary</h5>

                        <Row>
                            <Col md={6}>
                                <h4 className="text-success text-bold">Passed/NA</h4>
                                <Row>
                                    {passedQuestions.map(question => (
                                        <InfoTag
                                            className="bg-success text-white"
                                            value={<>{question?.questionText}</>}
                                        />
                                    ))}
                                </Row>
                            </Col>
                            <Col md={6}>
                                <h4 className="text-danger text-bold">Failed</h4>
                                <Row>
                                    {failedQuestions.map(question => (
                                        <InfoTag className="bg-danger text-white" value={question?.questionText} />
                                    ))}
                                </Row>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            )}
        </>
    );
};
export default AssuredSummary;
