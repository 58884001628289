import { NamaSplatOverlayItemModel } from "Types/Inspection/NamaSplatOverlayItemModel";
import * as api from "./Api";

export const getComponentsForSplat = (splatId: string): Promise<NamaSplatOverlayItemModel[]> => {
    return api.get<unknown, NamaSplatOverlayItemModel[]>(`component/splat/${splatId}`);
};

const componentService = {
    getComponentsForSplat,
};

export default componentService;
